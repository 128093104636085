/**
 * Object to store user data.
 */

class UserData {
    // Drupal uid and name.
    uid;
    name;
    // Object with Drupal uri and url
    picture;

    constructor(uid, name, picture) {
      this.uid = uid;
      this.name = name;
      this.picture = picture;
    }
    
    /**
     * Build object from raw data.
     *
     * @param {object} data
     */
    static fromRawData(data) {
      return new UserData(data.uid, data.name, data.picture);   
    }   
    
    /**
     * Get the user id stored on local storage.
     *
     * @return {string}
     */
    getUserId() {
      return this.uid;
    }

    /**
     * Get the user name stored on local storage.
     *
     * @return {string}
     */
    getName() {
      return this.name;
    }

    /**
     * Get the user picture from user data
     *
     * @return {string}
     */
    getPictureUrl() {
      return this.picture.url;
    }
    
    
}

export default UserData;
