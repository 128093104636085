import { gtag, initDataLayer, install } from 'ga-gtag';
import BaseService from './BaseService';
import NetworkService from "./NetworkService";
import Config from "./Config";

/**
 * Wrapper class to manage GA
 */
class AnalyticsAPIService extends BaseService {

    // GA Tracking Id, empty if disabled.
    static trackingId;

    /**
     * Init React-ga lib.
     */
    static init() {
        if (!Config.isAnalyticsEnabled())
            return;

        this.debug("AnalyticsAPIService.init()");
        this.trackingId = this.config().google_analytics_tracking_id;

        if (this.trackingId) {
            initDataLayer();
            gtag('consent', 'default', {});
            install(this.trackingId);
        }

    }

    /**
     * Check whether GA service is enabled.
     *
     * @return {boolean}
     */
    static isEnabled() {
      return Config.isAnalyticsEnabled() && !!this.trackingId;
    }

    /**
     * Send event to GA
     *
     * @param {string} category
     * @param {string} action
     * @param {string} label Optional event label
     */
    static sendEvent(category, action, label) {
      if (!this.isEnabled() || NetworkService.isOffline()) {
          return;
      }

      const data = {
         category,
         action
      };
      if (label) {
        data.label = label;
      }
      if (this.trackingId) {
          this.debug('AnalyticsAPIService.sendEvent', data);
          gtag('event', label, data);
      }
    }


    /**
     * Send url/path.
     *
     * @param {string} url
     */
    static async sendPageView(url) {
      if (!this.isEnabled() || NetworkService.isOffline()) {
          return;
      }

      if (this.trackingId) {
        this.debug('AnalyticsAPIService.sendPageView', url);
        gtag('event', 'page_view', { page: url });
      }
    }

    /**
     * Send event about outbound link.
     *
     * @param {string} category Event category
     * @param {string} label Event label
     * @param {string} page Event page
     * @param {string} action Event action
     */
    static async sendCustomEvent(action, label, page) {
        if (!this.isEnabled() || NetworkService.isOffline()) {
            return;
        }

        const data = {
            action,
            page_title: label,
            page_location: page,
        };
        if (this.trackingId) {
            this.debug('AnalyticsAPIService.sendEvent', data);
            gtag('event', action, data);
        }
    }

    /**
     * Send event about a modal page view (card)
     *
     * @param {string} type Card type
     * @param {string} cardTitle Title of the card
     * @param {string} url Url of the content loaded in the card
     */
    static async sendViewCard(cardTitle, url) {
        await this.sendCustomEvent(
            'screen_view',
            cardTitle + ' | Gronze.maps',
            url
        );
    }

    /**
     * Send event about outbound link.
     *
     * @param {string} type Type of the link
     * @param {string} label Label of the link
     * @param {string} url URL opened in external browser
     */
    static async sendOutboundLink(label, url) {
        await this.sendCustomEvent(
            'page_view',
            label + ' | Gronze.maps',
            url
        );
    }
}

export default AnalyticsAPIService;

