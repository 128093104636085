/**
 * Base class to store Object data retrieved from REST API.
 * 
 * This will match a Drupal entity.
 */
class ObjectData {
    // Data type and Id.
    type;
    id;
    url;
    
    // Rest properties used for request.
    // - id, Object ID
    // - type, Rest type
    // - url Rest URL
    properties = {};

    // Retrieved object data, raw object.
    object = {};

    /**
     * Generic constructor.
     * 
     * @param {string} type
     *    The data / object type.
     */
    constructor(type, id, properties, data) {
      this.type = type;
      this.id = id;
      
      if (properties) {
        this.properties = properties;
      }
      
      // Set some basic data, for nodes and terms.
      if (data) {
        this.data = data;

        if (data.url) {
          this.url = data.url;
        }
      }      
    }
    
    /**
     * Get data type.
     *
     * @return {string}
     */
    getType() {
      return this.type;
    }
        
    /**
     * Get Data Id.
     *
     * @return {int|string}
     */
    getId() {
      return this.id;
    }

    /**
     * Get the remote URL for links.
     *
     * @return {string}
     */
    getLinkUrl() {
      return this.url;
    }
    
    /**
     * Gets the Rest URL.
     */
    getRestUrl() {
      return this.properties && this.properties.url ? this.properties.url : null;
    }
      
}

export default ObjectData;
