import {Geolocation} from "@capacitor/geolocation";
import { GeolocateControl } from 'mapbox-gl';

/**
 * Given a destination object and optionally many source objects,
 * copy all properties from the source objects into the destination.
 * The last source object given overrides properties from previous
 * source objects.
 *
 * @param dest destination object
 * @param sources sources from which properties are pulled
 * @private
 */
function extend$1(dest, ...sources) {
    for (const src of sources) {
        for (const k in src) {
            dest[k] = src[k];
        }
    }
    return dest;
}
class _Event {
    constructor(type, data = {}) {
        extend$1(this, data);
        this.type = type;
    }
}

export class GronzeGeolocateControl extends GeolocateControl {
    warnOnceHistory = {};

    defaultOptions$1 = {
        positionOptions: {
            enableHighAccuracy: false,
            maximumAge: 0,
            timeout: 6000    /* 6 sec */
        },
        fitBoundsOptions: { maxZoom: 15 },
        trackUserLocation: true,
        showAccuracyCircle: true,
        showUserLocation: true,
        showUserHeading: true
    };

    constructor(options) {
        super();
        const geolocation = Geolocation;
        this.options = {...this.defaultOptions$1, options, geolocation};

        this.bindAll([
            '_onSuccess',
            '_onError',
            '_onZoom',
            '_finish',
            '_setupUI',
            '_updateCamera',
            '_updateMarker',
            '_updateMarkerRotation',
            '_onDeviceOrientation'
        ], this);

        this._updateMarkerRotationThrottled = this.throttle(this._updateMarkerRotation, 20);
        this._numberOfWatches = 0;
    }

    /**
     * Given a destination object and optionally many source objects,
     * copy all properties from the source objects into the destination.
     * The last source object given overrides properties from previous
     * source objects.
     *
     * @param dest destination object
     * @param sources sources from which properties are pulled
     * @private
     */
    extend(dest, src) {
        for (const i in src)
            dest[i] = src[i];
        return dest;
    }

    /**
     * Given an array of member function names as strings, replace all of them
     * with bound versions that will always refer to `context` as `this`. This
     * is useful for classes where otherwise event bindings would reassign
     * `this` to the evented object or some other value: this lets you ensure
     * the `this` value always.
     *
     * @param fns list of member function names
     * @param context the context value
     * @example
     * function MyClass() {
     *   bindAll(['ontimer'], this);
     *   this.name = 'Tom';
     * }
     * MyClass.prototype.ontimer = function() {
     *   alert(this.name);
     * };
     * var myClass = new MyClass();
     * setTimeout(myClass.ontimer, 100);
     * @private
     */
    bindAll(fns, context) {
        fns.forEach(fn => {
            if (!context[fn]) {
                return;
            }
            context[fn] = context[fn].bind(context);
        });
    }

    /**
     * Throttle the given function to run at most every `period` milliseconds.
     * @private
     */
    throttle(fn, time) {
        let pending = false;
        let timerId = null;
        const later = () => {
            timerId = null;
            if (pending) {
                fn();
                timerId = setTimeout(later, time);
                pending = false;
            }
        };
        return () => {
            pending = true;
            if (!timerId) {
                later();
            }
            return timerId;
        };
    }

    /**
     * Print a warning message to the console and ensure duplicate warning messages
     * are not printed.
     *
     * @private
     */
    warnOnce(message) {
        if (!this.warnOnceHistory[message]) {
            // console isn't defined in some WebWorkers, see #2558
            if (typeof console !== 'undefined')
                console.warn(message);
            this.warnOnceHistory[message] = true;
        }
    }

    _checkGeolocationSupport(callback) {
        const updateSupport = (supported = !!Geolocation) => {
            this._supportsGeolocation = supported;
            callback(supported);
        };
        if (this._supportsGeolocation !== undefined) {
            callback(this._supportsGeolocation);
        } else {
            const geoPermssions = Geolocation.checkPermissions();
            updateSupport(geoPermssions.location !== 'denied');
        }
        /*
         else if (ref_properties.window.navigator.permissions !== undefined) {
            // navigator.permissions has incomplete browser support http://caniuse.com/#feat=permissions-api
            // Test for the case where a browser disables Geolocation because of an insecure origin;
            // in some environments like iOS16 WebView, permissions reject queries but still support geolocation
            this.options.geolocation.
            ref_properties.window.navigator.permissions.query({ name: 'geolocation' }).then(p => updateSupport(p.state !== 'denied')).catch(() => updateSupport());


        } else {
            updateSupport();
        }
        */
    }

    onRemove() {
        // clear the geolocation watch if exists
        if (this._geolocationWatchID !== undefined) {
            Geolocation.clearWatch({id: this._geolocationWatchID });
            this._geolocationWatchID = undefined;
        }
        // clear the markers from the map
        if (this.options.showUserLocation && this._userLocationDotMarker) {
            this._userLocationDotMarker.remove();
        }
        if (this.options.showAccuracyCircle && this._accuracyCircleMarker) {
            this._accuracyCircleMarker.remove();
        }
        this._container.remove();
        this._map.off('zoom', this._onZoom);
        this._map = undefined;
        this._numberOfWatches = 0;
        this._noTimeout = false;
    }

    /**
     * Trigger a geolocation event.
     *
     * @example
     * // Initialize the geolocate control.
     * const geolocate = new mapboxgl.GeolocateControl({
     *     positionOptions: {
     *         enableHighAccuracy: true
     *     },
     *     trackUserLocation: true
     * });
     * // Add the control to the map.
     * map.addControl(geolocate);
     * map.on('load', () => {
     *     geolocate.trigger();
     * });
     * @returns {boolean} Returns `false` if called before control was added to a map, otherwise returns `true`.
     */
    async trigger() {
        if (!this._setup) {
            this.warnOnce('Geolocate control triggered before added to a map');
            return false;
        }
        if (this.options.trackUserLocation) {
            // update watchState and do any outgoing state cleanup
            switch (this._watchState) {
                case 'OFF':
                    // turn on the GeolocateControl
                    this._watchState = 'WAITING_ACTIVE';
                    this.fire(new _Event('trackuserlocationstart'));
                    break;
                case 'WAITING_ACTIVE':
                case 'ACTIVE_LOCK':
                case 'ACTIVE_ERROR':
                case 'BACKGROUND_ERROR':
                    // turn off the Geolocate Control
                    this._numberOfWatches--;
                    this._noTimeout = false;
                    this._watchState = 'OFF';
                    this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-waiting');
                    this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-active');
                    this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-active-error');
                    this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-background');
                    this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-background-error');
                    this.fire(new _Event('trackuserlocationend'));
                    break;
                case 'BACKGROUND':
                    this._watchState = 'ACTIVE_LOCK';
                    this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-background');
                    // set camera to last known location
                    if (this._lastKnownPosition)
                        this._updateCamera(this._lastKnownPosition);
                    this.fire(new _Event('trackuserlocationstart'));
                    break;
                default:
                    break;
            }
            // incoming state setup
            switch (this._watchState) {
                case 'WAITING_ACTIVE':
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-waiting');
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-active');
                    break;
                case 'ACTIVE_LOCK':
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-active');
                    break;
                case 'ACTIVE_ERROR':
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-waiting');
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-active-error');
                    break;
                case 'BACKGROUND':
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-background');
                    break;
                case 'BACKGROUND_ERROR':
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-waiting');
                    this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-background-error');
                    break;
                default:
                    break;
            }
            // manage geolocation.watchPosition / geolocation.clearWatch
            if (this._watchState === 'OFF' && this._geolocationWatchID !== undefined) {
                // clear watchPosition as we've changed to an OFF state
                this._clearWatch();
            } else if (this._geolocationWatchID === undefined) {
                // enable watchPosition since watchState is not OFF and there is no watchPosition already running
                this._geolocateButton.classList.add('mapboxgl-ctrl-geolocate-waiting');
                this._geolocateButton.setAttribute('aria-pressed', 'true');
                this._numberOfWatches++;
                let positionOptions;
                if (this._numberOfWatches > 1) {
                    positionOptions = {
                        maximumAge: 600000,
                        timeout: 0
                    };
                    this._noTimeout = true;
                } else {
                    positionOptions = this.options.positionOptions;
                    this._noTimeout = false;
                }
                const promiseResponse = await Geolocation.watchPosition(positionOptions,
                    (position, error) => {
                        if(error) {
                            this._onError(error);
                        } else {
                            this._onSuccess(position);
                        }
                    }
                );
                this._geolocationWatchID = promiseResponse;

                if (this.options.showUserHeading) {
                    this._addDeviceOrientationListener();
                }
            }
        } else {
            try {
                const position  = await Geolocation.getCurrentPosition();
                this._onSuccess(position);
            } catch (error) {
                this._onError(error);
            }

            // This timeout ensures that we still call finish() even if
            // the user declines to share their location in Firefox
            this._timeoutId = setTimeout(this._finish, 10000    /* 10sec */);
        }
        return true;
    }

    async _clearWatch() {
        await Geolocation.clearWatch({id: this._geolocationWatchID });
        window.removeEventListener('deviceorientation', this._onDeviceOrientation);
        window.removeEventListener('deviceorientationabsolute', this._onDeviceOrientation);
        this._geolocationWatchID = undefined;
        this._geolocateButton.classList.remove('mapboxgl-ctrl-geolocate-waiting');
        this._geolocateButton.setAttribute('aria-pressed', 'false');
        if (this.options.showUserLocation) {
            this._updateMarker(null);
        }
    }
}
