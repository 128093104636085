import GronzePage from "../components/GronzePage";
import AppService from "./AppService";

class GronzePageService {

    static componentOpenHandler;
    static componentCloseHandler;

    /**
     * method needed to connect page component with tha app
     */
    static init() {
        this.componentOpenHandler = AppService.appcomponent.handleOpenPage;
        this.componentCloseHandler = AppService.appcomponent.handleClosePage;
    }

    /**
     * open page
     */
    static getPage(component) {
        return <GronzePage component={component}/>;
    }
}

export default GronzePageService;
