import { Component } from 'react';
import './LegalConditions.scss';
import i18n from "../i18n";
import {Box, Grid, Link} from "@material-ui/core";
import Config from "../common/Config";

/**
 * Display help about lecal conditions.
 */
class LegalConditionsBox extends Component {

    render() {
         /*
                <p >
                    { i18n.t('legalConditions.message') }
                </p>
        */
        const siteLinks = Config.getSiteLinks();
        return (
            <Box textAlign="left" fontWeight="fontWeightLight" className="legal-conditions">

                <Grid container direction="row" justifyContent="center" >
                  <Grid item xs={4} >
                  <Link href={ siteLinks.legal_usage } target="_blank">
                      { i18n.t('legalConditions.link_usage') }
                  </Link>
                  </Grid>
                  <Grid item xs={4}>
                  <Link href={ siteLinks.legal_privacy } target="_blank">
                      { i18n.t('legalConditions.link_private') }
                  </Link>
                  </Grid>
                  <Grid item xs={4}>
                  <Link href={ siteLinks.legal_cookies} target="_blank">
                      { i18n.t('legalConditions.link_cookies') }
                  </Link>
                  </Grid>
                </Grid>
            </Box>
        );
    }
}

export default LegalConditionsBox;
