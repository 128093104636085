import { Marker} from 'mapbox-gl';
import FeatureData from './FeatureData';

/**
 * Object to store place data.
 *
 * Place type:
 * - 'hotel'
 * - 'place' (city)
 */
class PlaceData extends FeatureData {    
    // @var {object} Map Marker
    mapMarker;
    
    /**
     * @var {DOMElement}
     */
    htmlElement;
    
    /**
     * Some data properties.
     */
    iconClass;
    
    /**
     * Set marker selected state.
     * 
     * @param {boolean} selected
     *
     * @return {boolean}
     *   True if marker is there and can be set.
     */
    setSelected(selected = true) {
      const marker = this.getMapElement();
      if (!marker) {
        return false;
      }
      else if (selected) {
        marker.classList.add('selected');
        return true;
      }
      else {
        marker.classList.remove('selected');
        return true;
      }
    }
    
    /**
     * Create DOM Element
     */
    createElement() {
        if (!this.htmlElement) {
          const el = document.createElement('div');
          el.id = this.getMapItemId();
          this.htmlElement = el;
        }
        
        return this.htmlElement;
    }
    
    /**
     * Gets HTML element from document
     */
    getMapElement(refresh = true) {
        if (refresh || !this.htmlElement) {
          this.htmlElement = document.getElementById(this.getMapItemId());
        }
        return this.htmlElement;
    }
    
    /**
     * Create MAP Marker.
     *
     * @return {Marker}
     */
    createMapMarker() {
      if (!this.mapMarker) {
        const el = this.createElement();      
        this.mapMarker = new Marker(el)
            .setLngLat(this.getGeoLocation());
      }
      return this.mapMarker;
    }
    
    /**
     * Add marker to Map.
     *
     * @return {boolean} true if successful.
     */
    addToMap(mapObject) {
      const marker = this.createMapMarker();
      if (marker) {
        marker.addTo(mapObject);
        return true;
      }
      else {
        return false;
      }
    }
    
    /**
     * Remove marker from map.
     */
    removeMarker() {
      if (this.mapMarker) {
        this.mapMarker.remove();
        this.mapMarker = null;
      }
      this.removeElement();
    }
    
    /**
     * Remove HTML element.
     */
    removeElement() {
      if (this.htmlElement) {
        this.htmlElement.remove();
        this.htmlElement = null;
      }
    }
     
}

export default PlaceData;
