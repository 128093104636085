/**
 * Satellite control button.
 *
 * Implements IControl:
 * https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
 */
import MapService from "../common/MapService";

class StageControl {
    _container = document.createElement('div');
    _button = document.createElement('button');
    CLASS_ACTIVE = 'active';
    CLASS_INACTIVE = 'inactive';
    CLASS_VISIBLE = 'visible';
    CLASS_BUTTON_ETAPA = 'button_etapa';
    CLASS_BUTTON_CONTROL = 'mapboxgl-ctrl';
    mapComponent;
    visible = false;

    handleButtonClick(event) {
        MapService.mapEventStageControlClick(event);
    }

    onAdd(map) {
        this._map = map;
        this._container.className = this.CLASS_BUTTON_CONTROL + ' mapboxgl-ctrl-group ' + this.CLASS_BUTTON_ETAPA;
        this.mapStyle = this.CLASS_ACTIVE;
        this._button.className = `${this.CLASS_BUTTON_ETAPA} ${this.CLASS_ACTIVE}`;
        this._button.addEventListener('click', this.handleButtonClick.bind(this));
        this._container.appendChild(this._button);
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    setVisible(_visible) {
        this._container.classList.toggle(this.CLASS_VISIBLE, _visible);
        this.visible = _visible;
    }
}

export default StageControl;
