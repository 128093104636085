import BaseService from './BaseService';
import Config from "./Config";
import UrlService from './UrlService';
import Oauth2Service from "./Oauth2Service";
import RestService from "./RestService";
import RefreshTimer from "../data/RefreshTimer";

/**
 * Wrapper class to manage use account and authentication.
 *
 * This API should keep stable whatever the auth method used.
 */
class SiteAuth extends BaseService {

  /**
   * Keeps track of received valid user.
   * @var boolean
   */
  static validUser = false;

  /**
   * Whether to retry authentication if it fails.
   */
  static retryAuth = true;

  /**
   * this function sends the app to map view
   */
  static authenticateHandle;

  /**
   * Stored user data.
   *
   * @var {UserData}
   */
  static userData;

  /**
   * @var {RefreshTimer}
   */
  static refreshTimer = new RefreshTimer();

  /**
   * Check whether this needs refresh.
   */
  static needsRefresh() {
    return this.refreshTimer.needsRefresh() && this.isOnline();
  }

  /**
   * True if log in not required or we've got a valid user, not expired.
   */
  static isValidUser() {
    return !Config.isLoginRequired() || this.isUserRegistered() && !this.needsRefresh();
  }

  /**
   * True if current user is registered.
   */
  static isUserRegistered() {
    return this.validUser && this.userData;
  }

  /**
   * True if current user is anonymous.
   */
  static isUserAnonymous() {
    return !this.isUserRegistered();
  }

  /**
   * True if user is anonymous and can log in.
   */
  static canLogin() {
    return Config.isLoginAllowed() && this.isUserAnonymous();
  }

  /**
   * True if user is registerd and can log out.
   */
  static canLogout() {
    return Config.isLoginAllowed() && this.isUserRegistered();
  }

  /**
   * True if user is anonymous and can register.
   */
  static canRegister() {
    return Config.isLoginAllowed() && this.isUserAnonymous();
  }

  /**
   * Check authenticated state.
   *
   * @return boolean
   *   True if authenticated with OAuth or cookies enabled.
   */
  static async checkAuthentication() {
    this.debug('checkAuthentication');

    // Load user from storage if possible
    if (this.isValidUser()) {
      return true;
    } else if (Oauth2Service.hasAccessToken(false)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Initializes authentication using implicit flow.
   *
   * To be called any time on pwa after we get a 401 response, thus:
   * - No valid session.
   *
   * @return boolean
   * - True if authentication is complete
   * - False if auth failed or needs more steps
   */
  static async initAuthentication(with_registration = false) {
    this.debug('initAuthentication implicit');

    await Oauth2Service.requestAuthorization(with_registration);

    // Needs further processing / redirects.
    this.retryAuth = false;
    return false;
  }

  /**
   * Reset authentication, delete all.
   *
   * @return boolean
   * - True if authentication is complete
   * - False if auth failed or needs more steps
   */
  static async resetAuthentication() {
    this.debug('resetAuthentication');
    this.validUser = false;
    this.removeUserData();
    Oauth2Service.reset();
  }

  /**
   * Restart authentication from scratch.
   *
   * To be called any time after we get a 401 response, thus:
   * - No valid session.
   * - No valid oauth tokens.
   *
   * @return boolean
   * - True if authentication is complete
   * - False if auth failed or needs more steps
   */
  static async restartAuthentication() {
    this.resetAuthentication();
    return this.initAuthentication();
  }

  /**
   * Save the user data to storage.
   *
   * @param {UserData} userData: User data received from REST service
   */
  static setUserData(userData) {
    this.debug("Set user data", userData);
    this.userData = userData;
    this.validUser = true;
    this.refreshTimer.setRefreshLast();
    return userData;
  }

  /**
   * Get stored userData from variable.
   *
   * @return {object} | null
   */
  static async getUserData() {
    // if (this.isValidUser()) {
      return this.userData;
    // }
  }

  /**
   * Remove user data, clean variables and storage.
   */
  static removeUserData() {
    this.validUser = false;
    this.userData = null;
  }

  /**
   * Re-check authentication periodically.
   *
   * @return {boolean}
   */
  static async refreshAuthentication() {
    if (this.isValidUser()) {
      return true;
    }
    else {
      this.debug("Refresh / Recheck user");
      this.validUser = false;
      this.retryAuth = true;
      return this.checkAuthentication()
        .then(async auth =>  { return auth && await this.reloadUserData()});
    }
  }

  /**
   * Initializes page requiring a valid user.
   *
   * @returns boolean
   */
  static async requireValidUser() {
    if (this.isValidUser()) {
      this.debug('requireValidUser OK');
      return true;
    }
    else {
      this.debug('requireValidUser RELOAD');
      try{
        const result = await this.reloadUserData();
        return result;
      } catch (e) {
        return false;
      }

    }
  }

  /**
   * Reload user data from server.
   *
   * @return {userData}|null
   */
  static async reloadUserData() {
    return new Promise((resolve)=> {
      RestService.getCurrentUser()
          .then(async (user) => {
            resolve(this.setUserData(user));
          })
          .catch(error => {
            this.validUser = false;
            this.logger().warn('Error loading user data', error);
            resolve(null);
          });
    })
  }

  /**
   * Process oauth2 authentication
   *
   * Parameters:
   * - usarname
   * - password
   *
   * @param {username} string
   * @param {password} password
   *
   * @return void
   */
  static async authenticate(username, password) {
    return Oauth2Service.requestAccessToken(Oauth2Service.PASSWORD_REQUEST_TYPE, username, password)
      .then(() => this.reloadUserData())
      .then(() => this.authenticateHandle())
      .catch((error) => {
        this.debug("Site Authentication failed", error);
        if (error.type === 'user') {
          return Promise.reject(error);
        }
      });
  }

  /**
   * Process oauth2 authentication
   *
   * @return void
   */
  static async clientAuthenticate() {
    return Oauth2Service.requestAccessToken(Oauth2Service.CLIENT_CREDENTIALS_REQUEST_TYPE)
        .then(() => this.reloadUserData())
        .then(() => this.authenticateHandle())
        .catch((error) => {
          this.debug("Site Client Authentication failed", error);
          if (error.type === 'user') {
            return Promise.reject(error);
          }
        });
  }

  /**
   * Handle URL parameters if present.
   *
   * @param {string} url
   *   Raw URL from window.location.href or event.url
   *
   * @return Promise{boolean}
   *   True if URL has been handled successfully.
   */
  static async handleUrl(url) {
    const urlObject = new URL(url);
    return Oauth2Service.extractAuthCode(urlObject.searchParams);
  }

  /**
   * Handle URL event from UrlListener
   * @param {Event} event
   */
  static async handleUrlEvent(event) {
    this.debug("Handling URL event", event);

    return this.handleUrl(event.url)
      .then(() => this.reloadUserData())
      .then(() => this.authenticateHandle())
      .catch((error) => {
         this.debug("Authentication error", error);
      });
  }

  /**
   * Initialize, check URL auth callback, etc..
   *
   * If successful authorization, reloads pwa (redirect)
   *
   * @return {boolean}
   *   True unless we need to stop flow and redirect.
   */
  static async init() {
    this.logger().debug('SiteAuth.init()');

    // Initialize refresh timer.
    this.refreshTimer.setRefreshTime(Config.getRefreshTime());

    const result = await this.handleUrl(window.location.href)
      .then((result) => {
         if (result) {
           UrlService.redirectBaseUrl();
           return false;
         }
         else {
           return true;
         }
       })
      .catch((error) => {
         this.debug("Authentication error", error);
         return true; // Continue so we can retry.
      });

    Oauth2Service.init();

    return result;
  }

}

export default SiteAuth;
