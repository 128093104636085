import i18n from "i18next"
// import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next";

// ruta a los archivos de idiomas
import common_en from "./locales/en/common.json";
import common_es from "./locales/es/common.json";

i18n.use(initReactI18next).init({
    fallbackLng: "es",
    debug: true,
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    // react i18next special options (optional)
    react: {
        wait: false,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'li', 'em', 'a']
    },
    resources: {
        es: {
            translation: common_es,
        },
        en: {
            translation: common_en,
        },
    },
})
export default i18n;
