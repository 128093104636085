import { dataTypes } from '../common/Constants';
import PlaceData from './PlaceData';

/**
 * Object to store city from map marker.
 *
 * Example marker.
 * {
     id: some map id
     layer: map layer {id, type, source, source-layer}
     properties:
     - id: 349
     - name
     - tid: "localidad-349"
     - url: link to site https://www.gronze.com/castilla-y-leon/leon/arcahueja
     - population_size
     - priority
     type: Feature
     _geometry: Point{coordinates [lon, lat]}
   }
*/

class CityData extends PlaceData {

    /**
     * Returns place[id]
     */
    getMapItemId() {
      return 'place' + this.id;   
    }
    
    /**
     * Create from map feature.
     * @param {Feature} feature
     */
    static fromMapFeature(feature) {
      const properties = feature.properties;
      const data = new CityData(dataTypes.place, properties.id);
      
      data.setFeature(feature);      
      data.linkUrl = properties.url;

      return data;
    }
    
}

export default CityData;
