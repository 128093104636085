import {Component} from "react";
import styles from './Pages.module.scss';
import {Box, Button, Typography} from "@material-ui/core";
import TestInfo from "../TestInfo";
import Config from "../../common/Config";
import i18n from "../../i18n";
import UrlService from "../../common/UrlService";
import AnalyticsAPIService from "../../common/AnalyticsAPIService";
import GronzeIcon from "../../icons/GronzeIcon";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

class About extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDebug: !Config.isProduction(),
        };
    }

    goToGronze() {
        UrlService.goToSite();
        AnalyticsAPIService.sendOutboundLink(
            i18n.t('menu.go_gronze'),
            Config.getSiteUrl()
        );
    }

    render() {
        return <div className={styles.page + ' page'}>
            <div className={styles.header + ' header'}>
                <Typography variant="h4" component="h4">
                    { i18n.t('help.about_link') }
                </Typography>
                <div className={styles.icon}>
                    <div className="logo active logo-maps" alt="Logo Gronze Maps" />
                </div>
            </div>

            <Box p={3}>
                <Typography variant="body1" component="p">
                    <span dangerouslySetInnerHTML={{ __html: i18n.t('about.content_1') }} ></span>
                </Typography>
                <br />
                <Typography variant="body1" component="p">
                    <span dangerouslySetInnerHTML={{ __html: i18n.t('about.content_2') }} ></span>
                </Typography>
                <br />
                <Typography variant="body1" component="p">
                    <span dangerouslySetInnerHTML={{ __html: i18n.t('about.content_3') }} ></span>
                </Typography>

                <Button className={styles.gronzeButton} variant="outlined"
                        startIcon={<GronzeIcon />}
                        onClick={() => {
                            this.goToGronze();
                        }}
                        endIcon={<OpenInNewIcon />}
                >
                    { i18n.t('menu.go_gronze') }
                </Button>
            </Box>

            {  this.state.showDebug &&
                <TestInfo />
            }

        </div>
    }
}

export default About;
