import {Component} from "react";
import styles from './GronzePage.module.scss';
import CancelIcon from "@material-ui/icons/Cancel";
import {Slide} from "@material-ui/core";
import GronzePageService from "../common/GronzePageService";
import Swipe from "react-easy-swipe";

class GronzePage extends Component {
    // transition duration in miliseconds
    TRANSITION_TIMEOUT = 150;
    // minimun swipe movement in pixels
    MIN_SWIPE_SIZE = 150;
    swipping = false;

    constructor(props) {
        super(props);
        this.state = {
            opened: this.props.component !== null,
        }
        this.closeHandler = this.closeHandler.bind(this);
        this.unmountComponent = this.unmountComponent.bind(this);
        this.handleSwipeStart = this.handleSwipeStart.bind(this);
        this.handleSwipeMove = this.handleSwipeMove.bind(this);
        this.handleSwipeEnd = this.handleSwipeEnd.bind(this);
    }

    closeHandler() {
        this.setState({ opened: false });
    }

    unmountComponent() {
        if (!this.state.opened) {
            setTimeout(() => {
                GronzePageService.componentCloseHandler();
            }, this.TRANSITION_TIMEOUT);
        }
    }

    /**
     * Swipe handlers (See SwipeableBox)
     */
    handleSwipeStart() {
        this.swipping = true;
    }

    handleSwipeMove(position) {
        if (!this.swipping) return;
        if (position.x > this.MIN_SWIPE_SIZE) {
            this.closeHandler();
            this.swipping = false;
        }
    }

    handleSwipeEnd() {
        this.swipping = false;
    }

    render() {
        return <Slide
            direction="left"
            in={this.state.opened}
            addEndListener={this.unmountComponent}
            timeout={this.TRANSITION_TIMEOUT}
        >
            <div className={styles.gronzePage}>
                <Swipe
                    onSwipeStart={this.handleSwipeStart}
                    onSwipeMove={this.handleSwipeMove}
                    onSwipeEnd={this.handleSwipeEnd}
                    className={styles.cardContentWrapper}
                >
                    <div className={styles.pageCard + ' page'}>
                        {this.props.component}
                        <div className="cancel-icon">
                            <CancelIcon  variant="rounded" color="action" onClick={this.closeHandler} />
                        </div>
                    </div>
                </Swipe>
            </div>
        </Slide>
    }
}

export default GronzePage;
