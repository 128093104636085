import ReactDOM from 'react-dom';
import log from 'loglevel';
import './index.scss';
import { Theming } from './common/Theming';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Config from "./common/Config";
import AppService from "./common/AppService";
import SiteAuth from "./common/SiteAuth";

// Enable logging but do not persist.
log.enableAll(false);

const appName = process.env.REACT_APP_NAME;
const appVersion = process.env.REACT_APP_VERSION;

log.info("STARTING: " + appName + ': ' + appVersion );

// Manage unhandled rejections.
// @see https://javascript.info/promise-error-handling
window.addEventListener('unhandledrejection', function(event) {
  // the event object has two special properties:
  // event.promise [object Promise] - the promise that generated the error
  // event.reason Error: Whoops! - the unhandled error object
  log.warn("Unhandled promise rejection", event.reason);
  if (event.reason.errorMessage !== 'No active window to close!')
    alert("ERROR: " + event.reason);
});

Config.init();

initApp();

// init app
async function initApp() {
  let success = true;

  // Basic init.
  success = success && await AppService.init();
  // Just in case we need to handle the authorization callback before full initialization.
  success = success && await SiteAuth.init();
  // Full initialization.
  success = success && await AppService.initAll();

  if (success) {
    log.info("App initialization completed. Rendering...");
  }
  else {
    log.info("App initialization stopped. May be redirecting...");
  }

  ReactDOM.render(<Theming />, document.getElementById('app'));
}

// SW successful registration.
function registrationSuccessful() {
  log.info("SW registration successful, version", appVersion);
}

// SW Update if new version found.
function updateServiceWorker(registration) {
  log.info("SW update, version", appVersion);
  const registrationWaiting = registration.waiting;
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    registrationWaiting.addEventListener('statechange', e => {
      if (e.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
}

// SW Prompt user, new version detected
function promptUserToRefresh(registration) {
  // Either confirm or just inform and go for it.
  /*
  if (window.confirm("New version available! OK to refresh?")) {
    registration.waiting.postMessage({type: 'SKIP_WAITING'});
  }
  */
  window.alert("Actualizando " + appName + " version " + appVersion);
  // registration.waiting.postMessage({type: 'SKIP_WAITING'});
  updateServiceWorker(registration);
}


// Register Sevice Worker.
// Learn more about service workers: https://bit.ly/CRA-PWA
const swConfig = {
  onSuccess: registration =>  { registrationSuccessful(registration) },
  onUpdate: registration => { promptUserToRefresh(registration) },
};

serviceWorkerRegistration.register(swConfig);
