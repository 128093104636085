import { Component } from 'react';
import { CssBaseline } from "@material-ui/core";
import CardHeader from '@material-ui/core/CardHeader';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import log from 'loglevel';

import { featureTypes } from '../common/Constants';
import {MapService, mapLayers} from "../common/MapService";
import MapData from "../common/MapData";
import CardInfo from "./CardInfo";
import './MapComponent.scss';
import LogManager from "../common/LogManager";
import AppService from "../common/AppService";


/* eslint-disable */
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class MapComponent extends Component {
    // The map container element.
    mapContainer;

    constructor(props) {
        super(props);
        this.logger().debug('MapComponent constructor, props', props);

        this.state = {
            init: true,
            showDetail: false,
            detailData: null,
        }

        this.cancelCardClick = this.cancelCardClick.bind(this);
        this.reloadCardClick = this.reloadCardClick.bind(this);
        this.handleCardEvent = this.handleCardEvent.bind(this);

        this.initialLoading();
    }

    /**
     * Get logger.
     */
    logger() {
        return log.getLogger('MapComponent');
    }

    /**
     * Get map object.
     *
     * @return {Map} Mapbox Map
     */
    getMapObject() {
        return MapService.getMapObject();
    }

    /**
     * Create Map with initial position.
     */
    async initialLoading() {
        this.logger().debug('initialLoading');
        MapData.init();
    }

    /**
     * Create map after first initial rendering with
     */
    componentDidMount() {
        // Create map centered on initial location
        this.createMap(MapData.getInitLocation());

        // Show first card if any
        MapData.getInitCardData()
            .then(cardData => {cardData && this.setNewCardData(cardData)});
    }

    /**
     * Hide Card.
     */
    async hideCurrentCard() {
        if (this.state.showDetail) {
            this.setState({
                showDetail: false,
            });
            // Hide possibly hotel / city displayed with other zoom.
            if (!MapData.showHotels) {
                this.cleanHotels(false);
            }
            if (!MapData.showPlaces) {
                MapData.setSelectedCity();
            }
        }
    }

    /**
     * Show current card.
     */
    async showCurrentCard() {
        if (!this.state.showDetail && this.state.detailData) {
            MapService.relocateMapBeforeDetail(this.state.detailData);
            this.setState({
                showDetail: true,
            });
        }
    }


    /**
     * Set new Card Data for display.
     * @param {CardData} cardData
     */
    async setNewCardData(cardData) {
        cardData && MapService.relocateMapBeforeDetail(cardData);
        cardData && MapData.setCurrentCard(cardData);
        this.setState({
            showDetail: !!cardData,
            detailData: cardData,
        });
    }

    /**
     * Set card from place data if possible.
     * @param {PlaceData} placeData
     * @param {boolean} hideCurrent Hide current card before, depending on transition wanted
     */
    async setCardFromPlaceData(placeData, hideCurrent = true) {
        this.logger().debug('Set Card from Place', placeData);
        hideCurrent && this.hideCurrentCard();
        LogManager.closeLog();
        MapData.getCardFromPlaceData(placeData)
            .then(cardData => {
                cardData.clickPoint = placeData.clickPoint;
                this.setNewCardData(cardData);
                MapData.setSelectedPlace(placeData);
            });
    }

    /**
     * clean all accommodations and redraw.
     *
     * @param {boolean} keep_selected Whether to keep selected one.
     */
    cleanHotels(keep_selected = true) {
        this.logger().debug("cleanHotels keep_selected", keep_selected);
        MapData.cleanHotelMarkers(keep_selected);
        // It seems we need to delete from document too.
        this.cleanDocumentHotels(keep_selected);
    }

    /**
     * clean all hotel markers querying the document.
     *
     * @param {boolean} keep_selected Whether to keep selected one.
     */
    cleanDocumentHotels(keep_selected = true) {
        // remove all but selected
        const queryClass = '.accommodation' + (keep_selected ? ':not(.selected)' : '');
        const hotels =
            document.querySelectorAll('.mapboxgl-canvas-container.mapboxgl-interactive ' + queryClass);

        hotels.forEach((marker) => {
            marker.remove();
        });
    }

    /**
     * Reload / clean hotels, depending on zoom level.
     */
    async reloadHotels(bounds) {
        if (MapData.showHotels) {
            const hotelList = await MapData.getHotelsForBounds(bounds);
            this.cleanHotels();
            this.showHotelList(hotelList);
        } else {
            // Clean, but keep selected hotel visible.
            this.cleanHotels(MapData.hotelList.hasSelection());
        }
    }

    /**
     * Show new hotel list, add click events, etc...
     *
     * @param {HotelList} hotelList
     */
    async showHotelList(hotelList) {
        const map = this.getMapObject();
        hotelList.getDataItems().forEach(async (item) => {
            const el = item.createElement();
            el.addEventListener('click', (event) => {
                this.handleHotelClick(item, event);
            });
            item.addToMap(map);
        });
    }

    /*
     * Create map object, add event listenersHelper functions
     *
     * @param {LocationData} initLocation
     */
    async createMap(initLocation) {
        this.logger().debug('createMap');

        const mapCenter = [initLocation.longitude, initLocation.latitude];
        const mapZoom = initLocation.zoom;

        const map = MapService.createMap(this, this.mapContainer, mapCenter, mapZoom);

        // More stuff when map loaded.
        map.on('load', async () => {
            this.mapEventLoad(map);
            await MapService.mapEventLoad();
            MapService.updateMapComponent();
        });

        this.mapContainer.addEventListener('click', () => {
            MapData.cleanSelection();
        });
    }


    /**
     * Create map layers events.
     */
    async createMapLayersEvents(map) {
        // Interactive places
        map.on('mouseenter', mapLayers.interactiveCities, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', mapLayers.interactiveCities, () => {
            map.getCanvas().style.cursor = '';
        });
        map.on('click', mapLayers.interactiveCities, (event) => {
            this.mapEventFeatureClick(event, event.features[0]);
        });

        // Interactive tracks
        map.on('mouseenter', mapLayers.interactiveTracks, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', mapLayers.interactiveTracks, () =>{
            map.getCanvas().style.cursor = '';
        });
        map.on('click', mapLayers.interactiveTracks, (event) => {
            this.mapEventFeatureClick(event, event.features[0]);
        });
    }

    // Map load event.
    async mapEventLoad(map) {
        this.logger().debug("Component: mapEventLoad");
        if (this.state.init) {
            this.setState({
                init: false,
                // showDetail: false,
            });

            // Add controls, layers and buttons
            await MapService.createMapControls(map);
            await MapService.createMapLayers(map);

            this.createMapLayersEvents(map);

            // This will take care of layer visibility too.
            MapService.setMapLoaded();
        }
    }

    /**
     * Map mixed event. Invoked from MapService
     * @param {MapPosition} mapPos
     */
    async mapEventChanged(mapPos) {
        const {center, zoom, bounds} = mapPos;
        this.logger().debug("mapEventChanged (zoom, center)", zoom, center);
        MapData.setVisibility(zoom);
        await this.reloadHotels(bounds);
        MapData.showSelected();
        AppService.appcomponent.toggleOutOfMapCard(false);
        LogManager.closeLog();
    }

    /**
     * The current stage button has been clicked.
     */
    async mapEventClickStageControl() {
        if (MapData.selectedCard) {
            await MapService.zoomToStageLevel();
            this.setNewCardData(MapData.selectedCard);
        }
    }

    /**
     * Clicked map feature, handle event.
     * @param {Feature} Map Feature
     */
    async mapEventFeatureClick(event, feature) {
        this.logger().debug('mapEventFeatureClick', event);

        // Some event handling, grab here.
        if (event && event.originalEvent) {
            event.originalEvent.stopImmediatePropagation();
        }

        // const feature = event.features[0];
        const layer = feature.layer;
        let type;

        // The event layer will tell us the feature type.
        switch (layer.id) {
            case mapLayers.interactiveCities:
                type = featureTypes.localidad;
                break;
            case mapLayers.interactiveTracks:
                type = featureTypes.track;
                break;
            default:
            // We don't know this layer'
        }

        if (type) {
            this.handleFeatureClick(type, feature, event.lngLat);
        }
        else {
            this.logger().warn('Unknown map layer from feature click', layer, event);
        }
    }

    /**
     * Clicked map feature, handle event.
     *
     * @param {string} type Feature type
     * @param {object} feature Map feature
     */
    async handleFeatureClick(type, feature, point) {
        this.logger().debug('handleFeatureClick, type ' + type, feature);
        const placeData = MapData.getSelectedFeatureData(type, feature);
        if (placeData) {
            placeData.clickPoint = point;
            this.setCardFromPlaceData(placeData);
            // MapData.setSelectedPlace(placeData);
        }
    }

    /**
     * Clicked hotel, handle event.
     */
    async handleHotelClick(placeData, event) {
        event.stopPropagation();
        this.setCardFromPlaceData(placeData);
        // MapData.setSelectedHotel(placeData);
    }

    /**
     * Handle card click events: select, prev, next
     *
     * @param {string} eventType
     * @param {CardData} card
     */
    async handleCardEvent(eventType, card) {
        let newPlace;

        switch (eventType) {

            case CardInfo.EVENT_TYPE_SELECT:
                log.debug("Selected card", card);
                MapData.setSelectedCard(card);
                break;

            case CardInfo.EVENT_TYPE_NEXT:
                log.debug("Next card", card);
                newPlace = card.getNext();
                break;

            case CardInfo.EVENT_TYPE_PREV:
                log.debug("Prev card", card);
                newPlace = card.getPrev();
                break;

            default:
                log.error("Unknown card event type", eventType);
        }

        if (newPlace) {
            log.debug("Switching to new feature", newPlace);
            this.setCardFromPlaceData(newPlace, false);
        }
    }

    /**
     * Reload last loaded card data.
     */
    reloadCardClick() {
        this.hideCurrentCard();
        if (MapData.hasCardLoaded()) {
            this.showCurrentCard();
        }
    }

    /**
     * Event: Click cancel button on card.
     */
    cancelCardClick() {
        this.hideCurrentCard();
    }

    /**
     * Gets renderable card detail content.
     *
     * @param {CardData} cardData
     */
    getDetailInfo(cardData) {
        if (!this.props.allowDetail) return;

        const cardEvents = {
            cancelClick: this.cancelCardClick,
            reloadClick: this.reloadCardClick,
            handleCardEvent: this.handleCardEvent,
        };
        if (cardData && cardData.properties){
            const detailTitle = cardData.getTitle();
            const detailLink = cardData.getUrl();
            const disabledClass = detailLink === undefined ? 'disabled' : '';

            return <CardInfo
                title={detailTitle}
                disabledClass={'link-localidad ' + disabledClass}
                cardData={cardData}
                cardEvents={cardEvents}
                show={this.state.showDetail ? 'true' : 'false'}
            > </CardInfo>
        } else {
            // Fall back. Return empty card just in case.
            return <CardInfo
                title="Empty card"
                show={'false'}
            >
                <CardHeader title="Empty Card Content" />
            </CardInfo>
        }
    }

    /**
     * Used state properties:
     * -init
     * -showDetail
     * -detailData
     */
    render() {
        // this.logger().debug('Map.render', this.state);

        // to control detail view visibility
        document.getElementById('app-container')
            .classList.toggle('detailView', this.state.showDetail && this.props.allowDetail);

        return (
            // themeprovider to get material customization styles
            <div className='mapComponent'
                 onClick={() => AppService.appcomponent.toggleOutOfMapCard(false)}>
                <CssBaseline />
                <div ref={el => this.mapContainer = el} className="absolute top right left bottom" />

                {
                    this.getDetailInfo(this.state.detailData)
                }
            </div>
        );
    }
}

export default MapComponent;
