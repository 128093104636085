import debounce from 'lodash.debounce';
import { config, Map, Point, GeolocateControl, NavigationControl, ScaleControl } from 'mapbox-gl';

import GeoService from "./GeoService";
import BaseService from './BaseService';
import {dataTypes, geoPermStatus} from "./Constants";
import LocationData from "../data/LocationData";
import GeoLocation from "../data/GeoLocation";
import MapPosition from "../data/MapPosition";
import SatelliteControl from "../components/SatelliteControl";
import StageControl from "../components/StageControl";
import CardData from "../data/CardData";
import {GronzeGeolocateControl} from "../components/GronzeGeolocateControl";
import AppService from "./AppService";
import LogManager from "./LogManager";
import CityData from "../data/CityData";
import PermissionsService from "./PermissionsService";

const mapLayers = {
    // Coming from Mapbox service
    staticCities      : 'gronze-localidades',
    staticTracks      : 'gronze-tracks',
    satelliteLayer:     'mapbox-satellite',
    mapboxInteractive:  'mapboxgl-interactive',
    // For dynamic and selected items.
    selectedCities    : 'selected-cities',
    interactiveCities : 'interactive-localidades',
    selectedTracks    : 'selected-tracks',
    interactiveTracks : 'interactive-lines',
};

const mapDataSources = {
    selectedFeatures  : 'selected-features',
    // Just in case we need different datasources.
    selectedCities    : 'selected-cities',
    selectedTracks    : 'selected-tracks',
}

const orientations = {
    PORTRAIT: 'portrait',
    LANDSCAPE: 'landscape',
}

/**
 * Helper function to debounce map updates.
 */
const debounceUpdateMap = debounce(function() {
    MapService.updateMapDebounced();
}, 200);

/**
 * Provides access to map properties and wraps Mapbox features.
 */
class MapService extends BaseService {

    /**
     * @var {Map} Mapbox map object.
     */
    static mapObject;

    // The map container DOM element.
    static mapContainer;
    // @var {MapComponent} The React Component
    static mapComponent;

    // Reference to map component.
    static mapComponentRef;

    /**
     * @var {boolean} Map is fully loaded.
     */
    static mapLoaded;

    /**
     * @var {MapPosition} Current map data.
     */
    static mapPosition;

    // Map controls
    static geolocateControl;
    static navigationControl;
    static stageControl;

    /**
     * @var {string} Selected track id
     */
    static selectedTrackId;
    static currentTrackId;

    /**
     * @var {string} Selected route id
     */
    static selectedRouteId;

    /**
     * @var {numeric} Selected track zoom
     */
    static selectedTrackZoom;

    /**
     * Keep track of selected features.
     */
    static trackFeatures = {};

    /**
     * Get map object.
     *
     * @return {Map} Mapbox Map
     */
    static getMapObject() {
        return this.mapObject;
    }

    /**
     * Update map with latest map parameters.
     * This is called from debounceUpdateMap().
     */
    static updateMapDebounced() {
        this.debug("Update debounced");
        this.updateMapComponent();

        // If selected features and zoom is bigger, update selection for more detail.
        if (this.selectedTrackZoom) {
            this.showSelectedTrackFeatures();
        }
    }

    /**
     * Update map component with latest map parameters.
     */
    static updateMapComponent() {
        this.debug("Update map component");
        this.captureMap();
        this.setLayerVisibility();
        this.mapComponent.mapEventChanged(this.mapPosition);
    }

    /***
     * Gets current map location and zoom
     * @return {LocationData}
     */
    static getMapPosition() {
        // const location = this.getMapObject().getCenter();
        return new LocationData(this.mapPosition.center.lat, this.mapPosition.center.lng, this.mapPosition.zoom);
    }

    /**
     * Handle some map events.
     */
    static async mapEventMoved(event) {
        const map = event.target;
        this.updateFromMap(map);
        this.debug("Map moved to ", this.mapPosition.center);
        debounceUpdateMap();
    }

    static mapEventZoom(event) {
        const map = event.target;
        this.updateFromMap(map);
        this.debug("Map zoom to ", this.mapPosition.zoom);
        debounceUpdateMap();
    }

    static mapEventRotate(event) {
        const map = event.target;
        this.updateFromMap(map);
        const compassButton = document.querySelector('.mapboxgl-ctrl-compass');
        compassButton && (compassButton.classList.toggle('show', this.mapPosition.bearing !== 0));
        this.debug("Map rotate to ", this.mapPosition.bearing);
        debounceUpdateMap();
    }

    /**
     * Handles StageControl click.
     */
    static mapEventStageControlClick() {
        this.mapComponent.mapEventClickStageControl();
        // this.showSelectedTrackFeatures();
    }

    // Map load event.
    static async mapEventLoad() {
        // Add interactive layers
        // this.createMapLayers(this.mapObject);

        // Set event handlers
        this.mapObject.on('moveend', async (event) => {
            this.mapEventMoved(event);
        });

        this.mapObject.on('zoomend', (event) => {
            this.mapEventZoom(event);
        });

        this.mapObject.on('rotateend', (event) => {
            this.mapEventRotate(event);
        });
    }

    /**
     * Set map as loaded (from load event)
     */
    static setMapLoaded() {
        this.setLayerVisibility();
        this.mapLoaded = true;
        this.captureMap();
    }

    /**
     * Capture map position and zoom to use in next sessions
     */
    static captureMap() {
        const map = this.getMapObject();
        this.updateFromMap(map);
        GeoService.storeLocation(this.getMapPosition());
    }

    /**
     * Update location from map.
     */
    static updateFromMap(map) {
        this.mapPosition = MapPosition.fromMap(map);
    }

    /**
     * Destroy map handlers to remove map from memory
     *
     * Parameters:
     */
    static destroyMap() {
        if (this.mapObject) {
            this.mapObject.remove();
        }
    }

    /**
     * Change permission status handler fired from permissions service
     *
     * Parameters:
     * - value: permission status. One of geoPermStatus.
     */
    static changePermissionHandler(value) {
        this.debug('MapService.changePermissionHandler', value);
        // At this moment we use reload because a mapboxgl bug, but in future
        // versions here we will refresh map or button
        // See https://github.com/mapbox/mapbox-gl-js/issues/9741
        // UrlService.reloadWindow('Map.changePermissionHandler')
        // this.getMapObject().triggerRepaint();

        // Dirty trick to enable button
        // See https://github.com/mapbox/mapbox-gl-js/blob/main/src/ui/control/geolocate_control.js
        if (value === geoPermStatus.granted && '_geolocateButton' in this.geolocateControl) {
            this.geolocateControl._geolocateButton.disabled = false;
            // this.geolocateControl._geolocateButton.removeAttribute('disabled');
        }
    }

    /**
     * Create Mapbox Map.
     *
     * @param {Component} mapComponent
     * @param {Reference} mapContainer
     * @param {Array} mapCenter {lon, lat}
     */
    static createMap(mapComponent, mapContainer, mapCenter, mapZoom, minZoom) {
        this.debug('createMap, center', mapCenter);

        // Store / create objects.
        this.mapComponent = mapComponent;
        this.mapContainer = mapContainer;

        // Store some initial data.
        this.mapPosition = new MapPosition(GeoLocation.convert(mapCenter), mapZoom);

        // Go for the Mapbox Map
        const app_config = this.config();
        config.ACCESS_TOKEN = app_config.mapbox_access_token;

        this.mapObject = new Map({
            container: this.mapContainer,
            style: app_config.mapbox_style,
            center: mapCenter,
            zoom: mapZoom,
            minZoom,
            preserveDrawingBuffer: true,
            maxBounds: GeoService.getMapBounds(),
        });

        // Map loaded, register some more events.
        /*
        this.mapObject.on('load', async () => {
            this.debug("MapService: Map loaded");
            this.mapEventLoad(this.mapObject);
            this.mapComponent.mapEventLoad(this.mapObject);
        });
        */

        return this.mapObject;
    }

    /**
     * Create Map Controls.
     *
     * Triggers on map.load
     */
    static async createMapControls(map) {
        this.debug("createMapControls");
        const geolocationControlOptions = {
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true,
        };
        if (AppService.isIos() && AppService.isStandAlone()) {
            // add go to my position button
            this.geolocateControl = new GronzeGeolocateControl(geolocationControlOptions);
        } else {
            // add go to my position button
            this.geolocateControl = new GeolocateControl(geolocationControlOptions);
        }

        // we need to overwrite the success geocontrol function to close logs after click
        const geoControlSuccess = this.geolocateControl._onSuccess.bind(this);
        this.geolocateControl._onSuccess = (position) => {
            LogManager.closeLog();
            this.verifyIfOutside(position);
            geoControlSuccess(position);
        }
        this.geolocateControl.on('click', () => {
            // Request and initialize Location permissions
            PermissionsService.initGeoPermissionStatus(this.changePermissionHandler);
        });

        map.addControl(this.geolocateControl);

        map.addControl(new SatelliteControl());

        this.stageControl = new StageControl();
        map.addControl(this.stageControl);

        this.navigationControl = new NavigationControl({
            showCompass: true,
            showZoom: true,
        });
        map.addControl(this.navigationControl);

        map.addControl(new ScaleControl({
            maxWidth: 100,
            unit: 'metric'
        }));
    }

    /**
     * Create Map Layers.
     *
     * Triggers on map.load
     */
    static async createMapLayers(map) {
        this.debug("createMapLayers");
        const config = this.config();

        // See all layers in the map style, for debugging
        // this.debug("All layers", map.getStyle().layers)

        // Places (Localidades), add interactive layer *************************
        let trackLayer = map.getLayer(mapLayers.staticCities);

        map.addLayer({
                'id': mapLayers.interactiveCities,
                'type': trackLayer.type,
                'source': trackLayer.source,
                'source-layer': trackLayer.sourceLayer,
                'paint': {
                    'circle-radius': 12,
                    'circle-color': 'rgba(0,0,0,0)',
                },
                'minzoom': config.zoom_level_place_min,
            },
            mapLayers.staticCities);

        // add empty layer to show higlitgthed locations
        map.addSource(mapDataSources.selectedCities, {
            type: 'geojson',
            data: {
                "type": "FeatureCollection",
                "features": []
            }
        });

        map.addLayer({
                'id': mapLayers.selectedCities,
                'type': trackLayer.type,
                'source': mapDataSources.selectedCities,
                'paint': {
                    'circle-radius': 14,
                    'circle-color': 'rgba(256,0,0,0.5)',
                },
                'minzoom': config.zoom_level_place_min,
                'maxzoom': config.zoom_level_place_max
            },
            mapLayers.staticCities);

        // Tracks, add click controller *******************************
        trackLayer = map.getLayer(mapLayers.staticTracks);

        map.addLayer({
                'id': mapLayers.interactiveTracks,
                'type': trackLayer.type,
                'source': trackLayer.source,
                'source-layer': trackLayer.sourceLayer,
                'paint': {
                    'line-width': 30,
                    'line-color': 'rgba(0,0,0,0)',
                },
            },
            mapLayers.staticTracks);

        map.addSource(mapDataSources.selectedTracks, {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': []
            }
        });

        map.addLayer({
            'id': mapLayers.selectedTracks,
            'type': trackLayer.type,
            'source': mapDataSources.selectedTracks,
            'paint': {
                'line-width': 10,
                // @todo Style may be based on the existing track's color
                // Use a get expression (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-get)
                // to set the line-color to a feature property value.
                'line-color': 'rgba(255,0,0,0.5)',
            }
        }, mapLayers.staticTracks);

        this.debug("Added map layers and data sources");
    }

    /**
     * Gestiona la visibilidad de las capas interactivas dependiendo del zoom.
     * - After map loaded
     * - After map zoom changes
     */
    static setLayerVisibility() {
        const map = this.getMapObject();
        const zoom  = map.getZoom();
        this.debug("Set layer visibility for zoom", zoom);

        const way_visibility = (this.currentTrackId || GeoService.showCamino(zoom) || GeoService.showStages(zoom)) ? 'visible' : 'none';
        const locs_visibility = GeoService.showPlaces(zoom) ? 'visible' : 'none';

        map.setLayoutProperty(mapLayers.interactiveTracks, 'visibility', way_visibility);
        map.setLayoutProperty(mapLayers.interactiveCities, 'visibility', locs_visibility);
    }

    /**
     * Show/hide satelite layer
     *
     * @param {boolean} visible
     */
    static setSatelliteVisibility(visible) {
        this.getMapObject().setLayoutProperty(mapLayers.satelliteLayer, 'visibility', visible ? 'visible' : 'none');
    }

    /**
     * clean selected tracks and cities, but keep current track.
     */
    static cleanSelectedFeatures() {
        this.setSelectedRouteId(null, false);
        this.setSelectedTrackId(null, false)
        MapService.showSelectedTrackFeatures();
        MapService.setSelectedPlaces();
    }

    /**
     * Sets / unsets selected track id.
     * @param {array} ids Track id or null to unselect
     */
    static setSelectedTrackId(id = null, show = true) {
        this.debug("Selected track id", id);
        // Skip if not changed.
        if (this.selectedTrackId === id) return;

        if (id) {
            this.setSelectedRouteId(null, false);
            // If same as current track, do not add anything.
            if (this.currentTrackId === id ) {
                id = null;
            }
        } else if (this.selectedTrackId) {
            // Clean up previous data.
            delete this.trackFeatures[this.selectedTrackId];
        }

        this.selectedTrackId = id;

        show && this.showSelectedTrackFeatures();
    }

    /**
     * Sets / unsets current track id.
     * @param {string} id Track id or null to unselect
     */
    static setSelectedRouteId(id = null, show = true) {
        this.debug("Selected route id", id);

        if (id) {
            this.setSelectedTrackId(null, false);
        }

        this.selectedRouteId = id;

        show && this.showSelectedTrackFeatures();
    }

    /**
     * Sets / unsets current track id.
     *
     * @param {array} ids Track id or null to unselect
     */
    static setCurrentTrackId(cardData = null, show = true) {
        const id = cardData?.getMapId();
        this.debug("Current track id", id);

        if(this.currentTrackId === id) {
            this.currentTrackId = null;
            this.stageControl.setVisible(false);
        } else {
            if (id) {
                // Reset other tracks.
                this.selectedRouteId = null;
                this.selectedTrackId = null;
                this.stageControl.setVisible(true);
            }
            else if (this.currentTrackId) {
                // Clean up previous data.
                delete this.trackFeatures[this.currentTrackId];
                this.stageControl.setVisible(false);
            }

            this.currentTrackId = id;

            show && this.showSelectedTrackFeatures();
        }

        // force center on stage
        cardData.clickPoint = null;
        this.relocateMapBeforeDetail(cardData);
    }

    /**
     * Set track features to show on map.
     *
     * The currently selected tracks will be added automatically.
     */
    static showSelectedTrackFeatures() {
        let extra = [];
        let features = [];

        if (this.selectedTrackId) {
            extra = this.getTrackFeatures(this.selectedTrackId);
            features = features.concat(extra);
            this.debug("Selected features " + this.selectedTrackId, extra);
        }
        if (this.currentTrackId) {
            extra = this.getTrackFeatures(this.currentTrackId);
            features = features.concat(extra);
            this.debug("Current features " + this.currentTrackId, extra);
        }
        if (this.selectedRouteId) {
            extra = this.getRouteFeatures(this.selectedRouteId);
            features = features.concat(extra);
            this.debug("Route features", extra);
        }
        this.selectedTrackZoom = features.length ? this.mapPosition.zoom : 0;
        this.setSelectedFeatures(features, mapDataSources.selectedTracks);
    }

    /**
     * Set selected city features to show on map.
     */
    static setSelectedPlaces(features = []) {
        this.setSelectedFeatures(features, mapDataSources.selectedCities);
    }

    /**
     * Set features (cities) to show on map.
     */
    static setSelectedFeatures(features = [], dataSource) {
        this.getMapObject().getSource(dataSource).setData({
            "type": "FeatureCollection",
            features
        });
    }

    /**
     * Calculate new map center to display location.
     *
     * @todo This fails is center is not inside current bounds.
     *
     * @param {LocationData} pointLocation
     * @param {size} size of card
     * @param {force} force center using the point provided
     * @return {GeoLocation}
     */
    static getTranslationToCenter(pointLocation, size, force) {
        const map = this.getMapObject();
        const classList = document.documentElement.classList;
        const orientation = classList.contains('orientation_portrait') ? orientations.PORTRAIT : orientations.LANDSCAPE;
        const center = map.getCenter();
        const longitude = pointLocation.lng;
        const latitude = pointLocation.lat;
        const {lng, lat} = center;
        const markerPixels = map.project({ lat: latitude, lng: longitude});
        let newCords;
        if (orientation === orientations.PORTRAIT && (force || latitude < lat)) {
            const desvY = size === CardData.BIG ? 4 : 6;
            newCords = map.unproject(new Point(markerPixels.x, markerPixels.y + window.innerHeight/desvY));
        } else if (orientation === orientations.LANDSCAPE && (force || longitude < lng)) {
            newCords = map.unproject(new Point(markerPixels.x - window.innerWidth/4, markerPixels.y));
        } else {
            return null;
        }
        return new GeoLocation(newCords.lng, newCords.lat);
    }

    /**
     * Calculate new map center to display tracks.
     *
     * @param {bounds} Geolocation bounds
     * @param {size} CardData.BIG or CardData.small
     * @return {GeoLocation}
     */
    static getTrackPosition(bounds, size) {
        const map = this.getMapObject();
        const classList = document.documentElement.classList;
        const orientation = classList.contains('orientation_portrait') ? orientations.PORTRAIT : orientations.LANDSCAPE;
        const topRight = map.project(bounds._ne);
        const bottomLeft = map.project(bounds._sw);
        const markerPixels = map.project({ lat: bounds.getCenter().lat, lng: bounds.getCenter().lng});
        const desvY = size === CardData.BIG ? 2 : 2/3;
        if (orientation === orientations.PORTRAIT) {
            if(bottomLeft.x < 0 ||
                topRight.x > window.innerWidth ||
                topRight.y < 0 ||
                bottomLeft.y > window.innerHeight / desvY
            ) {
                const desvPos = CardData.BIG ? 4 : 6;
                return map.unproject(new Point(markerPixels.x, markerPixels.y + window.innerHeight/desvPos));
            }
        } else if (orientation === orientations.LANDSCAPE) {
            if(bottomLeft.x < window.innerWidth / 2 ||
                topRight.x > window.innerWidth ||
                topRight.y < 0 ||
                bottomLeft.y > window.innerHeight
            ) {
                return map.unproject(new Point(markerPixels.x - window.innerWidth/4, markerPixels.y));
            }
        }
        return null;
    }

    /**
     * Relocate map if needed to maintain clicked marker visible. Set max zoom
     *
     * @param {PlaceData} placeData
     * @param {CardData} cardData
     */
    static relocateMapBeforeDetail(cardData) {
        const map = this.getMapObject();
        let centerPoint, newLocation, newZoom;
        if (cardData.clickPoint) {
            newLocation = this.getTranslationToCenter(cardData.clickPoint, cardData.getDetailCardSize(), true);
        }
        if (!newLocation) {
            if (cardData.getGeoBounds()) {
                centerPoint = cardData.getGeoBounds().getCenter();
                newLocation = this.getTrackPosition(cardData.getGeoBounds(), cardData.getDetailCardSize());
                if (!newLocation) {
                    if (map.getBounds().contains(cardData.getGeoBounds().getCenter())) {
                        newLocation = this.getTranslationToCenter(centerPoint, cardData.getDetailCardSize());
                    } else {
                        newLocation = centerPoint;
                    }
                }
            } else {
                if (!cardData.geo_location) {
                    this.debug(`Problem looking for geo_location value on "${cardData.getTitle()}": ${cardData.getUrl()}; cancel map position and zoom relocating!`);
                    return;
                }
                centerPoint = newLocation = cardData.getGeoLocation();
                if (map.getBounds().contains(centerPoint)) {
                    newLocation = this.getTranslationToCenter(centerPoint, cardData.getDetailCardSize());
                } else {
                    newLocation = centerPoint;
                }
            }
        }

        const options = {duration: 1000};
        // Adjust zoom if needed. I.e. we fly to stage from a hotel,
        // then zoom is too big to see stage.
        const zoom = GeoService.getTypeZoomLevels(cardData.type);
        if (zoom && map.getZoom() > zoom.max && zoom.max > map.getZoom()) {
            this.debug("relocate/Zoom", zoom);
            newZoom = zoom.max;
            options.zoom = newZoom;
        }
        if (newLocation) {
            this.debug('relocateMap to Bounds center', centerPoint);
            if (newZoom && newZoom > map.getZoom()) {
                options.zoom = newZoom
            }
            map.panTo(newLocation, options);
        } else if (newZoom && newZoom > map.getZoom()) {
            map.zoomTo(newZoom, options);
        }
    }

    /**
     * Set the map zoom to the max stage zoon level
     */
    static zoomToStageLevel() {
        const zoom = GeoService.getTypeZoomLevels(dataTypes.stage);
        const duration = 700;
        const options = {duration};
        const map = this.getMapObject();
        map.zoomTo(zoom.max, options);
        // this timeout is needed to await for the zoom animation end
        return new Promise(resolve => setTimeout(resolve, duration));
    }

    /**
     * Get map features for city.
     *
     * @param {string} city_id
     */
    static getCityFeatures(city_id) {
        const options = {
            layers: [mapLayers.interactiveCities], // mapLayers.staticTracks],
            filter: ['==', ['get', 'id'], city_id],
        }
        return this.getMapObject().queryRenderedFeatures(undefined, options);
    }

    /**
     * Get map features for track.
     *
     * @param {string} route_id
     */
    static getRouteFeatures(route_id) {
        const options = {
            layers: [mapLayers.interactiveTracks], // mapLayers.staticTracks],
            filter: ['==', ['get', 'id'], route_id],
        }
        return this.getMapObject().queryRenderedFeatures(undefined, options);
    }

    /**
     * Get map features for track.
     *
     * @param {string} track_id
     */
    static getTrackFeatures(track_id) {
        const options = {
            layers: [mapLayers.interactiveTracks], // mapLayers.staticTracks],
            filter: ['==', ['get', 'track_id'], track_id],
        }

        let features = this.getMapObject().queryRenderedFeatures(undefined, options);

        // Maybe features are not visible, if so use stored ones.
        if (features.length) {
            this.trackFeatures[track_id] = features;
        }
        else if (track_id in this.trackFeatures) {
            features = this.trackFeatures[track_id];
        }
        return features;
    }

    static verifyIfOutside(position) {
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        const map_bounds = GeoService.getMapBounds();
        if(lon < map_bounds[0][0] ||
            lon > map_bounds[1][0] ||
            lat < map_bounds[0][1] ||
            lat > map_bounds[1][1]
        ) {
            AppService.appcomponent.toggleOutOfMapCard(true);
            this.geolocateControl._clearWatch();
        }
    }

    static gotoPlace(location) {
        const placeData = new CityData(dataTypes.place, location.id);
        placeData.name = location.name;
        this.mapObject.flyTo({
            center: [location.location.lng, location.location.lat],
            zoom: location.zoom,
            /*
            // open card and select city
            easing(t) {
                if (t === 1) {
                    console.log('*** ABRE CARD', location);
                    MapService.mapComponent.setCardFromPlaceData(placeData);
                    setTimeout(() => {
                        const features = MapService.getCityFeatures(placeData.id);
                        MapService.setSelectedPlaces(features);
                    }, 1000);
                }
                return t;
            }
             */
        });
    }

}

export {
    MapService,
    mapLayers,
};

export default MapService;
