import styles from './SliderShow.module.scss';
import {Component} from "react";
import Swipe from "react-easy-swipe";
import AppService from "../../common/AppService";

class SliderShow extends Component {
    // minimun swipe movement in pixels
    MIN_SWIPE_SIZE = 40;
    swipping = false;

    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 0,
        };
        this.getSlide = this.getSlide.bind(this);
        this.go = this.go.bind(this);
        this.goNext = this.goNext.bind(this);
        // swipe handlers
        this.handleSwipeStart = this.handleSwipeStart.bind(this);
        this.handleSwipeMove = this.handleSwipeMove.bind(this);
        this.handleSwipeEnd = this.handleSwipeEnd.bind(this);
    }

    go(newIndex) {
        this.setState({
            slideIndex: newIndex,
        })
    }

    goPrev() {
        if (this.state.slideIndex <= 0)
            return;
        this.go(this.state.slideIndex - 1);
    }

    goNext() {
        if (this.state.slideIndex >= this.props.slides.length - 1) {
            AppService.appcomponent.toggleInitialCard();
        }

        this.go(this.state.slideIndex + 1);
    }

    getSlide() {
        return this.props.slides.map((slide, index) => {
            if (this.state.slideIndex === index) {
                return <div key={'slide' + index}>
                    {slide}
                </div>
            }
        })
    }

    dotButtonclick(event, index) {
        event.stopPropagation();
        this.go(index);
    }

    /**
     * Swipe handlers (See SwipeableBox)
     */
    handleSwipeStart() {
        this.swipping = true;
    }

    handleSwipeMove(position) {
        if (!this.swipping) return;
        if (position.x > this.MIN_SWIPE_SIZE) {
            this.goPrev();
        } else if (position.x < -this.MIN_SWIPE_SIZE) {
            this.goNext();
        }
    }

    handleSwipeEnd() {
        this.swipping = false;
    }

    render() {
        return (
            <div className={styles.sliderShow} onClick={this.goNext}>
                <div className={styles.sliderViewport}>
                    <Swipe
                        onSwipeStart={this.handleSwipeStart}
                        onSwipeMove={this.handleSwipeMove}
                        onSwipeEnd={this.handleSwipeEnd}
                        className={styles.cardContentWrapper}
                    >
                        <div className={styles.sliderContainer}>
                            {this.getSlide()}
                        </div>
                    </Swipe>

                    <div className={styles.sliderDotButtons}>
                        {this.props.slides.map((_, index) => (
                            <button
                                key={'dot' + index}
                                className={this.state.slideIndex === index? styles.selected : ''}
                                onClick={(event) => {this.dotButtonclick(event, index)}}
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    }

}

export default SliderShow
