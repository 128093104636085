import BaseList from './BaseList';
import CityData from './CityData';

/**
 * Wrapper to manage lists of places (cities). 
 */
class CityList extends BaseList {
    
    // Max list size.
    maxSize = 10;
        
    /**
     * Build from list items from rest response.
     */
    static fromListItems(listItems) {
        const dataItems = [];
        
        listItems.forEach((item) => {
              dataItems.push(CityData.fromListItem(item));
        });
        
        return new CityList(dataItems);
    }
    
}

export default CityList;
