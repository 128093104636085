import Config from "./Config";
import BaseService from './BaseService';
import AppStorage from "../storage/AppStorage";
import UpdateService from "./UpdateService";
import UrlService from "./UrlService";
import AnalyticsAPIService from "./AnalyticsAPIService";
import { Device } from '@capacitor/device';

/**
 * Helper for App lifecycle management.
 */
class AppService extends BaseService {

    // device info returned from capacitor
    static deviceInfo;

    // Redirecting, wait until reload;
    static redirecting = false;

    /**
     * Define storage class
    */
    static storage = AppStorage;

    /**
     * offline state of the device
     */
    static offline = true;

    /**
     * Reference to the App component instance
     */
    static appcomponent;

    /**
     * Gets App name + version info.
     */
    static getAppInfoLabel() {
      return [Config.getAppName(), Config.getAppTag(), Config.getAppVersion()].join(' ');
    }

    /**
     * Gets current (running) app version.
     */
    static getCurrentVersion() {
      return process.env.REACT_APP_VERSION;
    }

    /**
     * Check whether App is installed.
     */
    static isInstalled() {
      return !!(this.storage.getInstallDate() || this.isStandAlone());
    }

    /**
     * Gets install information for debug.
     */
    static getInstallInfo() {
      return this.isInstalled() ?
        ['Installed', AppService.storage.getInstalledVersion(), AppService.storage.getInstallDate()].join(' ') :
        'Not Installed'
        ;
    }

    /**
     * Check app running standalone mode (is run as installed PWA or native).
     *
     * @see https://stackoverflow.com/questions/66009755/how-to-detect-if-pwa-is-already-installed-on-ios
     * @see window.matchMedia('(display-mode: standalone)').matches
     */
    static isStandAlone() {
        // native app version
        if(this.isNativeApp()) {
            return true;
        }
        // web / pwa version
        if ('standalone' in window.navigator) {
            // For iOS Only
            return window.navigator.standalone;
        } else {
            // Android
            return window.matchMedia('(display-mode: standalone)').matches;
        }
    }

    /**
     * Check app running native mode.
     */
    static isNativeApp() {
        return this.deviceInfo.platform === this.deviceInfo.operatingSystem;
    }

    /**
     * Detects if device is on iOS
     *
     * @see https://stackoverflow.com/questions/50543163/can-i-detect-if-my-pwa-is-launched-as-an-app-or-visited-as-a-website
     */
    static isIos() {
        return this.deviceInfo.operatingSystem === 'ios';
    }

    /**
     * Detects if is running as PWA installed or not
     *
     */
    static isPwa() {
        return this.deviceInfo.platform === 'web';
    }

    /**
     * Whether App needs user login.
     */
    static needsUserLogin() {
      return Config.isLoginRequired();
    }

    /**
     * Whether App must show User account / data.
     */
    static showUserAccount() {
      return Config.isLoginAllowed();
    }

    /**
     * Gets runtime device information.
     */
    static getDeviceInfo() {
      return [
        this.isIos() ? 'ios' : '',
        this.isPwa() ? 'pwa' : '',
        this.isStandAlone() ? 'standalone' : '',
        this.isNativeApp() ? 'native' : '',
      ].join(' ');
    }


    /**
     * Gets debug information.
     */
    static getDebugInfo() {
      return [Config.getAppEnv(), Config.getBuildEnv(), this.getDeviceInfo(), this.getInstallInfo(), UpdateService.getDebugInfo()].join(' ');
    }

    /**
     * Check for app updates if the time is right.
     */
    static async checkUpdates() {
      if (this.isPwa() && UpdateService.shouldCheckUpdates()) {
          UpdateService.checkUpdates();
      }
    }

    /**
     * Set redirecting state.
     */
    static setRedirecting(status = true) {
      this.redirecting = status;
    }

    /**
     * Check whether app is redirecting.
     * @return {boolean}
     */
    static isRedirecting() {
      return this.redirecting;
    }

    /**
     * Mark App as installed / not installed.
     *
     * @param {boolean} isInstalled
     */
    static setInstalled(isInstalled = true) {
      this.info("AppService.setInstalled", isInstalled);
      const version = Config.getAppVersion();
      if (isInstalled) {
        this.storage.setInstallDate();
        this.storage.setInstalledVersion(version);
        AnalyticsAPIService.sendEvent('App', 'Installed');
      }
      else {
        this.storage.clear();
      }
    }

    /**
     * Event callback: 'appinstalled'
     */
    static eventAppInstalled() {
      this.info('AppService.eventAppInstalled');
      this.setInstalled();
    }

    /**
     * Event callback: 'beforeinstallprompt'
     */
    static eventBeforeInstallPrompt(event) {
      this.info('AppService.eventBeforeInstallPrompt');
      // this.setInstalled(false);

      event.userChoice.then(choice => {
          this.info('AppService.eventBeforeInstallPrompt/choice', choice);
          AnalyticsAPIService.sendEvent('App', 'Add to home', choice.outcome);
      });

    }

    /**
     * Add device classes to body
     *
     * @see index.js
     */
    static setModelClass() {
        document.body.classList.add(this.deviceInfo.platform);
        let deviceModel = this.deviceInfo.model.replace(/\s/g, '_');
        let notch = '';

        if (deviceModel.indexOf('iPhone') !== -1) {
            deviceModel = 'iphone';
            notch = parseFloat(this.deviceInfo.model.substring(6).replace(',', '.')) > 14.6 ? 'notch' :  '';
            notch !== '' && document.body.classList.add(notch);
        }
        document.body.classList.add(deviceModel);
    }

    /**
     * Check whether app is opened for first time.
     * @return {boolean}
     */
    static isFirstTime() {
        if (AppStorage.getOpenedBefore())
            return false;

        return true;
    }

    /**
     * Initialize App.
     *
     * @see index.js
     */
    static async init() {
       try {
           this.deviceInfo = await Device.getInfo();
           this.debug("AppService.deviceInfo", this.deviceInfo);

           this.setModelClass();

           const initStatus = {
               isInstalled: this.isInstalled(),
               isStandAlone: this.isStandAlone(),
               isIos: this.isIos()
           };

           this.info("AppService.init", initStatus);

           UpdateService.init();

           // Track App Installs
           // @see https://www.billerickson.net/code/track-pwa-install-in-google-analytics/
           window.addEventListener('appinstalled', (event) => {
               AppService.eventAppInstalled(event);
           });

           window.addEventListener('beforeinstallprompt', (event) => {
               AppService.eventBeforeInstallPrompt(event);
           });

           this.debug("Succcessful init.");
           return true;
       } catch (e) {
           this.debug("Failed init", e);
           return false;
       }
    }

    /**
     * Set the reference of the app instance. We use this to call diferente handlers from eny component or service
     * @param {AppComponent} instance of the app component
     */
    static setAppComponent(instance) {
        this.appcomponent = instance;
    }

    /**
     * Initialize App // Second stage
     *
     * @see index.js
     */
    static async initAll() {
      // If we are redirecting somewhere else, like for Oauth callbacks, prevent further initialization.
      if (this.isRedirecting()) return false;
      AnalyticsAPIService.init();
      UrlService.init();

      // Send first pageview event.
      AnalyticsAPIService.sendPageView(UrlService.getPath());

      return true;
    }
}

export default AppService;
