/**
 * Wrapper to manage generic lists of places 
 */
class BaseList  {    
    /**
     * @var {Map} PlaceData items indexed by id
     */
    dataMap;
    
    // Max list size.
    maxSize = 100;

    /**
     * Other referenced objects.
     */
    mapObject;
    mapComponent;
    
    // Selected id
    selectedId;
    
    // Key field
    keyField = 'id';
        
    /**
     * Build from data Items.
     */
    constructor(placeDataList = [], keyField = 'id') {
      this.dataMap = new Map(placeDataList.map(i => [i[keyField], i]));
    } 
    
    /**
     * Add place item.
     */
    addPlace(placeData, replace = true) {
      if (replace || !this.dataMap.has(placeData.id)) {
        this.dataMap.set(placeData[this.keyField], placeData);
      }
      return this;
    }
    
    /**
     * Delete element and check selection.
     */
    deleteId(id) {
      if (this.selectedId && this.selectedId === id) {
        this.cleanSelection();
      }
      this.dataMap.delete(id);
      return this;
    }
    
    /**
     * Check max size, delete items if too many.
     */
    checkSize() {
      if (this.dataMap.size > this.maxSize) {
        const deleteKeys = Array.from(this.dataMap.keys()).slice(0, this.dataMap.size - this.maxSize);
        deleteKeys.forEach(key => {this.deleteId(key)});
        return deleteKeys;
      }
    }
    
    /**
     * Count items
     * @return int
     */
    countItems() {
      return this.dataMap.size;
    }
    
    
    /**
     * Add multiple place items.
     * @param {Array} placeList
     */
    addPlaceList(placeList) {
      placeList.forEach(item => {this.addPlace(item)});
      this.checkSize();
      return this;
    }
    
    /**
     * Get place by id.
     */
    getPlace(id) {
      return this.dataMap.get(id);
    }
    
    
    /**
     * Gets first item.
     */
    getFirst() {
      if (this.dataMap.size) {
        return this.getPlace(this.dataMap.keys().next().value);
      }
    }    
    
    /**
     * Clean all data.
     */
    clean() {
        this.cleanSelection();
        this.cleanData();
        return this;
    }
    
     /**
     * Clean all data.
     */
    cleanData() {
        this.dataMap.clear();
        return this;
    }
       
    /**
     * Clean selected items.
     */
    cleanSelection() {
        this.setSelectedId(null);
    }
    
    /**
     * Check for selected item.
     */
    hasSelection() {
        return this.selectedId && this.dataMap.has(this.selectedId);
    }
    
    /**
     * Gets selected map features.
     * 
     * @return {Array}
     */
    getSelectedFeatures() {
      const selected = this.getSelectedPlace();
      if (selected && selected.mapFeature) {
        return [selected.mapFeature];
      }
      else {
        return [];
      }
    }
    
    /**
     * Gets selected marker
     */
    
    /**
     * Set and highlight selected item id 
     */
    setSelectedId(id) {
      this.selectedId = id;
      return this; 
    }
    
    /**
     * Gets selected Place
     *
     * @return {PlaceData} 
     */
    getSelectedPlace() {
      return this.selectedId ? this.getPlace(this.selectedId) : null;
    }
    
    /**
     * Selected place data.
     * @param {PlaceData} place
     * @param {boolean} replace Replace items
     */
    setSelectedPlace(placeData) {
      this.addPlace(placeData);
      this.setSelectedId(placeData.id);
      // this.setSelectedMarker(placeData.mapMarker);
      return this;
    } 
    
    /**
     * Gets data items as array
     *
     * @return HotelData[];
     */
    getDataItems() {
      return Array.from(this.dataMap.values());
    }
    
    /**
     * Clean all item's markers
     */
    cleanMarkers() {
       this.getDataItems().forEach(async (item) => {
            item.mapMarker = null;
            /*
            const el = item.createElement();
            el.addEventListener('click', (event) => {
                this.handleHotelClick(item, event);
            });              
            item.addToMap(map);
            */
        });
    }
    
}

export default BaseList;
