import { Component } from 'react';
import AppService from "../common/AppService";
import UpdateService from "../common/UpdateService";
import {Box, Button, Divider, List, ListItem} from "@material-ui/core";

/**
 * Component to show some extra debug / test information.
 */
class TestInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.forceAppUpdate = this.forceAppUpdate.bind(this);
    }

    /**
     * Force App update
     */
    forceAppUpdate() {
        UpdateService.forceUpdate();
    }

    render() {
      return (
        <div className="test-info">
         <Divider />
         <Box textAlign="left" fontWeight="fontWeightLight" sx={{ bgcolor: 'primary.main' }} >
           <List>
             <ListItem>
               { AppService.getDebugInfo() }
             </ListItem>
             { AppService.isPwa() &&
             <ListItem>
               <Button variant="outlined" size="small" color="secondary" onClick={this.forceAppUpdate} >Force App update</Button>
             </ListItem>
             }
           </List>

         </Box>

        </div>
      );
    }
}

export default TestInfo;
