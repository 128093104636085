import BaseService from './BaseService';
import {dataTypes, featureTypeToDataType} from "./Constants";
import Request from "./Request";
import UserData from "../data/UserData";
import Config from "../common/Config";
import NetworkService from "./NetworkService";

/**
 * Handles backend connections through Request service and
 * returns promise-wrapped raw data from server.
 *
 * @todo Remove await and return Promises.
 */
class RestService extends BaseService {
    static hotelPrefix = 'hotel';
    static locationPrefix = 'place';
    static hotelPath = '/hotel/';
    static placePath = '/city/';
    static stagePath = '/stage/';
    static trackPath = '/track/';
    static pagePath = '/page/';

    // Path prefix for all the API
    static pathPrefix = '/maps20';
    // service error status
    static errorLoaded = false;

    /**
     * Gets rest URL for object type.
     *
     * @param {string} type Location type (hotel, place);
     */
    static getUrlForDataType(type, id) {
      let path;
      switch (type) {
        case dataTypes.hotel:
          path = this.hotelPath + id;
          break;
        case dataTypes.place:
          path = this.placePath + id;
          break;
        case dataTypes.stage:
          path = this.stagePath + id;
          break;
        case dataTypes.track:
          path = this.trackPath + id;
          break;
        case dataTypes.page:
          path = this.pagePath + id;
          break;
        default:
          // No action.
      }

      return path ? Config.getRestUrl() + this.pathPrefix + path : '';
    }

    /**
     * Gets rest URL for object type.
     *
     * @param {string} type Location type (hotel, place);
     */
    static getUrlForFeatureType(type, id) {
      return this.getUrlForDataType(featureTypeToDataType(type), id);
    }

    /**
     * Request data, handle errors.
     *
     * @param {string} url
     *   The request path with '.json' suffix.
     * @param {string} query_string
     *   Optional query string to append to the path.
     * @param {boolean} fullUrl
     *   True if the first parameter is a full Url
     * @param {boolean} withAuth
     *   True if we need to use authentication
     *
     * @return object
     *   Parsed response body if successful, empty otherwise.
     */
    static async getRawData(url, query_string = null, fullUrl = false, withAuth = true) {
        if (NetworkService.isOffline()) {
            return;
        }

        let urlData = url;

        if (!fullUrl) {
          urlData = this.pathPrefix + urlData;
        }

        if (query_string) {
          urlData = urlData + '?' + query_string;
        }

        try {
          const res = await Request.get(urlData, withAuth);
          // stores error status to reload data cases
          this.errorLoaded = !res || res.error;
          if (!this.errorLoaded) {
              return res;
          } else {
            this.logger().warn("RestService: Error response", res.error);
            return;
          }
        }
        catch (error) {
          this.logger().error("RestService: Error: ", error);
          this.errorLoaded = true;

        }

    }

     /**
      * Load from services by object type, id.
      *
      * @param {string} type, Data type
      *
      * @return {object} Returned raw data if successful
      */
     static async loadFromTypeId(type, id) {
       const url = this.getUrlForDataType(type, id);
       return this.getRawData(url, null, true);
     }

    /*
     * Get accommodations for given bounds.
     *
     * @param {LngLatBounds} bounds
     *   Map bounds, https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatbounds
     *
     * @return {HotelList}|none
     *   Parsed response body if any, none if empty
     */
    static async getHotels(bounds) {
        const geoLatMin = bounds._sw.lat;
        const geoLatMax = bounds._ne.lat;
        const geoLonMin = bounds._sw.lng;
        const geoLonMax = bounds._ne.lng;
        const urlPath = '/hotel_list.json';
        let urlData = '';
        urlData += 'geo_lat[min]=' + geoLatMin;
        urlData += '&geo_lat[max]=' + geoLatMax;
        urlData += '&geo_lon[min]=' + geoLonMin;
        urlData += '&geo_lon[max]=' + geoLonMax;

        return this.getRawData(urlPath, urlData);
    }

     /*
     * Get route list
     *
     * @return {RouteList}|none
     *   Parsed response body if any, none if empty
     */
    static async getRouteList() {
        const urlPath = '/route_list.json';
        const urlData = '';

        return this.getRawData(urlPath, urlData);
    }

    /**
     * Gets current user data.
     *
     * @return {UserData} | null User data if successful.
     */
    static async getCurrentUser() {
      const user_data = await this.getRawData('/status/current_user');
      if (user_data) {
        this.logger().debug("Received user data", user_data);
        return UserData.fromRawData(user_data.user);
      } else {
        return Promise.reject("Cannot load user data.");
      }
    }

    /**
     * Gets App status from server.
     *
     * @return {object} | null User data if successful.
     *   Object with 'app_active', 'app_version', 'app_config'
     */
    static async getAppStatus() {
      const app_data = await this.getRawData('/status/gronze_maps');
      if (app_data) {
        this.logger().debug("Received app status data", app_data);
        return app_data;
      }
    }

     /**
     * Gets App updates from server w/o authentication.
     *
     * @return {object} | null User data if successful.
     *   Object with 'app_active' and 'app_version'
     */
    static async getAppUpdates() {
      const app_data = await this.getRawData('/updates/gronze_maps', null, null, false);
      if (app_data) {
        this.logger().debug("Received app updates data", app_data);
        return app_data;
      }
    }
}

export default RestService;
