import { Component } from 'react';
import './GeoPermissionHelp.scss';
import i18n from "../i18n";
import { Trans } from 'react-i18next';
import {Box, Grid, Link, Typography} from "@material-ui/core";
import GeoService from "../common/GeoService";

/**
 * Display help about geo permissions.
 */
class GeoPermissionHelp extends Component {

    render() {
        const url = GeoService.selectUrlInstructionsByBrowser();
        return (
            <Box textAlign="left" fontWeight="fontWeightRegular">
                <Typography  variant="h5" component="h5" gutterBottom  color="primary">
                    { i18n.t('geoPermission.title') }
                </Typography>
                <div className="gronzeInfo">
                    <Trans i18nKey="geoPermission.helpText"><p>Geo Permission Help Text</p></Trans>
                </div>
                { url &&
                <Grid container direction="row" justifyContent="center">
                    <Link href={url} target="_blank" >
                        { i18n.t('geoPermission.link_geo') }
                    </Link>
                </Grid>
                }
            </Box>
        );
    }
}

export default GeoPermissionHelp;
