import {Component} from "react";
import styles from './SliderShow.module.scss';
import image1 from '../../assets/slides/img001.jpg';
import image2 from '../../assets/slides/img002.jpg';
import image3 from '../../assets/slides/img003.jpg';
import image4 from '../../assets/slides/img004.jpg';
import {Typography} from "@material-ui/core";


export class Slide1 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={styles.slide}>
            <img src={image1} alt='Selecciona etapa' />
            <p>
                Caminos y etapas en el mapa
            </p>
        </div>
    }
}

export class Slide2 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={styles.slide}>
            <img src={image2} alt='Selecciona etapa' />
            <p>
                Alojamientos cerca del camino
            </p>
        </div>
    }
}

export class Slide3 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={styles.slide}>
            <img src={image3} alt='Selecciona etapa' />
            <p>
                Información básica y contacto
            </p>
        </div>
    }
}

export class Slide4 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={styles.slide}>
            <img src={image4} alt='Selecciona etapa' />
            <p>
                Ubicación en tiempo real
            </p>
        </div>
    }
}

export class Slide5 extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={styles.slide}>
            <div className={styles.logo}>
                <div className="logo active logo-maps" alt="Logo Gronze Maps" />
            </div>

            <Typography variant="h3" component="h3">
                ¡Buen camino!
            </Typography>
        </div>
    }
}
