import CardData from './CardData';
import StageData from './StageData';
import RouteData from './RouteData';

/**
 * CardData / Stage Card - Etapas
 *
 * Data wrapper for object types:
 * - Stage
 */
class StageCard extends CardData {
    constructor(type, properties, data) {
      // Call parent constructor.
      super(type, properties, data);
      
      // Add extra properties.
      if (data) {
        if (!data.geo_location && data.geo_bounds && data.geo_bounds.lat && data.geo_bounds.lon) {
          // Geo bounds contains center lat / lon so we can just...
          this.geo_location = data.geo_bounds;
        }
        if (data.title_prefix) {
          this.subtitle = data.title_prefix;
        }
      }
    }

    /**
     * Returns track_id
     */
    getMapId() {
      return this.data ? this.data.track_id : null;
    }
    
    /**
     * Gets Route Data.
     * @return {RouteData}|null
     */
    getRouteData() {
      if (this.data && this.data.route_ref) {
        return RouteData.fromListItem(this.data.route_ref);
      }
    }
    
    /**
     * Overrides CardData.getSubtitle().
     */
    getSubtitle() {
      const route = this.getRouteData();
      const parts = [];
      if (this.data && this.data.title_prefix) {
        parts.push(this.data.title_prefix);
      }
      
      if (route) {
        parts.push(route.name);
      }
      if (parts.length) {
        return parts.join(' / ');
      }
      
    }
    
    /**
     * Stage cards can be selected.
     */
    canSelect() {
      return true;
    }

    /**
     * Navigation: has next?.
     *
     * @return {Boolean}
     */
    hasNext() {
        return this.data && this.data.stage_next_list && this.data.stage_next_list.length > 0;
    }

    /**
     * Navigation: has previous?.
     *
     * @return {Boolean}
     */
    hasPrev() {
        return this.data && this.data.stage_prev_list && this.data.stage_prev_list.length > 0;
    }

    /**
     * Navigation: Get next.
     *
     * @return {StageData}|null The first next stage.
     */
    getNext() {
      if (this.data && this.data.stage_next_list) {
        const stage = StageData.fromListItem(this.data.stage_next_list[0]);
        return stage;
      }
    }
    
    /**
     * Navigation: Get previous.
     *
     * @return {StageData}|null The first prev stage
     */
    getPrev() {
      if (this.data && this.data.stage_prev_list) {
        const stage = StageData.fromListItem(this.data.stage_prev_list[0]);
        return stage;
      }       
    }
}

export default StageCard;
