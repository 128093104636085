/**
 * Helper for Log and Error management.
 * This services must be used with the components/SnackComponent component
 * We need an instance of the Snackcomponent placed at the top level component of the app
 * This static service offers 4 services to show the diferent types os snackbars:
 * createError, createInfo, createWarning and createSuccess and has a message param to change de snackbar text
 */


class LogManager {

    static TYPE_ERROR = 'danger';
    static TYPE_INFO = 'info';
    static TYPE_SUCCESS = 'success';
    static TYPE_WARNING = 'warning';
    static LOG_EVENT = 'LogEvent';

    static logs = [];
    static open = false;

    static initialLogData = {
        visible: false,
        message: '',
    }

    static actualLog;
    static componentHandler;
    static componentCloseHandler;
    static blocked = false;

    /**
     * method need to connect snack component with log system using the componentHandler
     */
    static setLogHandler = (handler, closeHandler) => {
        this.componentHandler = handler;
        this.componentCloseHandler = closeHandler;
    }

    /**
     * internal method used to open new log
     */
    static nextLog = (message, type, block) => {
        this.open = true;
        this.actualLog = {
            message,
            type,
            block,
        }
        const data = {
            visible: true,
            message,
            type,
            block,
        };
        this.componentHandler && (this.componentHandler(data));
    }

    /**
     * method used from outside to open new log
     */
    static createLog = (message, type, block) => {
        if (this.blocked) {
            return;
        }

        this.blocked = block;
        if (this.open) {
            const log = {
                message,
                type,
                block,
            }
            this.logs = [log];
            const data = {
                visible: false,
                message: this.actualLog.message,
                type: this.actualLog.type,
                block,
            };
            this.componentHandler && (this.componentHandler(data));
        } else {
            this.nextLog(message, type, block);
        }
    }

    /**
     * method received from snackbar when is closed to detect if we need to open other
     */
    static deleteLog = () => {
        this.open = false;
        if (this.logs.length > 0) {
            const next = this.logs.pop();
            this.nextLog(next.message, next.type);
        }
    }

    /**
     * opens error log
     * @param message: string message to send
     */
    static createError = (message, block = false) => {
        this.createLog(message, this.TYPE_ERROR, block);
    }

    /**
     * opens warning log
     * @param message: string message to send
     */
    static createWarning = (message, block = false) => {
        this.createLog(message, this.TYPE_WARNING, block);
    }

    /**
     * opens info log
     * @param message: string message to send
     */
    static createInfo = (message, block = false) => {
        this.createLog(message, this.TYPE_INFO, block);
    }

    /**
     * opens success log
     * @param message: string message to send
     */
    static createSuccess = (message, block = false) => {
        this.createLog(message, this.TYPE_SUCCESS, block);
    }

    /**
     * Process network response erors.
     *
     * @param {Object} response The error.response object
     * @param {Object} error Optional original error object if available

     * @return {Object} Structured error object (type, message, error)
     */
    static processResponseError(response, error) {
        let message = 'Response ' + response.status;
        if (response.data) {
          if (response.data.error) {
            message += ' ' + response.data.error;
          }
          if (response.data.error_description) {
            message += ': ' + response.data.error_description;
          }
        }
        else if (error) {
          message += ': ' + error.message;
        }
        this.createError(message);
        return {type: 'response', message, error};
    }

    /**
     * Process network request erors.
     *
     * @see https://axios-http.com/docs/handling_errors
     *
     * @param {mixed} error
     *
     * @return {Object} Structured error object (type, message, error)
     */
    static processRequestError(error) {
      if (error.response) {
        return this.processResponseError(error.response, error);
      }
      else if (error.message) {
        this.createError(error.message);
        return {type: 'network', message: error.message, error};
      }
      else {
        this.createError(error);
        return {type: 'unknown', message: error, error};
      }
    }

    static closeLog() {
        this.blocked = false;
        this.componentCloseHandler && (this.componentCloseHandler());
        this.deleteLog();
    }
}


export default LogManager;
