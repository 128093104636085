import { Component } from 'react';
import './InitSessionScreen.scss';

import {CssBaseline} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import Grid from "@material-ui/core/Grid";
import LocationDisabledIcon from '@material-ui/icons/LocationDisabled';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import i18n from "../i18n";
import GeoService from "../common/GeoService";
import UrlService from "../common/UrlService";
import GeoPermissionHelp from './GeoPermissionHelp';


class GeoPermissionFailCard extends Component {

    constructor(props) {
        super(props);

        const url = GeoService.selectUrlInstructionsByBrowser();
        this.state = {
            url,
        }
    }

    /**
     * Button callbacks
     */
    reload = function() {
      UrlService.reloadWindow("Location permission");
    }

    enable = function() {
       const url = GeoService.selectUrlInstructionsByBrowser();
       if (url) {
          UrlService.openExternalUrl(url);
       }
    }

    close() {
        window.close();
    }

    render() {
        return (
            <div id="initSessionCard">
                <CssBaseline />
                <Card className='blockingCard'>
                    <CardContent>
                        <Grid container alignContent="center" spacing={2}>
                            <Grid item  xs={12}>
                                <LocationDisabledIcon color="disabled" variant="rounded" className="mainIcon"/>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography  variant="h5" component="h5" gutterBottom>
                                    {this.props.permissionStatus}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box className="help-text" >
                          <GeoPermissionHelp />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Grid container direction="row" justifyContent="center" >
                            { this.state.url &&
                            <Button color="primary" onClick={this.enable}>{ i18n.t('geoPermission.enableButton') }</Button>
                            }
                            <Button color="secondary" onClick={this.reload}>{ i18n.t('initSession.retry') }</Button>

                        </Grid>
                    </CardActions>
                </Card>

            </div>
        );
    }
}

export default GeoPermissionFailCard;
