import BaseList from './BaseList';
import HotelData from './HotelData';

/**
 * Wrapper to manage lists of hotels. 
 */
class HotelList extends BaseList {
      
    /**
     * Build from list items from rest response.
     */
    static fromListItems(listItems) {
        const dataItems = [];
        
        listItems.forEach((item) => {
              dataItems.push(HotelData.fromListItem(item));
        });
        
        return new HotelList(dataItems);
    }

}

export default HotelList;
