import ConfigDefault from './../config.default.json';
import ConfigEnv from './../config.json';
import log from 'loglevel';

/**
 * Wrapper class to manage configuration.
 */
class Config {

  // Configuration values.
  static values;

  /**
   * Gets App name.
   */
  static getAppName() {
    return this.isProduction() ? "Gronze Maps" : "Gronze Test";
  }

  /**
   * Gets App tag (Beta)
   */
  static getAppTag() {
    return "";
  }

  /**
   * Gets App version.
   */
  static getAppVersion() {
    return process.env.REACT_APP_VERSION;
  }

  /**
   * Gets App environment (production, testing, devel, local)
   */
  static getAppEnv() {
    return this.config().app_env;
  }

  /**
   * Gets build / node environment (development|production)
   */
  static getBuildEnv() {
    return process.env.NODE_ENV;
  }

  /**
   * Time to recheck app version or authentication, in seconds.
   */
  static getRefreshTime() {
    return this.getBuildEnv() === 'production' ? this.config().app_refresh_time : 60;
  }

  /**
   * Gets REST URL.
   *
   * @returns {string}
   */
  static getRestUrl() {
      return this.config().rest_url;
  }

  /**
   * Gets oauth2 scope.
   *
   * @returns {string}
   */
  static getOauth2Scope() {
      return this.config().oauth2_scope;
  }

  /**
   * Gets base Site URL.
   *
   * @returns {string}
   */
  static getSiteUrl() {
    return this.config().rest_url;
  }

  /**
   * Gets site links.
   */
  static getSiteLinks() {
    const baseUrl = this.getSiteUrl();

    return {
      help: baseUrl + '/gronze-maps',
      legal_usage: baseUrl + '/legal/condiciones-uso',
      legal_privacy: baseUrl + '/legal/politica-privacidad',
      legal_cookies: baseUrl + '/legal/politica-cookies',
      user_edit: baseUrl + '/user/'
    };
  }

  /**
   * Return TRUE if the Login is Required.
   */
  static isLoginRequired() {
    return this.config().app_login_required;
  }

  /**
   * Return TRUE if the we must to use analytics tracking.
   */
  static isAnalyticsEnabled() {
    return this.config().app_analytics_enabled;
  }

  /**
   * Return TRUE if the Login is Allowed.
   */
  static isLoginAllowed() {
    // For now, if not required, not allowed either. This may change...
    return this.isLoginRequired();
  }

  // get icons fonts from the configured server
  static addIconFont() {
    const styles = "@import url('" + this.getSiteUrl() + "/sites/all/themes/gronze2016/css/icons.css');"
    const styleSheet = document.createElement("style")
    styleSheet.type = "text/css"
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
  }

  /**
   * Get configuration values / stored / default config if not loaded.
   *
   * @returns {object} Configuration
   */
  static config() {
    if (!this.values) {
      this.init();
    }
    return this.values;
  }

  /**
   * Set configuration values. Init logging.
   *
   * @param object config
   *   Configuration data.
   */
  static init(config) {
    if (config) {
      log.debug("Init config with values", config);
      this.values =  Object.assign(ConfigDefault, config);
    } else {
      // Not loaded, use default
      this.values =  Object.assign(ConfigDefault, ConfigEnv);
      // log.debug("Init config", this.values)
    }

    // Initialize log.
    this.initLogging(this.values);

    // Copy over to window config for compatibility
    window.config = this.values;

    // Initialize Icon fonts.
    this.addIconFont();

    return this.values
  }

  /**
   * Initialize logging
   */
  static initLogging(config) {
    const level = localStorage.getItem('loglevel');

    if (level) {
      log.setLevel(level);
    }
    else {
      log.setLevel(config.app_loglevel);
    }
  }

  /**
   * Check production version / env
   */
  static isProduction() {
    return this.getAppEnv() === 'production' && this.getBuildEnv() === 'production';
  }
}

export default Config;
