import styles from './ModalComponent.module.scss';
import {Component, cloneElement} from "react";
import CancelIcon from "@material-ui/icons/Cancel";

class ModalComponent extends Component {

    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
    }

    close() {
        this.props.closeHandler();
    }

    render() {
        const content = <div className={styles.modalInside} style={{
            maxHeight: this.props.height,
            bottom: this.props.position === 'top' ? '40px' : 0,
            top: this.props.position === 'top' ? 'auto' : 0,
        }}>
            {cloneElement(this.props.children, { handleClose: this.close })}
            <div className={styles.cancelIcon + ' cancel-icon'}>
                <CancelIcon  variant="rounded" color="action" onClick={this.close}/>
            </div>
        </div>

        return (
            this.props.modal?
                <div className={styles.modal} >
                    {content}
                </div>
                :
                <div style={{
                    maxHeight: this.props.height,
                }}>
                    {content}
                </div>
        )
    }

}

export default ModalComponent
