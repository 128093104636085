import {useEffect} from 'react';
import { App } from '@capacitor/app';
import SiteAuth from "./SiteAuth";

const AppUrlListener = () => {
    // dejo comentado este estado porque me es cómodo recuperarlo para cuando necesito debugear en app firmada
    // const [mensaje, setMensaje] = useState('logs:');
    useEffect(() => {
        App.addListener('appUrlOpen', async (event) => {
            // Handle authentication callbacks.
            const authorized = await SiteAuth.checkAuthentication();
            const params = (new URL(event.url)).searchParams;
            if (authorized || params.get('state')) {
                SiteAuth.handleUrlEvent(event);
            }
        });
    }, []);

    return null;
    /* <p>
        {mensaje}
    </p>; */
};

export default AppUrlListener;
