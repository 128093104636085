import BaseService from './BaseService';
import AnalyticsAPIService from "./AnalyticsAPIService";
import AppService from "./AppService";
import Config from "./Config";
import {Browser} from "@capacitor/browser";

/**
 * Helper for Url management.
 */
class UrlService extends BaseService {

    // @var {string}
    static baseUrl;

    // @var {URLSearchParams}
    static urlParams;

    /**
     * Gets App Base url
     *
     * @return {string}
     */
    static getBaseUrl() {
        if (!this.baseUrl) {
          this.baseUrl = window.location.href.split('?')[0];
        }
        return this.baseUrl;
    }

    /**
     * Gets App Callback url
     *
     * @return {string}
     */
    static getCallbackUrl() {
      return AppService.isPwa() ?  window.location.origin : this.config().oauth2_redirect_url;
    }

    /**
     * Gets path.
     */
    static getPath() {
      return window.location.pathname;
    }

    /**
     * Get URL Params.
     *
     * @return {URLSearchParams}
     */
    static getUrlParams() {
        if (!this.urlParams) {
            const queryString = window.location.search.substr(1);
            this.urlParams = new URLSearchParams(queryString);
        }
        return this.urlParams;
    }

    /**
     * Clean window URL
     */
    static cleanUrl() {
      window.history.replaceState(null, null, window.location.pathname);
    }

    /**
     * Open external link (in new window).
     *
     * @param {string} url
     */
    static openExternalUrl(url) {
        this.debug("UrlService.openExternalUrl");
        AnalyticsAPIService.sendOutboundLink(url, () => {
          this.info("Outbound url sent to Analytics", url)}
        );

        return this.doOpenExternal(url);
    }

    /**
     * Open (gronze) site link (in new window).
     *
     * Similar to openExternalUrl but it doesn't report to analytics
     *
     * @param {string} url
     */
    static openSitelUrl(url) {
        this.debug("UrlService.openSiteUrl", url);

        return this.doOpenExternal(url);
    }

    /**
     * Open (gronze) site link (in new window).
     *
     * - Adds tracking utm_source utm_medium
     *
     * @param {string} path
     */
    static openSitePath(path = '/') {
        this.debug("UrlService.openSitePath", path);

        return this.openSiteUrl(this.buildSiteUrlFromPath(path));
    }

    /**
     * Builds site path, adding tracking parameters.
     */
    static buildSiteUrlFromPath(path = '/') {
        const cleanPath = path.split('?')[0];
        const queryString = path.split('?')[1];
        const params = new URLSearchParams(queryString);

        if (!params.has('utm_source')) {
          params.set('utm_source', 'gronze_maps');
        }

        if (!params.has('utm_medium')) {
          params.set('utm_medium', AppService.isInstalled() ? 'pwa_installed' : 'pwa_browser');
        }

        return Config.getSiteUrl() + cleanPath + '?' + params.toString();
    }

    /**
     * Do open external link. Internal API.
     *
     * Also to be used as hitCallback for ReactGA.outboundlink().
     */
    static doOpenExternal(url) {
      this.debug("UrlService.doOpenExternal", url);
      window.open(url, '_blank');
      return false;
    }

    /**
     * Reload window Location.
     *
     * @param {string} reason Optional description of the reload reason for logging.
     */
    static reloadWindow(reason = '') {
      this.logger().info("UrlService.reloadWindow(" + reason + ")");
      window.location.reload();
    }

    /**
     * Go to URL (Redirect)
     *
     * @param {string} url Full URL
     */
    static redirectToUrl(url) {
        this.debug("UrlService.redirectToUrl", url);
        if (AppService.isPwa()) {
            AppService.setRedirecting();
            window.location.replace(url);
        } else {
            this.openInBrowser(url);
        }
    }

    /**
     * Go to URL (Redirect)
     *
     * @param {string} url Full URL
     */
    static openInfo(url) {
        this.debug("UrlService.openInfo", url);

        this.openExternalUrl(url);
    }

    /**
     * Go to URL using inAppBrowser plugin
     *
     * @param {string} url Full URL
     */
    static async openInBrowser(url) {
        await Browser.open({url});
    }

   /**
    * Redirect base URL.
    */
    static redirectBaseUrl() {
        return this.redirectToUrl(this.getBaseUrl());
    }

    /**
     * Go to site path, different behavior:
     * - If browser, just redirect.
     * - If installed pwa, open new window.
     */
    static goToSite(path = '/') {
      const url = this.buildSiteUrlFromPath(path);
      return this.doOpenExternal(url);
    }

    /**
     * Go to web site (gronze)
     *
     * @todo User config.site_url
     */
    static redirectToSite() {
        return this.redirectToUrl('https://www.gronze.com/');
    }

    /**
     * Go to web site (gronze) and call logout
     *
     */
    static logout() {
        const redirectUrl = encodeURIComponent(this.getCallbackUrl());
        const logoutUrl = `${Config.getSiteUrl()}/app/logout?redirect_url=${redirectUrl}/logout`;

        // redirect the user to the authorize uri
        this.redirectToUrl(logoutUrl);
    }

    /**
     * Initialize service, from index.js
     *
     * @see index.js
     */
    static init() {
      this.debug("UrlService.init()");
      // Calculate before anything else.
      this.getBaseUrl();
    }
}

export default UrlService;
