import {Component} from "react";

class ExternalHTMLContent extends Component {

    constructor(props) {
        super(props);
    }

    render() { return (
        <div dangerouslySetInnerHTML={{ __html: this.props.contentHtml }} ></div>
    )}

}

export default ExternalHTMLContent;
