import React, { Component } from 'react';
import log from 'loglevel';
import styles from './CardInfo.module.scss';
import './CardInfo.scss';
import Typography from '@material-ui/core/Typography';
import {Card, CardActions, CardContent, CardHeader, CardMedia,Button, ButtonGroup} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Phone from "@material-ui/icons/Phone";
import WarningIcon from '@material-ui/icons/Warning';
import LockIcon from '@material-ui/icons/Lock';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Servicio from "./Servicio";
import i18n from "../i18n";
import UrlService from "../common/UrlService";
import {unescape} from 'lodash/string';
import Swipe from "react-easy-swipe";
import CardData from "../data/CardData";
import {dataTypes} from "../common/Constants";
import AnalyticsAPIService from "../common/AnalyticsAPIService";
import ExternalHTMLContent from "./ExternalHTMLContent";
import MapService from "../common/MapService";

/**
 * Helper classes / components.
 */
class BookingButton extends React.Component {
    constructor(props) {
      super(props);
      this.openBookingLink = this.openBookingLink.bind(this);
    }

    openBookingLink() {
        const url = this.props.url;
        log.debug('CardInfo.openBookingLink', url);
        UrlService.openExternalUrl(url);
        AnalyticsAPIService.sendOutboundLink(
            'Booking.com',
            url
        );
    }

    render() { return (
        <Button variant="text" size="small" onClick={this.openBookingLink}  className={styles.bookingButton}>
             Booking.com
        </Button>
    )}
}

class PhoneButton extends Component {
    constructor(props) {
      super(props);
      this.phoneButtonCall = this.phoneButtonCall.bind(this);
    }

    phoneButtonCall() {
      const url = 'tel:' + this.props.phoneNumber;
      log.debug('CardInfo.phoneButtonCall', url);
      UrlService.openExternalUrl(url);
        AnalyticsAPIService.sendOutboundLink(
            'phone button',
            url
        );
    }

    render() { return (
        <Button  variant="text" size="small" color="secondary" className={styles.phoneButton} onClick={this.phoneButtonCall} >
           <Phone  variant="rounded" color="secondary" className="phone-icon"/>
        </Button>
    )}
}

class GronzeButton extends Component {
    constructor(props) {
      super(props);
      this.gotoGronzeLink = this.gotoGronzeLink.bind(this);
    }

    gotoGronzeLink() {
        const url = this.props.url;
        log.debug('CardInfo.gotoGronzeLink', url);
        UrlService.openInfo(url);
        AnalyticsAPIService.sendOutboundLink(
            i18n.t('detailCard.goGronze'),
            url
        );
    }

    render() { return (
        <Button
            variant="text"
            size="small"
            color="secondary"
            className={styles.gronzeButton}
            onClick={this.gotoGronzeLink}
            endIcon={<OpenInNewIcon />}
        >
             { i18n.t('detailCard.goGronze') }
        </Button>
    )}
}

class CardImageHtml extends Component {
    // @var {string} imgHtml
    render() { return (
        <CardMedia className={styles.cardMedia}>
                <span dangerouslySetInnerHTML={{ __html: this.props.imgHtml }} ></span>
        </CardMedia>
    )}

}

/**
 * Component: Select card
 *  - cardId
 *  - isSelected
 */
class SelectButton extends Component {
    constructor(props) {
      super(props);

      this.clickEvent = this.clickEvent.bind(this);
      this.state = {
        selected: false,
      }
    }

    clickEvent(event) {
      this.props.handleCardEvent(CardInfo.EVENT_TYPE_SELECT, event);
      this.setState({
          selected: !this.state.selected,
      });
    }

    componentDidUpdate(prevProps) {
      // Refresh state only when component changes.
      if (this.props.cardId !== prevProps.cardId) {
        this.setState({selected: this.props.isSelected});
      }
    }

    render() {
      const selected = this.state.selected;
      // @todo Make text more generic, for other card types.
      const text = selected ? "Seleccionada" : "Seleccionar";
      const selectedStyle = selected ? "secondary" : "primary";

      return (
        <Button variant="contained"
                size="small"
                color={selectedStyle}
                onClick={ this.clickEvent }
                className={styles.selectButton}
        >
            {text}
        </Button>
      )
    }
}


class PrevButton extends Component {
    render() { return (
        <Button  variant="text" size="small" color="secondary"
          className={styles.prevButton} onClick={this.props.handleClick}
          disabled={this.props.disabled}>
            <span >Anterior</span>&nbsp;<span className={styles.mediumSizeIcon + " glyphicons glyphicons-chevron-right" }/>
        </Button>
    )}
}

class NextButton extends Component {
    render() { return (
        <Button  variant="text" size="small" color="secondary"
          className={styles.nextButton}
          onClick={this.props.handleClick}
          disabled={this.props.disabled}>
            <span className={styles.mediumSizeIcon + " glyphicons glyphicons-chevron-left" }/><span >Siguiente</span>
        </Button>
    )}
}

/**
 * Button group. Props:
 * - cardData
 * - handleShowNext
 * - hanbleShowNext
 * - handleSelectCard
 */
class CardSelectButtons extends Component {
    constructor(props) {
      super(props);

      // click to get next and prev
      this.handleShowPrev = this.handleShowPrev.bind(this);
      this.handleShowNext = this.handleShowNext.bind(this);

      this.state = {}
    }

        // handle go to prev stage button
    handleShowPrev(event) {
        this.props.handleCardEvent(CardInfo.EVENT_TYPE_PREV, event);
    }

    // handle go to next stage button
    handleShowNext(event) {
        this.props.handleCardEvent(CardInfo.EVENT_TYPE_NEXT, event);
    }

    render() {
        const card = this.props.cardData;
        return (
            <ButtonGroup disableElevation variant="contained" fullWidth className={styles.selectButtonsBox}>
                <NextButton handleClick={this.handleShowNext} disabled={ !card.hasNext() } />
                {card.canSelect() &&
                        <SelectButton
                           cardId={card.id} // Force card refreshing
                           handleCardEvent={ this.props.handleCardEvent }
                           isSelected={ card.selected } />
                }
                <PrevButton handleClick={this.handleShowPrev} disabled={ !card.hasPrev() }/>
            </ButtonGroup>
        )
    }
}

/**
 * Basic content card.
 *
 * Expanding functionality:
 * https://stackoverflow.com/questions/59938274/expanding-individual-cards-with-react-material-ui-card-expander
 */
class CardInfo extends Component {

     // minimun swipe movement in pixels
     MIN_SWIPE_SIZE = 40;

     // Define some event types for cards.
     static EVENT_TYPE_SELECT = 'select';
     static EVENT_TYPE_NEXT = 'next';
     static EVENT_TYPE_PREV = 'prev';

     swipping = false;

    constructor(props) {
        super(props);
        log.debug('props.cardData', props.cardData);

        this.state = {
            expanded: false,
            swipping: false,
        }

        // Action Buttons
        this.closeHandler = this.closeHandler.bind(this);
        this.toggleHandler = this.toggleHandler.bind(this);


        // Other controls
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleCardEvent = this.handleCardEvent.bind(this);

        // swipe handlers
        this.handleSwipeStart = this.handleSwipeStart.bind(this);
        this.handleSwipeMove = this.handleSwipeMove.bind(this);
        this.handleSwipeEnd = this.handleSwipeEnd.bind(this);

        // scroll bottom control
        this.handleScroll = this.handleScroll.bind(this);
    }

    // Unused atm
    handleExpandClick = () => {
      log.debug("Expand Click");
        this.setState({expanded: !this.state.expanded})
    };

    closeHandler() {
        this.setState({expanded: false});
        this.props.cardEvents.cancelClick();
    }

    toggleHandler() {
        const expanded = !this.state.expanded;
        this.setState({ expanded });
    }

    /**
     * Swipe handlers (See SwipeableBox)
     */
    handleSwipeStart() {
        this.swipping = true;
    }

    handleSwipeMove(position) {
        if (!this.swipping) return;
        if (position.y > this.MIN_SWIPE_SIZE) {
            if (this.state.expanded) {
                log.debug("Swipe down (Unexpand)");
                this.setState({
                    expanded: false,
                });
                this.swipping = false;
            } else {
                log.debug("Swipe down (Close)");
                this.swipping = false;
                this.closeHandler();
            }
        } else  if (position.y < -this.MIN_SWIPE_SIZE) {
            log.debug("Swipe up (Expand)");
            this.setState({
                expanded: true,
            });
            this.swipping = false;
        } else if (position.x > this.MIN_SWIPE_SIZE) {
            if (this.props.cardData.type === dataTypes.stage && this.props.cardData.hasNext()) {
                this.handleCardEvent(CardInfo.EVENT_TYPE_NEXT);
            }
        } else if (position.x < -this.MIN_SWIPE_SIZE) {
            if (this.props.cardData.type === dataTypes.stage && this.props.cardData.hasPrev()) {
                this.handleCardEvent(CardInfo.EVENT_TYPE_PREV);
            }
        }

    }

    handleSwipeEnd() {
        this.swipping = false;
    }

    handleScroll(event) {
        const { currentTarget } = event;
        if (currentTarget.scrollHeight - currentTarget.offsetHeight > 0 &&
            currentTarget.scrollTop !== 0) {
            event.stopPropagation();
        }
    }

    /**
     * We need this intermediate handler to be able to pass on current card
     * to MapComponent handler.
     */
    async handleCardEvent(eventType, event) {
        event && event.stopPropagation();
        await MapService.zoomToStageLevel();
        this.props.cardEvents.handleCardEvent(eventType, this.props.cardData);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show === 'false' && this.props.show === 'true' ||
            prevProps.title !== this.props.title) {
            const {cardType, title, url} = this.getTitles();
            this.sendAnalyticsOpenCardEvent(`[${cardType}] ${title}`, url);
        }
    }

    getTitles() {
        const title = unescape(this.props.title || this.props.cardData.getTitle()).replace('&#039;', "'");
        const url = this.props.cardData? this.props.cardData.getUrl() : '';
        const cardType = this.props.cardData? this.props.cardData.getType() : '';
        return {cardType, title, url};
    }

    async sendAnalyticsOpenCardEvent(title, url) {
        await AnalyticsAPIService.sendViewCard(
            title,
            url,
        );
    }

    render() {
        const expanded = this.state.expanded;
        const cardData = this.props.cardData;

        // Card class will depend on whether we've got image or not
        const cardClass = [styles.detailCard];
        if (this.props.show === 'true') {
            cardClass.push(styles.show);
            if (expanded) {
                cardClass.push(styles.expanded);
                cardClass.push('expanded');
            }
        } else {
            cardClass.push(styles.hide);
        }
        if (this.props.cardData && this.props.cardData.type === dataTypes.hotel) {
            cardClass.push(styles.hotel);
        }

        if (cardData) {
            if (cardData.getDetailCardSize() === CardData.BIG) {
              cardClass.push(styles.imageCard) ;
            }

        }

        // filter html entities from title
        const {title, subtitle} = this.getTitles();
        const warningText = cardData ? cardData.getWarningText() : '';
        const warningType = cardData && warningText ? cardData.getWarningType() : null;

        return (
        <div className={styles.cardContainer}>
            {cardData &&
            <Card className={cardClass.join(' ')}>

                <div className="swype-line" onClick={this.toggleHandler}/>
                <Swipe
                    onSwipeStart={this.handleSwipeStart}
                    onSwipeMove={this.handleSwipeMove}
                    onSwipeEnd={this.handleSwipeEnd}
                    className={styles.cardContentWrapper}
                >
                    <div className="cancel-icon card">
                        <CancelIcon  variant="rounded" color="action" onClick={this.closeHandler} />
                    </div>
                    <CardHeader
                        title={<Typography className='link-localidad-text' variant="h5" component="h5" gutterBottom>
                            {title}
                        </Typography>}
                        subheader={subtitle}
                    >
                    </CardHeader>
                    { cardData.imgHTML &&
                        <CardImageHtml imgHtml={cardData.imgHTML} />
                    }
                    { cardData.hasSelectionButtons() &&
                        <CardSelectButtons handleCardEvent={ this.handleCardEvent } cardData={ cardData }/>
                    }
                    <CardContent className={styles.cardContent} onTouchStart={this.handleScroll} >
                        <div className="card-content">

                            {warningText &&
                                <div className='nota-destacada'>
                                    <span>
                                        {warningType === CardData.WARNING_CLOSED &&
                                            <LockIcon variant="rounded" color="primary" className="lock-icon"/>
                                        }
                                        {warningType === CardData.WARNING_INFO &&
                                            <WarningIcon variant="rounded" color="primary" className="warn-icon"/>
                                        }
                                        {warningText}
                                    </span>
                                </div>
                            }

                            {cardData.tag_servicios &&
                            <div className='servicios-container'>
                                 {cardData.tag_servicios.map((servicio, index) => <Servicio data={servicio}  key={index}/>)}
                            </div>
                            }
                            <div className={styles.infoContainer}>
                                {cardData.extra_html && <ExternalHTMLContent contentHtml={cardData.extra_html} /> }
                            </div>
                        </div>
                    </CardContent>
                </Swipe>

                <CardActions className={styles.cardActions}>
                    <div className={styles.actionsContainer}>
                        { cardData.phoneNumber &&  <PhoneButton phoneNumber={cardData.phoneNumber} /> }
                        { cardData.bookingUrl &&   <BookingButton url={cardData.bookingUrl} /> }
                        { cardData.link &&         <GronzeButton url={cardData.link} /> }
                    </div>
                </CardActions>
            </Card>
            }
      </div>
      );
    }

}

export default CardInfo;
