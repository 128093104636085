import CardData from './CardData';

/**
 * CardData - City, Hotel
 *
 * Data wrapper for object types:
 * - Accommodation (Hotel, Hostel)
 * - Place (City, Monument)
 */
class PlaceCard extends CardData {

    constructor(type, properties, data) {
      super(type, properties, data);

      this.title = data.localidad_localidad;              
      if (data.tag_servicios) {
          this.tag_servicios = data.tag_servicios;
      }      

      this.geo_location = data.geo_location;
      this.bookingUrl = data.booking_url;
    }

    /**
     * Returns place[id]
     */
    getMapId() {
      return 'place' + this.id;   
    }
}

export default PlaceCard;
