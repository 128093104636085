import BaseStorage from "./BaseStorage";

/**
 * Storage service for App data.
 */
class AppStorage extends BaseStorage {

    // All names like 'user.xxx'
    static keyPrefix = 'app';

    /**
     * Gets storage object.
     *
     * @return {Storage}
     */
    static storage() {
      return window.localStorage;
    }

    /**
     * Gets intalled version.
     *
     * @return {string}}
     */
    static getInstalledVersion() {
      return this.getItem('install_version');
    }

    /**
     * Sets intalled version.
     *
     * @param {string}}
     */
    static setInstalledVersion(version) {
      this.setItem('install_version', version);
    }

    /**
     * Gets intalled date UTC.
     *
     * @return {string}
     */
    static getInstallDate() {
      const installed = this.getItem('install_date');
      return installed ? (new Date(parseInt(installed))).toLocaleString() : '';
    }

    /**
     * Sets install date, save as timestamp.
     */
    static setInstallDate() {
      this.setItem('install_date', Date.now());
    }

    /**
     * Remove all items in localStorage.
     */
    static reset() {
        sessionStorage.clear()
        this.storage().clear();
    }


    /**
     * Gets app is opened for first time.
     *
     * @return {boolean}}
     */
    static getOpenedBefore() {
        return this.getItem('opened_before') !== null &&
            this.getItem('opened_before') !== 'false';
    }

    /**
     * Sets app is opened for first time.
     *
     */
    static setOpenedBefore(value = true) {
        this.setItem('opened_before', value);
    }

}

export default AppStorage;
