import React, { Component } from 'react';
import './App.scss';
import {CircularProgress, CssBaseline} from "@material-ui/core";
import MapComponent from "./components/MapComponent";
import InitSessionScreen from "./components/InitSessionScreen";
import GeoPermissionFailCard from "./components/GeoPermissionFailCard";
import logoImage from "./assets/logo.png";
import Menu from "./components/Menu";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import log from 'loglevel';

import SiteAuth from "./common/SiteAuth";
import PermissionsService from "./common/PermissionsService";
import MapService from "./common/MapService";
import AppService from "./common/AppService";
import {geoPermStatus} from "./common/Constants";
import SnackComponent from "./components/SnackComponent";

import AppUrlListener from './common/AppUrlListener';
import UrlService from "./common/UrlService";
import AppStorage from "./storage/AppStorage";
import NetworkService from "./common/NetworkService";
import GronzePageService from "./common/GronzePageService";
import SliderShow from "./components/SliderShow/SliderShow";
import {Slide1, Slide2, Slide3, Slide4, Slide5} from "./components/SliderShow/Slides";
import ModalComponent from "./components/ModalComponent";
import GeoService from "./common/GeoService";
import LogManager from "./common/LogManager";
import {OutOfMapComponent} from "./components/OutOfMapComponent";

class App extends Component {
    // Reference to map component.
    mapRef;
    menu;
    appContainerRef;

    // Geo permissions options.
    lastGeoState;
    geoPermissionsGranted;

    constructor(props) {
        super(props);

        this.state = {
            mapVisible: false,
            authenticated: false,
            geoPermissionsFail: false,
            menuVisible: false,
            initialized: false,
            gronzePageComponent: null,
            isLoading: true,
            showInitialCard: AppService.isFirstTime(),
            initialToastShown: GeoService.isUsingConfigInitialLocation(),
            showModalCard: false,
        }

        this.toggleInitialCard = this.toggleInitialCard.bind(this);
        this.toggleOutOfMapCard = this.toggleOutOfMapCard.bind(this);
        this.handleScreenFocus = this.handleScreenFocus.bind(this);
        this.changePermissionHandler = this.changePermissionHandler.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.onUnautorize = this.onUnautorize.bind(this);
        this.onAutorize = this.onAutorize.bind(this);
        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);

        this.menu = React.createRef();
        this.mapRef= React.createRef();
        this.appContainerRef= React.createRef();

        AppService.setAppComponent(this);

        this.handleOpenPage = this.handleOpenPage.bind(this);
        this.handleClosePage = this.handleClosePage.bind(this);
        GronzePageService.init();

        this.statusRequestCallback = this.statusRequestCallback.bind(this);
    }

    /**
     * Screen focus event. Periodically re-check app version and authentication.
     */
    handleScreenFocus() {
      if (window.navigator.onLine) {
        this.checkUpdates();
      }
    }

    componentDidMount() {
      global.addEventListener('focus', this.handleScreenFocus, false);
      SiteAuth.authenticateHandle = this.onAutorize;

      this.initApp();
    }

    componentWillUnmount() {
      global.removeEventListener('focus', this.handleScreenFocus, false)
    }

    async initApp() {
        this.logger().debug("Initializing App");
        MapService.mapLoaded = false;
        this.loadEnv();

        NetworkService.init();

        if (!AppService.needsUserLogin()) {
            await SiteAuth.clientAuthenticate();
        } else if (!NetworkService.isOffline() && !AppService.isRedirecting() && await this.accessControl()) {
            this.loadMap();
        } else if (AppService.needsUserLogin() &&
            !this.state.authenticated &&
            window.navigator.onLine &&
            !this.state.initialized) {
            this.setState({
                isLoading: false,
            })
        } else {
          this.initPage();
        }

    }

    // Periodically re-check app version and authentication.
    async checkUpdates() {
        if (this.state.authenticated && this.state.initialized && SiteAuth.needsRefresh()) {
          // Recheck authentication, reload init page if fails.
          SiteAuth.refreshAuthentication().then((auth) => {
            if (!auth) this.initApp();
          });
        }

        AppService.checkUpdates();
    }

    /**
     * Get logger.
     */
    logger() {
      return log.getLogger('App');
    }

    /**
     * Gets map component.
     *
     * @return {MapComponent}
     */
    getMapComponent() {
      if (this.mapRef) {
        return this.mapRef.current;
      }
    }

    /**
     * Change permission status handler fired from permissions service
     *
     * Parameters:
     * - value: permission status. One of geoPermStatus
     */
    changePermissionHandler(value) {
        this.logger().debug('changePermissionHandler', value);

        this.geoPermissionsGranted = PermissionsService.geoPermissionGranted;

        // change state
        /*
        this.setState({
            mapVisible: PermissionsService.geoPermissionGranted,
            geoPermissionsGranted: PermissionsService.geoPermissionGranted,
        })
        */

        if (this.lastGeoState && this.lastGeoState !== geoPermStatus.prompt && this.state.mapVisible && this.getMapComponent()){
           MapService.changePermissionHandler(value);
        }

        this.lastGeoState = value;
    }

    /**
     * Check access control:
     * - If we are authenticated.
     * - Valid user is loaded.
     */
    async accessControl() {
      return await SiteAuth.checkAuthentication() && await SiteAuth.requireValidUser();
    }

    // load environment vars
    loadEnv() {
        window.env = {
            base_url: process.env.REACT_APP_BASE_URL,
        };
    }

    /**
     * Initial page when there's some error condition.
     */
    async initPage() {
        // show error cards
        this.setState({
            authenticated: SiteAuth.isValidUser(),
            geoPermissionsGranted: PermissionsService.geoPermissionGranted,
            mapVisible: false,
            initialized: false,
        })

    }

    // the last step: load the map
    async loadMap() {
        // show error cards
        this.setState({
            authenticated: true, // SiteAuth.isValidUser(),
            geoPermissionsGranted: PermissionsService.geoPermissionGranted,
            mapVisible: true,
            initialized: true,
        })
    }

    /**
     * Menu handling functions
     */
    toggleMenu() {
        this.setState({
            menuVisible: !this.state.menuVisible,
            showModalCard: false,
        })
    }

    closeMenu() {
        if (this.state.menuVisible){
            this.setState({
                menuVisible: false,
            })
        }
    }

    // Just some callbacks required by SwipeableDrawer (?)
    onMenuOpen = function() {
        log.debug("onMenuOpen");
        this.setState({
            menuVisible: true
        });
    };

    onMenuClose = function() {
        log.debug("onMenuClose");
        this.setState({
            menuVisible: false
        });
    };

    /**
     * Sends app to map view after login
     */
    onAutorize() {
        // Request and initialize Location permissions
        PermissionsService.initGeoPermissionStatus(this.changePermissionHandler);
        this.setState({
            authenticated: true,
            mapVisible: true,
        })
    }

    onUnautorize() {
        SiteAuth.resetAuthentication();
        MapService.destroyMap();
        this.setState({
            authenticated: false,
            mapVisible: false,
        })
        AppStorage.reset();
        // remove authorization from inAppBrowser session
        UrlService.logout();
    }

    async handleOpenPage(component) {
        await this.getMapComponent().hideCurrentCard();
        this.setState({
            gronzePageComponent: component,
        })
    }

    handleClosePage() {
        this.setState({
            gronzePageComponent: null,
        })
    }

    statusRequestCallback(isLoading) {
        this.setState({
            isLoading
        })
    }

    toggleInitialCard(value) {
        AppStorage.setOpenedBefore(value);

        this.setState({
            showInitialCard: value,
        })
    }

    toggleOutOfMapCard(value) {
        this.setState({
            showModalCard: value,
        })
    }

    render() {
        let mapComponent;
        let appMenu;
        let classActiveMenu = '';
        let modalCard;

        if (this.state.mapVisible) {
            mapComponent = <MapComponent
                ref={this.mapRef}
                allowDetail={!this.state.menuVisible}
            />
            appMenu = <Menu
                ref={this.menu}
                unautorize={this.onUnautorize}
            />
            classActiveMenu = 'active';

            if (this.state.showInitialCard) {
                modalCard = <ModalComponent
                    height="90vh"
                    modal={true}
                    closeHandler={this.toggleInitialCard}
                >
                    <SliderShow
                        slides={[
                            <Slide1 key="slide1"/>,
                            <Slide2 key="slide2"/>,
                            <Slide3 key="slide3"/>,
                            <Slide4 key="slide4"/>,
                            <Slide5 key="slide5"/>,
                        ]}
                        options={{ dragFree: true, containScroll: 'trimSnaps' }}
                    />
                </ModalComponent>;
            } else if (GeoService.isUsingConfigInitialLocation() && this.state.initialToastShown) {
                setTimeout(() => {
                    this.setState({
                        initialToastShown: false,
                    });
                    LogManager.createInfo('Pulsa el icono de Ubicación o selecciona un Camino', true);
                }, 1000);
            } else if (this.state.showModalCard) {
                modalCard = <ModalComponent
                    height="35vh"
                    modal={false}
                    position="top"
                    closeHandler={this.toggleOutOfMapCard}
                >
                    <OutOfMapComponent></OutOfMapComponent>
                </ModalComponent>;
            }
        }
        let blockedApCard;

        if (!this.state.authenticated &&
            window.navigator.onLine &&
            !this.state.initialized &&
            !this.state.isLoading
        ) {
            // init session error
            blockedApCard = <InitSessionScreen />
        } else if (this.state.geoPermissionsFail && this.state.initialized) {
            // geolocation permission not granted
            blockedApCard = <GeoPermissionFailCard />
        }

        let gronzePage;
        if (this.state.gronzePageComponent !== null) {
            gronzePage = GronzePageService.getPage(this.state.gronzePageComponent);
        }

        let isLoading;
        if (this.state.isLoading) {
            isLoading = <CircularProgress
                size={60}
                thickness={6}
                className="circularProgress"
            />;
        }

        return (
            // themeprovider to get material customization styles
            <div className='appComponent' onClick={this.closeMenu} ref={this.appContainerRef} >
                <AppUrlListener></AppUrlListener>
                <CssBaseline />
                { mapComponent }
                { blockedApCard }
                <header>
                    <img className={ 'logo ' + classActiveMenu } src={logoImage} alt="Logo Gronze" onClick={this.toggleMenu} />
                </header>
                { gronzePage }
                <SwipeableDrawer
                    open={this.state.menuVisible}
                    onOpen={this.onMenuOpen}
                    onClose={this.onMenuClose}
                    disableSwipeToOpen={false}
                    hysteresis={0.5}
                >
                    { appMenu }
                </SwipeableDrawer>

                <SnackComponent />

                { isLoading }

                { modalCard }

            </div>
        );
    }

}

export default App;
