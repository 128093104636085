import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import App from "../App";

const primaryColor = '#5f3411';
// const secondaryColor = '#719848';

const theme = createTheme({
    palette: {
        primary: {
            main: '#8e571f',
        },
        secondary: {
            main: '#719848',
        },
    },
    typography: {
        fontFamily: 'Source Sans Pro, sans-serif',
        h3: {
            fontSize: '1.7em',
            fontWeight: 'bold',
            color: primaryColor,
        },
        h5: {
            color: '#000000',
            'font-size': '1.4em',
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': ['src:url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&display=swap)'],
            },
        },
    },

});


function Theming() {

    return (
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    );
}

export {
    theme,
    Theming,
}
