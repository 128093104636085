import {Component} from 'react';
import Config from '../common/Config';
import styles from './OutOfMapComponent.module.scss';
import {Typography, Button} from "@material-ui/core";
import MapService from "../common/MapService";
import i18n from "../i18n";
import AppService from "../common/AppService";

export class OutOfMapComponent extends Component {
    constructor(props) {
        super(props);
    }

    getRecommendedLocations() {
        const locations = Config.values.recommended_locations;
        return locations.map((location) =>
            <li key={`recommended_${location.id}`}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        AppService.appcomponent.toggleOutOfMapCard(false);
                        MapService.gotoPlace(location);
                    }}
                >{location.name}</Button>
            </li>
        );
    }

    render() {
        return <div className={styles.outOfMap} >
            <div>
                <Typography variant="h5" component="h5">
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html: i18n.t('outOfMap.title') }} ></div>
                    <div className={styles.message} dangerouslySetInnerHTML={{ __html: i18n.t('outOfMap.message') }} ></div>
                </Typography>
                <ul>{this.getRecommendedLocations()}</ul>
            </div>
        </div>
    }
}
