import BaseService from './BaseService';
import MapService from "./MapService";
import GeoService from "./GeoService";
import DataService from "./DataService";
import {dataTypes, featureTypes} from "./Constants";
import CityList from "../data/CityList";
import HotelList from "../data/HotelList";
import CityData from "../data/CityData";
import StageData from "../data/StageData";
// import GeoBounds from "../data/GeoBounds";

/**
 * Service / Manager for data linked to Map.
 * - Handles selected and displayed features.
 * - Gets Card data from map features.
 * - Retrieves data objects from DataService.
 */
class MapData extends BaseService {
    // Store inital location and place data
    static initLocation;
    static initPlaceData;

    // @var {Promise}
    static initCardData;

    // Store last place selected.
    // @var {PlaceData}
    static lastPlaceData;

    /**
     * Selected card data.
     *
     * @var {CardData}
     */
    static selectedCard;

    /**
     * @var {HotelList} Hotel list
     */
    static hotelList;

    /**
     * @var {CityList} City list
     */
    static cityList;

    /**
     * @var {LngLatBounds} Bounds for this list
     */
    static mapBounds;

    /**
     * Map zoom level. Used to control element visibility.
     */
    static zoom;

    // These will depend on zoom
    // @var {boolean}
    static showHotels;
    static showPlaces;
    static showStages;
    static showRoutes;

    /**
     * Build from data Items.
     */
    static init() {
        this.cityList = new CityList();
        this.hotelList = new HotelList();
        DataService.init();

        this.initLocation = GeoService.getInitialPosition();
        this.debug('Initial Location', this.initLocation);

        this.initPlaceData = GeoService.getPlaceFromUrl();

        if (this.initPlaceData) {
            this.debug('Initial Place', this.initPlaceData);
            this.initCardData = this.getCardFromPlaceData(this.initPlaceData);
        }
        else {
            this.initCardData = Promise.resolve(false);
        }

    }

    /**
     * Set visibility from zoom level.
     *
     * @param {float} zoom
     */
    static setVisibility(zoom) {
        this.zoom = zoom;
        this.showHotels = GeoService.showHotels(zoom);
        this.showPlaces = GeoService.showPlaces(zoom);
        this.showStages = GeoService.showStages(zoom);
        this.showRoutes = GeoService.showRoutes(zoom);
    }

    /**
     * Get initial place data, if any.
     */
    static getInitLocation() {
      return this.initLocation;
    }

    /**
     * Get initial place data, if any.
     */
    static getInitPlaceData() {
      return this.initPlaceData;
    }

    /**
     * Get initial card data.
     * @return {Promise}
     */
    static async getInitCardData() {
      return this.initCardData;
    }

    /**
     * Gets city from map feature.
     */
    static getCityFromMapFeature(feature) {
      return CityData.fromMapFeature(feature);
    }

    /**
     * Gets Route from map feature.
     */
    static getRouteFromMapFeature(feature) {
      if (feature.properties.route_id) {
        return DataService.getRouteByKey(feature.properties.route_id);
      }
    }

    /**
     * Gets Stage from map feature.
     */
    static getStageFromMapFeature(feature) {
      const routeData = this.getRouteFromMapFeature(feature);
      if (routeData) {
        const stageData = StageData.fromMapFeature(feature);
        stageData.routeData = routeData;
        return stageData;
      }
    }

    /**
     * Translates selected feature to feature data.
     * Takes into account curren zoom and visibility.
     *
     * @param {string} type
     * @param {object} feature
     *
     * @return {FeatureData}|null
     */
    static getSelectedFeatureData(type, feature) {
      switch (type) {
        case featureTypes.localidad:
          return this.showPlaces && MapData.getCityFromMapFeature(feature);
          // break;

        case featureTypes.track:
          if (this.showStages) {
            return MapData.getStageFromMapFeature(feature);
          }
          else if (this.showRoutes) {
            return MapData.getRouteFromMapFeature(feature);
          }
          break;

        default:
          throw new Error("Cannot handle click on feature type: " + type, feature);
      }

    }

    /**
     * Check last card is loaded.
     */
    static hasCardLoaded() {
      return this.lastPlaceData && this.lastCardData;
    }

    /**
     * Check place data is the current one.
     * @param {PlaceData} placeData
     */
    static isCurrentPlaceData(placeData) {
        return this.lastPlaceData && this.lastPlaceData.matchFeatureData(placeData);
    }

     /**
      * Get card data from place data.
      *
      * @param {PlaceData|HotelData} placeData
      *
      * @return {CardData} ({Promise})
      */
     static async getCardFromPlaceData(placeData) {
       if (this.isCurrentPlaceData(placeData)) {
          if (this.lastCardData) {
              this.debug('Card already loaded / showing');
              return this.lastCardData;
          } else {
              this.debug('Last Card not loaded / error. Skip')
              return null;
          }
        } else if (this.selectedCard && placeData.matchCardData(this.selectedCard)) {
          this.debug('Card is selected one, already loaded.');
          return this.selectedCard;
        } else {
              this.lastPlaceData = placeData;
              this.lastCardData = null;
              return DataService.loadFromPlaceData(placeData)
                  .then(cardData => {
                     // this.lastPlaceData = this.placeData;
                     this.lastCardData = cardData;
                     return cardData;
                   });
       }
     }

    /**
     * Get accommodations for given map bounds. Load when needed.
     *
     * @param {LngLatBounds} bounds Map bounds
     * @return {HotelList}
     */
    static async getHotelsForBounds(bounds) {
        if (this.mapBounds && this.mapBounds.containsBounds(bounds)) {
            // New bounds are contained in previous ones, no need to reload.
            this.debug('Contained bounds, no need to reload', bounds);
        }
        else {
            // Expand bounds to load a 10% more
            this.mapBounds = GeoService.expandBounds(bounds, 0.1);
             // Set new bounds and reload hotels.
            const hotelList = await DataService.loadHotels(this.mapBounds);

            if (hotelList) {
              this.debug("Loaded hotels", hotelList.countItems());
              // Replace items, keep selection.
              this.hotelList.cleanData().addPlaceList(hotelList.getDataItems());
            }
            else {
              this.cleanHotelList();
            }
        }

        return this.hotelList;
    }

    /**************************************
     * Map selections: hotels, locations.
     **************************************/

    /**
     * Highlight whatever object is selected.
     */
    static showSelected() {
      const cityList = this.cityList;
      const hotelList = this.hotelList;

      if (cityList.hasSelection()) {
         MapService.setSelectedPlaces(cityList.getSelectedFeatures());
      } else if (hotelList.hasSelection()) {
         hotelList.getSelectedPlace().setSelected();
      }
    }

    /**
     * Set and highlight selected hotel.
     *
     * @param {PlaceData} | null
     */
    static setSelectedHotel(placeData) {
      if (placeData) {
        this.debug("Selected Hotel", placeData.id, placeData.name);
        this.cleanSelection();
        this.hotelList.setSelectedPlace(placeData);
        this.showSelected();
      } else if (this.hotelList.hasSelection()) {
        this.hotelList.getSelectedPlace().setSelected(false);
        this.hotelList.cleanSelection();
      }
    }

    /**
     * Set and highlight selected location
     *
     * @param {PlaceData} | null
     */
    static setSelectedCity(placeData) {
      if (placeData) {
        this.debug("Selected City", placeData.id, placeData.name);
        this.cleanSelection();
        this.cityList.setSelectedPlace(placeData);
        // Temporary, check size every time.
        this.cityList.checkSize();
        this.showSelected();
      } else if (this.cityList.hasSelection()) {
        this.cityList.cleanSelection();
        MapService.setSelectedPlaces([]);
      }
    }

    /**
     * Set seleted place, generic.
     *
     * @param {PlaceData}
     */
    static setSelectedPlace(placeData) {
      // If missing map_id, skip
      const map_id = placeData.getMapItemId();

      if (!map_id) return;

      // clean before draw
      MapService.cleanSelectedFeatures();

      switch (placeData.type) {
        case dataTypes.place:
          this.setSelectedCity(placeData);
          break;
        case dataTypes.hotel:
          this.setSelectedHotel(placeData);
          break
        case dataTypes.route:
          MapService.setSelectedRouteId(map_id);
          break;
        case dataTypes.stage:
          MapService.setSelectedTrackId(map_id);
          break;
        default:
          // No action.
      }
    }

    /**
     * Set current card, that is displayed.
     *
     * @param {CardData} cardData
     *
     * @todo Extend for other card types.
     */
    static setCurrentCard(cardData) {
      switch (cardData.type) {
        case dataTypes.stage:
          MapService.setSelectedTrackId(cardData.getMapId());
          break;
        default:
          // Stupid linter needs a default case.
      }


    }

    /**
     * Set selected card.
     *
     * @param {CardData} cardData
     *
     * @todo Extend for other card types.
     */
    static setSelectedCard(cardData) {
      cardData.selected = true;

      this.selectedCard = cardData;

      switch (cardData.type) {
        case dataTypes.stage:
          MapService.setCurrentTrackId(cardData);
          break;

        default:
          this.logger().warn("Uhandled selected card type", cardData);

      }
    }

    /**
     * Clean all selected items.
     */
    static cleanSelection() {
        this.setSelectedCity();
        this.setSelectedHotel();
        MapService.cleanSelectedFeatures();
    }

    /**
     * Clean Hotel list, reset bounds.
     */
    static cleanHotelList() {
      this.hotelList.clean();
      this.mapBounds = null;
    }

     /**
     * Clean Hotel list, reset bounds.
     */
    static cleanHotelMarkers(keep_selected) {
        if (!keep_selected && this.hotelList.hasSelection()) {
          this.hotelList.getSelectedPlace().setSelected(false);
        }
        const keep = keep_selected ? this.hotelList.getSelectedPlace() : null;
        this.hotelList.getDataItems().forEach(item => {
          if (!keep || item.id !== keep.id ) {
            item.removeMarker();
          }
        })
    }
}

export default MapData;
