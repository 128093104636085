import { Component } from 'react';
import './Menu.scss';
import {CssBaseline} from "@material-ui/core";
import i18n from "../i18n";

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CancelIcon from "@material-ui/icons/Cancel";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import SiteAuth from "../common/SiteAuth";
import Typography from '@material-ui/core/Typography';
import Config from "../common/Config";
import AppService from "../common/AppService";
import GronzePageService from "../common/GronzePageService";
import About from "./pages/About";
import DataService from "../common/DataService";
import {getExternalPages} from "../common/Constants";
import ExternalPage from "./pages/ExternalPage";
import {InfoOutlined} from "@material-ui/icons";
import {BuildOutlined} from '@material-ui/icons';
import log from "loglevel";
import UrlService from "../common/UrlService";
import AnalyticsAPIService from "../common/AnalyticsAPIService";

class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            userPicture: '',
            userId: '',
            showUser: AppService.showUserAccount(),
            showLogout: SiteAuth.canLogout(),
        };
        this.initComponent();

        this.close = this.close.bind(this);
        this.closeSession = this.closeSession.bind(this);
    }

    async initComponent() {
        if (!this.state.showUser) {
            return;
        }
        const userData = await SiteAuth.getUserData();
        this.setState ({
            userName: userData.getName(),
            userPicture: userData.getPictureUrl(),
            userId: userData.getUserId(),
        });
    }

    close() {
        AppService.appcomponent.closeMenu(false);
    }

    async openExternalHTML(pageName, label) {
        const pageId = getExternalPages()[pageName];
        const pageHtml = await DataService.getPageById(pageId, label);
        GronzePageService.componentOpenHandler(<ExternalPage pageHtml={pageHtml.contentHtml} title={pageHtml.title}/>);
    }

    openGronzePage() {
        GronzePageService.componentOpenHandler(<About />);
    }

    closeSession() {
        if (!this.state.showLogout) {
            return;
        }
        this.props.unautorize();
    }

    openSlider() {
        AppService.appcomponent.toggleInitialCard(true);
    }

    openSupportForm() {
        const url = Config.getSiteUrl() + '/formulario/gronze-maps';
        const params = new URLSearchParams();
        params.set('version', AppService.getCurrentVersion());
        params.set('status', AppService.getDebugInfo());
        params.set('device', AppService.deviceInfo.model);
        params.set('os', AppService.deviceInfo.platform);
        params.set('operatingSystem', AppService.deviceInfo.operatingSystem);
        params.set('osVersion', AppService.deviceInfo.osVersion);
        params.set('manufacturer', AppService.deviceInfo.manufacturer);
        log.debug('Menu.formulario/gronze-maps', url);
        UrlService.openInfo(url + '?' + params.toString());
        AnalyticsAPIService.sendOutboundLink(
            i18n.t('menu.go_support'),
            url
        );
    }

    render() {
        // <Avatar src="/assets/logo-gronze.svg" alt="Logo Gronze.com" variant="square" />

        return (
            <div className="menu">
                <CssBaseline />
                <Typography className="title" variant="h4" component="h2">
                    {Config.getAppName()}
                </Typography>
                <List>
                    {
                        this.state.showUser?
                            <div><ListItem>
                                <ListItemAvatar>
                                    <Avatar alt={'Foto de ' + this.state.userName} src={this.state.userPicture} />
                                </ListItemAvatar>
                                <ListItemText primary={ this.state.userName } />
                            </ListItem>
                            <Divider /></div>
                        :
                        null
                    }

                    <ListItem button component="a"
                              onClick={() => {
                                  this.openGronzePage();
                              }}
                    >
                            <ListItemIcon><InfoOutlined /></ListItemIcon>
                            <ListItemText primary={ i18n.t('help.about_link') } />
                    </ListItem>

                    <ListItem button component="a"
                              onClick={() => {
                                  this.openSlider();
                              }}
                    >
                            <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                            <ListItemText primary={ i18n.t('help.help_link') } />
                    </ListItem>

                    <ListItem button
                              onClick={() => {this.openExternalHTML(
                                  'legal_usage',
                                  i18n.t('legalConditions.link_usage'),
                              )}}
                    >
                            <ListItemIcon><NavigateNextIcon /></ListItemIcon>
                            <ListItemText primary={ i18n.t('legalConditions.link_usage') } />
                    </ListItem>
                    <ListItem button
                              onClick={() => {this.openExternalHTML(
                                  'legal_privacy',
                                  i18n.t('legalConditions.link_private'),
                              )}}
                    >
                            <ListItemIcon><NavigateNextIcon /></ListItemIcon>
                            <ListItemText primary={ i18n.t('legalConditions.link_private') } />
                    </ListItem>

                    {
                        AppService.isPwa() ?
                            <ListItem button
                                      onClick={() => {
                                          this.openExternalHTML(
                                              'legal_cookies',
                                              i18n.t('legalConditions.link_cookies'),
                                          )
                                      }}
                            >
                                <ListItemIcon><NavigateNextIcon/></ListItemIcon>
                                <ListItemText primary={i18n.t('legalConditions.link_cookies')}/>
                            </ListItem>
                            :
                            null
                    }

                    <Divider />

                    <ListItem button component="a"
                              onClick={() => {this.openSupportForm()}}
                    >
                        <ListItemIcon><BuildOutlined /></ListItemIcon>
                        <ListItemText primary={ i18n.t('menu.go_support') } />
                    </ListItem>

                    {
                        this.state.showLogout ?
                            <ListItem button onClick={this.closeSession} >
                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                <ListItemText primary={ i18n.t('menu.close_session') } />
                            </ListItem>
                            :
                            null
                    }

                    <ListItem >
                        <ListItemText className="brand" secondary={ AppService.getAppInfoLabel() } />
                    </ListItem>
                </List>

                <div className="cancel-icon">
                    <CancelIcon  variant="rounded" color="action" onClick={this.close}/>
                </div>
            </div>
        );
    }
}

export default Menu;

