import BaseService from "./BaseService";
import AppService from "./AppService";
import log from "loglevel";
import MapService from "./MapService";

import { Network } from '@capacitor/network';
import LogManager from "./LogManager";
import UrlService from "./UrlService";

/**
 * Helper for App network management.
 */
class NetworkService extends BaseService {
    static offline = false;

    static init() {
        this.debug("NetworkService.init()");

        if (AppService.isPwa()) {
            this.initPWA();
        } else  {
            this.initStandAlone();
        }
    }

    /**
     * Detects if the device has netowrk
     *
     */
    static isOffline() {
        return this.offline;
    }

    static initPWA() {
        // control net status
        window.addEventListener("offline", () => {
            this.setOffline(true);
        }, false);
        window.addEventListener("online", () => {
            this.setOffline(false);
        }, false);
    }

    static async initStandAlone() {
        Network.addListener('networkStatusChange', status => {
            this.setOffline(!status.connected);
        });
        const status = await Network.getStatus();
        this.setOffline(!status.connected);
    }

    static setOffline(value) {
        if (!value) {
            log.info('We have network connection');
            if (this.offline === true) {
                if (!MapService.mapLoaded) {
                    setTimeout(() => {
                        UrlService.reloadWindow("OfflineContent.constructor, no mapLoaded.");
                    }, 100);
                }
                LogManager.closeLog();
            }
        } else {
            log.info('No network connection');
            if (this.offline !== true) {
                LogManager.createError('No network connection', true);
            }
        }
        this.offline = value;
    }

}

export default NetworkService;
