import axios from "axios";
import LogManager from "./LogManager";
import BaseService from "./BaseService";
import Oauth2Service from "./Oauth2Service";
import Config from "./Config";
import NetworkService from "./NetworkService";
import AppService from "./AppService";

/**
 * Handles low level -authenticated- connections with backend server.
 *
 * Used by:
 * - RestService
 * - OauthService
 *
 * @todo Keep only logging here. Move user messages up.
 */
class Request extends BaseService {
    // Create a client whose requests will be signed
    // static client = axios.create();
    static client;
    static statusCallback;

    /**
     * Init client and init OAuth but only if we've already got an access token.
     *
     * @param object oauth_config
     *   Optional oauth config.
     */
    static init() {

      // Create a new client.
      const config = {
        // `baseURL` will be prepended to `url` unless `url` is absolute.
        baseURL: Config.getRestUrl(),
        // `withCredentials` indicates whether or not cross-site Access-Control requests
        // should be made using credentials
        withCredentials: false, // default
      }

      this.logger().debug('Request init', config);

      this.client = axios.create(config);
      this.statusCallback = AppService.appcomponent.statusRequestCallback;
    }

    /**
     * Gets Axios client, create if not there.
     *
     * @returns AxiosInstance
     */
    static getClient() {
      if (! this.client) {
        this.init();
      }
      return this.client;
    }

    /**
     * Create request with params
     *
     * @param {string} url, absolute or relative
     * @param {string} method, GET or POST
     * @param {object} data, object with the params used in the request
     * @param {boolean} withAuth, if request needs Authentication header or not
     * @param {boolean} retry, Internal param, whether to retry request if auth fails.
     *
     * @return {Promise} returns result of request
     */
    static async sendRequest(url, method, data, withAuth = true, retry = true) {
      this.debug("Request.sendRequest: " + url, data);

      if (NetworkService.isOffline()) {
          this.logger('Request blocked due to network offline');
      }

      if (this.statusCallback) {
          this.statusCallback(true);
      }

      // @todo Need this? Or for post only?
      const axiosConfig = {
          headers: {
              'Content-Type': 'text/plain',
          },
      };

      if (withAuth) {
        // @todo Change to await / sync, check refresh token time, save a request.
        const authData = await Oauth2Service.getAuthorization();
        if (authData) {
            axiosConfig.headers.Authorization = authData;
        } else {
            return Promise.reject('We need authorization data to send this request')
        }
      }


      let result;
      if (method === 'POST') {
        const body = JSON.stringify(data);
        result = this.getClient().post(url, body, axiosConfig);
      } else {
        result = this.getClient().get(url, axiosConfig);
      }

      return result
        .then((response) => {
            if (this.statusCallback) {
                this.statusCallback(false);
            }
          return response.data;
        })
        .catch(async (error) => {
            if (this.statusCallback) {
                this.statusCallback(false);
            }

           if (error.response && withAuth && retry && error.response.status < 500) {
             // If it is an authentication error (400 - 500), we may handle it.
             const canAuth = await Oauth2Service.processAuthErrorResponse(error.response);
             if (canAuth) {
               // Send new request but with retry = false.
               return this.sendRequest(url, method, data, withAuth, false);
             }
           }

           // Fallback, default error handling.
           return Promise.reject(LogManager.processRequestError(error));
        })
    }

    /**
     * Get request, some error responses are handled internally.
     */
    static async get(url, withAuth) {
        return this.sendRequest(url, 'GET', null, withAuth);
    }

    /**
     * Post request, some error responses are handled internally.
     */
    static async post(url, data, withAuth) {
        return this.sendRequest(url, 'POST', data, withAuth);
    }
}

export default Request;
