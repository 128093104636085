/**
 * Object to store map location data and zoom.
 */
class MapPosition {
    /**
     * @var {LngLat}
     */
    center;
    // @var {int}
    zoom;
    /**
     * @var {LngLatBounds}
     */
    bounds;
    // @var {int}
    bearing
    
    constructor(center, zoom, bounds, bearing = 0) {
      this.center = center;
      this.zoom = zoom;
      this.bounds = bounds;
      this.bearing = bearing;
    }
    
    /**
     * Build from Mapbox Map
     * @param {Map} map
     * @return {MapPosition}
     */
    static fromMap(map) {
        return new MapPosition(map.getCenter(), map.getZoom(), map.getBounds(), map.getBearing());
    }
    
    /**
     * Convert to list (for destructuring).
     *
     * Usage: [lon, lat, zoom] = this.toList();
     */
    toList() {
      return [this.center.lng, this.center.lat, this.zoom];
    }
}

export default MapPosition;
