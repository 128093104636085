import BaseStorage from "./BaseStorage";
import AppService from "../common/AppService";

/**
 * Storage service for user data.
 *
 * Use localStorage or sessionStorage depending on status.
 *
 * Implements most Storage methods plus some utility functions.
 */
class AuthStorage extends BaseStorage {
  
    // All names like 'user.xxx'
    static keyPrefix = 'auth';
  
    /**
     * Gets storage object.
     *
     * @return {Storage}
     */
    static storage() {
      if (AppService.isInstalled()) {
        return window.localStorage;
      }
      else {
        return window.sessionStorage;
      }
    }

}

export default AuthStorage;
