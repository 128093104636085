import ObjectData from './ObjectData';

/**
 * Object to store Page data.
 */
class PageData extends ObjectData {   
    // Common node properties.
    title;
    language;
    created;
    changed;
    
    // Main content HMTL 
    contentHtml;

    // Full HTML from 'services' view mode.
    extraHtml;

    /**
     * Class constructor.
     * 
     * @param {string} type
     * @param {object} properties
     * @param {object} data
     */
    constructor(id, properties, data) {
      super('page', id, properties, data)

      if (data) {
        this.id = parseInt(id)
        this.title = data.title;
        this.contentHtml = data.body_html;       
        this.extraHtml = data.extra_html ? data.extra_html : '';
      }
    }

    /**
     * Get the user name stored on local storage.
     *
     * @return {string}
     */
    getTitle() {
      return this.title;
    }
    
    /**
     * Gets the page content as HTML.
     *
     * @return {string}
     */
    getContent() {
      return this.contentHtml;
    }    

    /**
     * Get the user picture from user data
     *
     * @return {string}
     */
    getPageUrl() {
      return this.url;
    }
    
        /**
     * Gets site pages, each having:
     * - id: Drupal nid
     * - title: Default title
     * 
     * @return {object}
     *   Indexed by page name.
     */
    static getSitePages() {
      return {
        legal_usage: {id: 1424, title: 'Terms of Use'},
        legal_privacy: {id: 16620, title: 'Privacy Policy'},
        legal_cookies: {id: 78787, title: 'Cookie Policy'},
      }
    }
}

export default PageData;
