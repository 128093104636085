import FeatureData from './FeatureData';


/**
 * Object to store feature/track data.
 *
 * Data type:
 * - 'route'
 * - 'stage'
 */
class TrackData extends FeatureData {
    // @var {string} Id track
    track_id;    
    // @var {string} Id route
    route_id;
    // @var {boolean} Is selected
    selected = false;
    
    /**
     * Set selected state.
     * 
     * @param {boolean} selected
     *
     * @return {boolean}
     *   True if marker is there and can be set.
     */
    setSelected(selected = true) {
      this.selected = selected;
    }

}

export default TrackData;
