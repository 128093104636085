/**
 * Satellite control button.
 *
 * Implements IControl:
 * https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
 */
import MapService from "../common/MapService";

class SatelliteControl {
    _container = document.createElement('div');
    _button = document.createElement('button');
    CLASS_STANDARD = 'standard';
    CLASS_SAT = 'sat';
    CLASS_BUTTON_SAT = 'button_sat';
    CLASS_BUTTON_CONTROL = 'mapboxgl-ctrl';
    mapComponent;
    visible = false;

    handleButtonSat() {
        const satButton = document.querySelector('.' + this.CLASS_BUTTON_SAT);
        this.visible = !this.visible;
        MapService.setSatelliteVisibility(this.visible);
        satButton.classList.toggle(this.CLASS_STANDARD);
        satButton.classList.toggle(this.CLASS_SAT);
    }

    onAdd(map) {
        this._map = map;
        this._container.className = this.CLASS_BUTTON_CONTROL + ' mapboxgl-ctrl-group';
        this.mapStyle = this.CLASS_STANDARD;
        this._button.className = `${this.CLASS_BUTTON_SAT} ${this.CLASS_SAT}`;
        this._button.addEventListener('click', this.handleButtonSat.bind(this));
        this._container.appendChild(this._button);
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

export default SatelliteControl;
