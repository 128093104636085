import {Component} from "react";
import styles from './Pages.module.scss';
import ExternalHTMLContent from "../ExternalHTMLContent";
import {Box, Typography} from "@material-ui/core";

class ExternalPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className={styles.page + ' page'}>
            <div className={styles.header + ' header'}>
                <Typography variant="h1" component="h1">
                    { this.props.title }
                </Typography>
            </div>
            <Box p={3}>
                <ExternalHTMLContent contentHtml={this.props.pageHtml} />
            </Box>
        </div>
    }
}

export default ExternalPage;
