
function GronzeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <defs>
        <path id="Layer1_0_FILL" fillOpacity="0" d="M209 117V0H0v117z"></path>
        <path
          id="Layer0_0_MEMBER_0_FILL"
          fill="#5e3510"
          d="M202.45-.05H6.35Q0-.05 0 6.25V110.8q0 6.3 6.35 6.3h196.1q6.6 0 6.6-6.3V6.25q0-6.3-6.6-6.3z"
        ></path>
        <path
          id="Layer0_0_MEMBER_1_FILL"
          fill="#739948"
          d="M88.2 88q12.25-12.25 12.25-29.6 0-17.4-12.25-29.65-12.3-12.3-29.7-12.3-17.35 0-29.6 12.3Q16.6 41 16.6 58.4q0 17.35 12.3 29.6 12.25 12.3 29.6 12.3 17.4 0 29.7-12.3z"
        ></path>
        <path
          id="Layer0_0_MEMBER_2_FILL"
          fill="#fff"
          d="M58.2 61.6l2.05 22.3q2.2 23 3.05 26.8.8 3.75 1.5 4.9l7.9-1.8q.1-2.35-.75-6.1-.8-3.7-13.4-45.05-.15-.5-.35-1.05z"
        ></path>
        <path
          id="Layer0_0_MEMBER_2_1_STROKES"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M58.2 61.6l2.05 22.3q2.2 23 3.05 26.8.8 3.75 1.5 4.9l7.9-1.8q.1-2.35-.75-6.1-.8-3.7-13.4-45.05-.15-.5-.35-1.05z"
        ></path>
      </defs>
      <use
        width="100%"
        height="100%"
        transform="translate(-.365 2.592)"
        xlinkHref="#Layer1_0_FILL"
      ></use>
      <use
        width="100%"
        height="100%"
        transform="matrix(.11481 0 0 .20487 0 .01)"
        xlinkHref="#Layer0_0_MEMBER_0_FILL"
      ></use>
      <use
        width="100%"
        height="100%"
        transform="matrix(.20033 0 0 .20051 .358 .139)"
        xlinkHref="#Layer0_0_MEMBER_1_FILL"
      ></use>
      <g transform="matrix(.20033 0 0 .20051 .358 .139)">
        <use
          width="100%"
          height="100%"
          xlinkHref="#Layer0_0_MEMBER_2_FILL"
        ></use>
        <use
          width="100%"
          height="100%"
          xlinkHref="#Layer0_0_MEMBER_2_1_STROKES"
        ></use>
      </g>
    </svg>
  );
}

export default GronzeIcon;
