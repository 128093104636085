/**
 * Helper timer to handle refreshing.
 */
class RefreshTimer  {    
    /**
     * Unique name for storage.
     */
    name;
    /**
     * Last refresh timestamp, milliseconds.
     */
    refreshLast = 0;
    
    /**
     * Time to next refresh, seconds.
     */
    refreshTime = 0;
        
    /**
     * Build refresh timer.
     *
     * @var {string} name
     */
    constructor(name = 'refresh_timer', refreshTime = 0, refreshLast = 0) {
      this.name = name;
      this.refreshTime = refreshTime;
      this.refreshLast = refreshLast;
    } 
    
    /**
     * Check refresh time, for services that need refreshing.
     *
     * @return {boolean}
     *   True if it needs refreshing.
     */
    needsRefresh() {
      return this.refreshTime && Date.now() - this.refreshLast >  1000 * this.refreshTime;
    }
    
    /**
     * Sets last refresh time.
     */
    setRefreshLast() {
      this.refreshLast = Date.now();
      return this;
    }
    
    /**
     * Sets time to next refresh, seconds.
     *
     * @param {int} sTime
     */
    setRefreshTime(sTime) {
      this.refreshTime = sTime;
      return this;
    }     
    
    /**
     * Sets last refresh and time interval for next refresh.
     *
     * @param {int} sTime
     */
    setRefreshNext(sTime) {
      this.setRefreshLast();
      this.setRefreshTime(sTime);
      return this;
    }    
    
    /**
     * Format last refreshed time.
     */
    formatLast() {
      return this.lastChecked ? (new Date(this.lastChecked)).toLocaleString() : '';
    }
    
    /**
     * Save to storage.
     * @param {Storage}
     */
    saveTo(storage) {
      storage.setObject(this.name, this);
      return this;
    }
    
    /**
     * Save to storage.
     * @param {Storage}
     */
    loadFrom(storage) {
      const timer = storage.getObject(this.name);
      if (timer) {
        this.refreshTime = parseInt(timer.refreshTime);
        this.refreshLast = parseInt(timer.refreshLast);
      }      
    }    
}

export default RefreshTimer;
