import { LngLatBounds} from 'mapbox-gl';
import GeoLocation from './GeoLocation';

/**
 * Stores geographical bounds data, wrapping MapBox's LngLatBounds
 *
 * @see https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatbounds
 */
class GeoBounds extends LngLatBounds {
    // Properties
    // (LngLatLike?) sw
    // (LngLatLike?) ne
    
    /**
     * Construct from Mapbox LngLat
     * @param {LngLatBoundsLike} bounds
     */
    static fromLngLatBounds(bounds) {
      return new GeoBounds(bounds.sw, bounds.ne);
    }
    
    /**
     * From raw object data.
     * with 'top', 'right', 'left', 'bottom'
     *
     * @return {GeoBounds}     
     */
    static fromGeoData(data) {
      if (data.left && data.bottom && data.right && data.top) {
          const sw = new GeoLocation(data.left, data.bottom);
          const ne = new GeoLocation(data.right, data.top);
          return new GeoBounds(sw, ne);
      }
    }
     
    /**
     * Check whether current bounds contain new ones.
     *
     * @param {LngLatBounds} bounds
     */
    containsBounds(bounds) {
      return this.contains(bounds.getSouthWest())
        && this.contains(bounds.getNorthEast())
        && this.contains(bounds.getNorthWest())
        && this.contains(bounds.getSouthEast());
    }    
        
    /**
     * Convert to query string for REST request.
     */
    toQueryString() {
        const geoLatMin = this._sw.lat;
        const geoLatMax = this._ne.lat;
        const geoLonMin = this._sw.lng;
        const geoLonMax = this._ne.lng;
        
        let urlData = '';
        urlData += 'geo_lat[min]=' + geoLatMin;
        urlData += '&geo_lat[max]=' + geoLatMax;
        urlData += '&geo_lon[min]=' + geoLonMin;
        urlData += '&geo_lon[max]=' + geoLonMax;
        return urlData; 
    }
}

export default GeoBounds;
