import BaseList from './BaseList';
import RouteData from './RouteData';

/**
 * Wrapper to manage lists of hotels. 
 */
class RouteList extends BaseList {
   
    // Key field
    keyField = 'route_id';
        
    /**
     * Build from list items from rest response.
     */
    static fromListItems(listItems) {
        const dataItems = [];
        
        listItems.forEach((item) => {
              dataItems.push(RouteData.fromListItem(item));
        });
        
        return new RouteList(dataItems, 'route_id');
    }  

}

export default RouteList;
