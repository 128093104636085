import log from 'loglevel';
import {geoPermStatus} from "./Constants";
import AppService from "./AppService";
import { Geolocation } from '@capacitor/geolocation';

class PermissionsService {

    static geoPermissionGranted;
    static geoPermissionStatus;

    /**
     * Handler callback
     */
    static changePermissionHandler;

    /**
     * Change permission status handler
     *
     * Parameters:
     * - value: permission status. One of geoPermStatus
     */
    static setState(value) {
        log.debug('PermissionsService.setState', value);
        PermissionsService.geoPermissionGranted = value === geoPermStatus.granted;
        PermissionsService.geoPermissionStatus = value;

        // Handler callback
        if (this.changePermissionHandler) {
          this.changePermissionHandler(value);
        }
        // force show permissions prompt (not always works
        if (value === geoPermStatus.prompt) {
            PermissionsService.requestGeoPermission();
        }
    }

    /**
     * Create change permissions handler and return i itial state
     *
     * Parameters:
     * - changeHandler: function fired on the component to change state
     */
    static async initGeoPermissionStatus(changeHandler) {
      this.changePermissionHandler = changeHandler;
      if (AppService.isIos() && AppService.isStandAlone()) {
          log.info('IOs app request permissions at start');
          return new Promise((resolve) => {
              Geolocation.requestPermissions()
                  .then((result) => {
                      PermissionsService.setState(result.location);
                      resolve(result.location === geoPermStatus.granted);
                  })
                  .catch(() => {
                      log.warn("Error getting geolocation permissions");
                      resolve(false);
                  })

          });
      } else if ('permissions' in window.navigator) {
        return new Promise((resolve) => {
            // gets permission state
            navigator.permissions.query({name:'geolocation'}).then(function(result) {
                // change permission state handler
                result.onchange = () => {
                    PermissionsService.setState(result.state);
                    // fires function on component
                    // changeHandler(result.state);
                }
                PermissionsService.setState(result.state);
                resolve(result.state === geoPermStatus.granted);
            });
        });
      } else if('geolocation' in navigator) {
        // This will trigger a prompt if not enabled.
        navigator.geolocation.getCurrentPosition(function () {
          log.info("PermissionService: Position available");
          PermissionsService.setState(geoPermStatus.granted);
          return true;
        }, function(error) {
          log.info("PermissionService: No position available", error);
          PermissionsService.setState(geoPermStatus.denied);
          return false;
        });
      } else {
        // No luck
        log.warn("Cannot use geolocation nor check permissions.");
        return false;
      }

    }

    /**
     * Force permissions prompt
     *
     */
    static async requestGeoPermission() {
        navigator.geolocation.getCurrentPosition(
            () => {

            },
            () => {

            }
        );
    }
}

export default PermissionsService;
