import { Component } from 'react';
import './Servicio.scss';

class Servicio extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        let content;
        if (this.props.data.name && this.props.data.name !== '') {
            content = <span dangerouslySetInnerHTML={{ __html: this.props.data.icon_html }} title={this.props.data.name}></span>;
        }
        return (
            <div className='servicio'>
                {content}
            </div>
        );
    }
}

export default Servicio;
