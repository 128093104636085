import Config from "./Config";
import BaseService from './BaseService';
import AppStorage from "../storage/AppStorage";
import RestService from "./RestService";
import RefreshTimer from "../data/RefreshTimer";
import semver from "semver/preload";

/**
 * Helper for App updates, specialy for PWA.
 */
class UpdateService extends BaseService {
   /**
    * Define storage class
    */
    static storage = AppStorage;

    /**
     * @var {RefreshTimer}
     */
    static refreshTimer = new RefreshTimer();

    /**
     * Gets debug information.
     */
    static getDebugInfo() {
      const lastChecked = this.refreshTimer.formatLast();
      return lastChecked ? "Last check " + lastChecked : '';
    }

    /**
     * Check current App version vs installed one.
     *
     * @return {boolean} true if not set or it matches.
     */
    static checkRunningAppVersion() {
      const runningVersion = Config.getAppVersion();
      const installedVersion = this.storage.getInstalledVersion();

      if (!installedVersion) {
        this.info('Setting installed version', runningVersion);
        this.storage.setInstalledVersion(runningVersion);
        return true;
      }
      else if (installedVersion === runningVersion) {
        this.debug("App version, ok", runningVersion);
        return true;
      } else {
        this.debug("Setting new App version", runningVersion);
        this.storage.setInstalledVersion(runningVersion);
        return false;
      }
    }

    /**
     * Check App version against remotely reported one.
     *
     * @param {string} current, Version to check against (m.n.p).
     *
     * @return {object}|null Update information.New version if it needs to update.
     */
    static async checkServerAppVersion(current) {
      const updates = await RestService.getAppUpdates();
      if (updates) {
        // True if current version >= reported version.
        return semver.gt(updates.app_version, current);
      }
    }

    /**
     * Check for app updates.
     */
    static async checkUpdates() {
        this.refreshTimer.setRefreshLast();
        if (this.checkRunningAppVersion()) {
            const installed = this.storage.getInstalledVersion();
            this.checkServerAppVersion(installed).then((updates) => {
                updates && this.forceUpdate(updates);
            });
        }
    }

    /**
     * Check whether it is time to check updates.
     *
     * @return {boolean}
     */
    static shouldCheckUpdates() {
      return this.refreshTimer.needsRefresh() && this.isOnline();
    }

    /**
     * Force app update to new version.
     *
     * @param {object} Update information with:
     *   -app_version
     *
     *   If the parameter is empty, just force clean reload.
     */
    static forceUpdate(updates) {
      if (updates) {
         window.alert("Gronze Maps Update, version " + updates.app_version);
      }
      else {
         window.alert("Reloading Gronze Maps");
      }
      this.forceCacheClean()
          .then(this.forceSwUnregister())
          .finally(window.location.reload());
    }

    /**
     * Force service worker cache clean.
     */
    static async forceCacheClean() {
        caches.keys()
          .then((cacheNames) => {
            // Step through each cache name and delete it
            return Promise.all(
              cacheNames.map((cacheName) => caches.delete(cacheName))
            );
          });
    }

    /**
     * Unregister all service workers.
     */
    static async forceSwUnregister() {
      if(window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
          for(const registration of registrations) {
            registration.unregister();
          }
        });
      }
    }

    /**
     * Initialize, from AppService.
     */
    static init() {
       // Initialize refresh timer.
       this.refreshTimer.setRefreshTime(Config.getRefreshTime());

       if (!this.checkRunningAppVersion()) {
           this.info("App needs update, new version", Config.getAppVersion());
           // Do not reload here, sw + index.js will take care.
           // window.location.reload(true);
       }
    }
}

export default UpdateService;
