import { Component } from 'react';
import './InitSessionScreen.scss';

import i18n from "../i18n";
import { Trans } from 'react-i18next';
import {Box, CssBaseline, Typography, Button, Divider, Link} from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import Config from "../common/Config";
import SiteAuth from "../common/SiteAuth";
import AppService from "../common/AppService";
import LegalConditionsBox from './LegalConditionsBox';
import Login from "./Login";

class InitSessionScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // detect if app act as PWA
            asPWA: AppService.isPwa(),
            // detect app not installed
            isInstalled: AppService.isInstalled(),
            // Whether we can close the window
            // which is not working mostly..
            canClose: false,
            // Test Warning
            showTestWarning: Config.config().app_test_warning,
            showDebug: !Config.isProduction(),
            showRegister: SiteAuth.canRegister()
        };
    }

    reload() {
        // clean data and reload
        SiteAuth.restartAuthentication();
    }

    register() {
        // Start authentication w/ Registration.
        // SiteAuth.restartAuthentication();
        SiteAuth.resetAuthentication();
        SiteAuth.initAuthentication(true);
    }

    close() {
        window.close();
    }

    render() {
        const siteLinks = Config.getSiteLinks();
        // const appIcon = <SvgIcon component={MapsIcon} fontSize="large" />;

        return (
            <div id="initSessionCard">
                <CssBaseline />
                <Typography  variant="h4" component="h1" gutterBottom  color="secondary" className="title">
                    { Config.getAppName() }<small> { Config.getAppTag() }</small>
                </Typography>

                <div className="content">
                    <Typography  variant="h5" component="h2" gutterBottom color="primary">
                        { i18n.t('initSession.title') }
                    </Typography>

                    <div className="gronzeInfo">

                        { this.state.showTestWarning &&
                        <Alert severity="info">
                            <Trans i18nKey="initSessionScreen.warning">Test Warning</Trans>
                        </Alert>
                        }
                       <Box textAlign="left" fontWeight="fontWeightRegular" >
                         <Typography variant="body1" component="div">
                            <Trans i18nKey="help.requirements"><p>Requirements</p></Trans>
                         </Typography>
                       </Box>
                    </div>
                    <br/>
                    {
                        this.state.asPWA ?
                            null
                            :
                            <Box textAlign="left" fontWeåight="fontWeightRegular" >
                                <strong>
                                    <Trans i18nKey="initSession.login_form_message">Introduce tu usuario y contraseña</Trans>
                                </strong>
                                <Login />
                            </Box>
                    }
                    <Box textAlign="left" fontWeight="fontWeightRegular">
                        <strong><Trans i18nKey="help.init_session"><p>Inicia sesión en Gronze.com</p></Trans> </strong>
                    </Box>
                    <Box textAlign="center" fontWeight="fontWeightRegular">
                        <Button variant="contained"  color="primary" className="" onClick={this.reload}>{ i18n.t('initSession.init') }</Button>
                    </Box>
                    {
                        this.state.showRegister ?
                            <div><Box textAlign="left" fontWeight="fontWeightRegular">
                                <strong>
                                    {i18n.t('help.register')}
                                </strong>
                            </Box>
                                <Box textAlign="center" fontWeight="fontWeightRegular">
                                    <Button variant="contained"  color="primary" className="" onClick={this.register}>{ i18n.t('initSession.register') }</Button>
                                </Box></div>
                            :
                            null
                    }
                    {
                        this.state.canClose &&
                        <Box textAlign="center" fontWeight="fontWeightRegular">
                            <Button color="secondary" className="big-button" onClick={this.close}>Cerrar</Button>
                        </Box>
                    }

                    <Box textAlign="left" fontWeight="fontWeightRegular">
                        <Trans i18nKey="help.pwa"><p>Explain PWA</p></Trans>
                    </Box>
                    <br/>

                    {
                        !this.state.isInstalled &&
                        <Box textAlign="left" fontWeight="fontWeightRegular">
                            <p>
                                {i18n.t('help.install_before')}
                                <Link href={ siteLinks.help } target="_blank">{i18n.t('help.install_link')}</Link>
                            </p>
                        </Box>
                    }

                    <div className="footer">
                        <Divider style={{marginTop: '10px'}} />
                        <LegalConditionsBox />
                        <Divider style={{marginBottom: '10px'}} />
                        <Typography variant="body2" component="p" align="center" >
                            { AppService.getAppInfoLabel() }
                        </Typography>
                        { this.state.showDebug &&
                            <Typography variant="body2" component="p" align="center" >
                                <small>
                                    { AppService.getDebugInfo() }
                                </small>
                            </Typography>
                        }
                    </div>
                </div>
            </div>
        );
    }

}

export default InitSessionScreen;
