import { dataTypes, dataClass } from '../common/Constants';
import PlaceData from './PlaceData';
import GeoLocation from './GeoLocation';
import MapData from "../common/MapData";
import GeoService from "../common/GeoService";
/**
 * Object to store hotel data from rest list.
 *
 * Example list item.
 * {
    "id":"3352",
    "uri":"https://www.gronze.com.ddev.local/maps20/hotel/3352",
    "url":"https://www.gronze.com.ddev.local/castilla-y-leon/leon/arcahueja/hotel-camino-real",
    "geo_location":{"geom":"POINT (-5.502959489822 42.56641679304)","geo_type":"point","lat":"42.566416793040","lon":"-5.502959489822","left":"-5.502959489822","top":"42.566416793040","right":"-5.502959489822","bottom":"42.566416793040","geohash":"ezs44ubz7g0n8nb5"},
    "icon":"gronze-icon-alojamiento",
    "hotel_type":"Hotel",
    "title":"Hotel Camino Real",
    "type":"alojamiento"},
   }
*/

class HotelData extends PlaceData {
    
    /**
     * Returns hotel[id]
     */
    getMapItemId() {
      return 'hotel' + this.id;
    }

    /**
     * Create from data type, id
     */
    static fromListItem(item) {
      const data = new HotelData(dataTypes.hotel, item.id);
      data.restUrl = item.uri;
      data.linkUrl = item.url;
      // @todo Transition from old geo_location (obj) to geo_location_json
      if (item.geo_location_json) {
        data.location = GeoLocation.fromGeoJson(item.geo_location_json);
      }
      else {
        data.location = GeoLocation.fromLatLon(item.geo_location.lat, item.geo_location.lon);
      }
      data.iconClass = item.icon;
      data.name = item.title;
      return data;
    }
    
    /**
     * Create DOM Element
     */
    createElement() {
        const el = super.createElement();
        el.className = dataClass.hotel + ' icon glyphicons ' + this.iconClass;
        if (GeoService.showHotelNames(MapData.zoom)) {
            el.innerHTML= `<div class="accomodation-name">${this.name}</div>`;
        }
        return el;
     }
     
}

export default HotelData;
