import { dataTypes } from '../common/Constants';
import TrackData from './TrackData';

/**
 * Object to store city from map marker.
 *
 * Example marker.
 * {
     id: some map id
     layer: map layer {id = "interactive-lines", type, source, source-layer}
     properties:
     - camino_id: "camino-viejo"
     - name: "Etapa 21 Congosto - Cacabelos"
     - stroke: "#aa0000"
     type: Feature
     _geometry: Point{coordinates [lon, lat]}
   }
*/

class RouteData extends TrackData {
  
    /**
     * Returns hotel[id]
     */
    getMapItemId() {
      return this.route_id;
    }  
    
    /**
     * Create from map feature.
     * @param {Feature} feature
     */
    static fromMapFeature(feature) {
      const properties = feature.properties;
      const data = new RouteData(dataTypes.route);
      data.setFeature(feature);
      
      data.route_id = properties.route_id;
      data.name = properties.name;
      
      return data;
    }
    
    /**
     * Create from data type, id
     * @return {RouteData}
     */
    static fromListItem(item) {
      const data = new RouteData(dataTypes.route, item.id);

      data.route_id = item.key;
      data.restUrl = item.uri;
      data.linkUrl = item.url;
      data.name = item.title ? item.title : item.name;
      
      return data;
    }    
    
}

export default RouteData;
