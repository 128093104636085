import axios from "axios";
import BaseService from "./BaseService";
import AuthStorage from "../storage/AuthStorage";
import LogManager from "./LogManager";
import Config from "./Config";
import UrlService from "./UrlService";
import qs from "querystring";
import RefreshTimer from "../data/RefreshTimer";

class Oauth2Service extends BaseService {

    /**
     * auth types
     */
    static IMPLICIT_REQUEST_TYPE = 'authorization_code';
    static REFRESH_REQUEST_TYPE = 'refresh_token';
    static PASSWORD_REQUEST_TYPE = 'password';
    static CLIENT_CREDENTIALS_REQUEST_TYPE = 'client_credentials';

    /*
     * auth type used in this installation
     */
    static requestType;

    /**
     * Define storage class
    */
    static storage = AuthStorage;

    /**
     * storage oauth2 key
    */
    static STORAGE_OAUTH2_KEY = 'oauth2_tokens';
    static STORAGE_OAUTH2_STATE = 'oauth2_State';

    /**
    * response error message used to detect if we need to refresh token
     */
    static EXPIRED_TOKEN_MESSAGE = 'The access token provided has expired';

    /**
     * @var {RefreshTimer}
     */
    static refreshTimer = new RefreshTimer('oauth_refresh_timer');

    /**
     * Gets consumer data.
     *
     * @return {Object}
     */
    static getConsumerData() {
        const config = this.config();

        if(!config || !config.oauth2_client_id) throw new Error('Missing config: oauth_client_id');

        return  {
            rest_url: config.rest_url,
            oauth2_client_id: config.oauth2_client_id,
            oauth2_scope: config.oauth2_scope,
            redirect_url: UrlService.getCallbackUrl(),
            oauth2_client_secret: config.oauth2_client_secret,
        }
    }

    /**
     * Post request
     * @param {string} url Absolute or relative URL (path)
     * @param {Array} params
     * @param {Object} config Axios config.
     */
    static async post(url, params = {}, config = {}) {
      config.baseURL = Config.getRestUrl();

      // About 'Content-Type':
      // If application/x-www-form-urlencoded, params need to be urlencoded.
      // If application/json, then the request would take an extra CORS preflight.
      /* eslint-disable */
      if (!config.hasOwnProperty('headers')) {
        config.headers = {};
      }
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      // If params are urlencoded...
      // @see https://axios-http.com/docs/urlencoded
      const data = new URLSearchParams(params);

      return axios.post(url, data, config);
   }

    /**
     * Detect if we need refresh token and if needed gets new token and returns result
     *
     * Expired tokens:
     *
     * - status: 401
     * - error: invalid_token
     * - error_description: "The access token provided has expired"
     *
     * - status: 400
     * - error: invalid_grant
     * - error_description: "Refresh token has expired"
     *
     * Invalid token:
     * - status: 400
     * - error: invalid_grant
     * - error_descpription: "refresh_token doesn't exist or is invalid for the client"
     *
     * @param {object} data, response data returned by request
     *
     * @return {Promise<boolean>} Resolves to TRUE if the token is refreshed
     */
    static async processAuthErrorResponse(response) {
        this.debug("Processing Auth Error", response);

        if (response.status === 401 && response.data.error_description === this.EXPIRED_TOKEN_MESSAGE) {
            // Just return true or false, no complex errors.
            return this.requestRefreshToken().then(() => true, () => false);
        } else {
            // Authentication cannot be recovered, the caller will handle it.
            this.reset();
            // @todo No authentication, but we don't want to trigger a page reload..
            // UrlService.redirectBaseUrl();
            return false;
        }
    }

    /**
     * Gets token when login.
     *
     * Possible errors:
     *
     * - status 401
     * - error: 'invalid_grant'
     * - error_description:"Invalid username and password combination"
     *
     * @param {string} requestType, PASSWORD_REQUEST_TYPE, IMPLICIT_REQUEST_TYPE or REFRESH_REQUEST_TYPE
     * @param {string?} username, user name
     * @param {string?} password, user password
     * @param {string?} authcode, used only on implicit flow second step
     *
     *
     * @return {Promise<boolean>} returns result of resending request
     */
    static async requestAccessToken(requestType, username, password, authcode) {
        this.debug("requestAccessToken", {requestType, username, password});
        const url = '/oauth2/token';
        const config = this.getConsumerData();

        this.requestType = requestType;

        const params = {
            grant_type: requestType,
            client_id: config.oauth2_client_id,
        };
        if(requestType === this.PASSWORD_REQUEST_TYPE) {
            params.username = username;
            params.password = password;
        } else if (requestType === this.CLIENT_CREDENTIALS_REQUEST_TYPE) {
            params.client_secret = config.oauth2_client_secret;
        } else {
            params.redirect_uri = config.redirect_url;
            params.code = authcode;
        }

        return this.post(url, params)
          .then((response) => {
             this.setAuth2Data(response.data);
             /*
             if(requestType === this.IMPLICIT_REQUEST_TYPE) {
                 UrlService.redirectBaseUrl();
             }
             */
             return true;
          })
          .catch((error) => {
              if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  this.debug("Auth Error response", error.response);

                  // Handle known errors
                  if (error.response.status === 401 && error.response.data.error === 'invalid_grant') {
                    return Promise.reject({type: 'user', message: error.response.data.error_description, error});
                  }
              }
              // Something happened in setting up the request that triggered an Error
              this.debug('Oauth Error', error);
              return Promise.reject(LogManager.processRequestError(error));

          });
    }

    /**
     * refresh token, resend request and returns result
     *
     * @return {Promise<boolean>} returns result of resending request
     */
    static async requestRefreshToken() {
        if (this.requestType === this.CLIENT_CREDENTIALS_REQUEST_TYPE) {
            return this.requestAccessToken(this.CLIENT_CREDENTIALS_REQUEST_TYPE);
        } else {
            const config = this.getConsumerData();
            const url = '/oauth2/token';
            const oauth = this.getAuth2Data();

            this.debug("Refreshing OAuth token", oauth);

            const params = {
                grant_type: this.REFRESH_REQUEST_TYPE,
                refresh_token: oauth.refresh_token,
                client_id: config.oauth2_client_id,
            }
            if (this.requestType === this.CLIENT_CREDENTIALS_REQUEST_TYPE) {
                params.client_secret = config.oauth2_client_secret;
            }

            return this.post(url, params)
                .then((response) => {
                    this.setAuth2Data(response.data);
                    return true;
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        this.debug("Error response " + error.response.status, error.response);
                        // Full auth reset if invalid grant.
                        if (error.response.status === 400 && error.response.data.error === 'invalid_grant') {
                            this.reset();
                        }
                        return Promise.reject(error.response);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.debug('Oauth Error', error);
                        return Promise.reject({
                            status: 500,
                            message: error.request,
                        });
                    }
                });
        }
    }

    /**
     * Gets / recreates authorization state code.
     *
     * @param {boolean} Create new one or use existing.
     * @return {string} code
     */
    static getAuthorizationCode(create = false) {
      let state;

      if (create) {
        state = Math.random().toString(36).slice(2, 7);
        this.storage.setItem(this.STORAGE_OAUTH2_STATE, state);
      }
      else {
        state = this.storage.getItem(this.STORAGE_OAUTH2_STATE);
      }
      return state;
    }

    /**
     * Gets query string parameters for Authorization request.
     * If user is not registered uses the register url and if is IOS other
     *
     * @param {boolean} with_registration True to get registration URL.
     *
     * @return {string} Full URL with query string parameters.
     */
    static getAuthorizationUrl(with_registration = false) {
      const config = this.getConsumerData();
      const path = with_registration ? '/oauth2/register' : '/oauth2/authorize';

      return Config.getRestUrl() + path + '?' + qs.stringify({
          response_type: 'code',
          client_id: config.oauth2_client_id,
          redirect_uri: config.redirect_url,
          scope: config.oauth2_scope,
          state: this.getAuthorizationCode(true),
      });
    }

    /**
     * gets auth code forcing reload to authorize url
     * this method creates and stores state param to verify the auth response
     */
    static async requestAuthorization(with_registration = false) {
        this.debug("requestAuthorization");

        const uri = this.getAuthorizationUrl(with_registration);

        // redirect the user to the authorize uri
        await UrlService.redirectToUrl(uri);
    }

    /**
     * True if we are on second step implicit oauth2 flow and gets right code and state
     * @todo Remove, not used.
     */
    static checkAuthCode() {
        const url = window.location.href;
        const urlObject = new URL(url);
        this.extractAuthCode(urlObject.searchParams);
    }

    /**
     * Extract auth code from URL params
     *
     * @param {URLSearchParams} params
     *
     * @return Promise{boolean}
     *   True if we are on second step implicit oauth2 flow and gets right code and state
     */
    static async extractAuthCode(urlParams) {
        if (urlParams.has("code") && urlParams.get("state") === this.getAuthorizationCode()) {
          return this.requestAccessToken(this.IMPLICIT_REQUEST_TYPE, null, null, urlParams.get("code"));
        }
        else {
          return false;
        }
    }

    /**
     * Gets stored authorization data.
     *
     * Possible values:
     * - access_token
     * - refresh_token
     * - scope
     * - token_type
     */
    static getAuth2Data() {
        return this.storage.getObject(this.STORAGE_OAUTH2_KEY);
    }

    /**
     * Gets Authorization header.
     *
     * @param {boolean} check_expired Check for expiration, defaults to true
     *
     * @return {Promise<string>}
     *    Authorization header, empty if not available.
     */
    static async getAuthorization(check_expired = true) {
        const oauth2_headers = this.getAuth2Data();

        if (!oauth2_headers) {
          return '';
        } else if (check_expired && this.refreshTimer.needsRefresh()) {
          this.debug("getAuthorization: Token needs refreshing.");
          return this.requestRefreshToken().then(
            () => { return this.getAuthorization(false); },
            () => ''
          )
        }
        else {
          return oauth2_headers ? oauth2_headers.token_type + ' ' + oauth2_headers.access_token :  '';
        }
    }



    /**
     * Set / delete stored authorization data.
     *
     * @param object authData
     *   Data to be stored or null for deleting it
     */
    static setAuth2Data(authData) {
        this.debug("setAuth2Data", authData);
        if (authData) {
            this.storage.setObject(this.STORAGE_OAUTH2_KEY, authData);
            if (authData.expires_in) {
              this.refreshTimer
                .setRefreshNext(authData.expires_in)
                .saveTo(this.storage);
            }
        } else {
            this.storage.removeItem(this.STORAGE_OAUTH2_KEY);
            this.storage.removeItem(this.refreshTimer.name);
        }

    }

    /**
     * Checks whether we've got an access token.
     *
     * @param {boolean} check_expired
     *
     * @return boolean True if we've got an access token.
     */
    static hasAccessToken(check_expired = true) {
        const auth_data = this.getAuth2Data();

        return auth_data && auth_data.access_token && (!check_expired || !this.refreshTimer.needsRefresh());
    }

    /**
     * Reset all authentication.
     */
    static reset() {
        this.setAuth2Data(null);
    }

    /**
     * Initializae, load timer from storage if any...
     */
    static init() {
      this.refreshTimer.loadFrom(this.storage);
      this.refreshTimer.refreshLast && this.debug("Loaded Oauth refresh timer.", this.refreshTimer);
    }
}

export default Oauth2Service;
