import log from 'loglevel';
import Config from "./Config";

/**
 * Base class for services. Provides:
 * - Logging
 * - Configuration 
 */
class BaseService  {
    
    /**
     * Log messages to console.
     *
     * @returns (object) Logger
     */
    static logger() {
        return log.getLogger('BaseService');
    }

    /**
     * Debug facility, quick log to console.
     *
     * @param {string} message
     */
    static debug(message, data = null) {
      this.logger().debug(message, data);
    }
    
    /**
     * Info, for regular logs.
     *
     * @param {string} message
     */
    static info(message, data = null) {
      this.logger().info(message, data);
    }

    /**
     * Configuration data.
     *
     * @returns {object} Configuration
     */
    static config() {
        return Config.config();
    }
    
    /**
     * Checks for network connection.
     */
    static isOnline() {
      return window.navigator.onLine;
    }
}

export default BaseService;