import CardData from './CardData';

/**
 * CardData - City, Hotel
 *
 * Data wrapper for object types:
 * - Accommodation (Hotel, Hostel)
 * - Place (City, Monument)
 */
class HotelCard extends CardData {
    tag_servicios= '';
    geo_location= '';
    bookingUrl = '';
    phoneNumber = '';
    notaDestacada = '';

    constructor(type, properties, data) {
      super(type, properties, data);

      this.title = data.title;
      
      if (data.servicios_albergue) {
          this.tag_servicios = data.servicios_albergue;
      } 
                  
      if (data.contact_phone && (data.contact_phone.length > 0)) {
          this.phoneNumber = data.contact_phone[0].replace(/\s/g, "");
      }                          

      this.geo_location = data.geo_location;
      this.bookingUrl = data.booking_url;
    }
    
    /**
     * Returns hotel[id]
     */
    getMapId() {
      return 'hotel' + this.id;
    }    
    
    /**
     * When closed, message is in 'status_warning'.
     */
    getWarningType() {
      if (this.data && this.data.status_warning) {
        return HotelCard.WARNING_CLOSED;
      }
      else {
        return super.getWarningType();
      }
    }    

}

export default HotelCard;
