import { dataTypeToFeatureType} from '../common/Constants';
import GeoLocation from './GeoLocation';

/**
 * Object to store feature data.
 *
 * Subclasses:
 *
 * - PlaceData
 *   - CityData
 *   - HotelData
 * - TrackData
 *   - RouteData
 *   - StageData
 */
class FeatureData {
    // @var {string} Object / data type.
    type;
    // @var {int}, 0 for missing parameter
    id;
    name;

    // @var {string} Rest URL
    restUrl;
    // @var {string} Link URL
    linkUrl;

    // @var {object} Map Feature
    mapFeature;

    /**
     * @var {GeoLocation} Optional place location.
     */
    location;

    // Event click point coordinates
    clickPoint;

    constructor(type, id = 0) {
      this.type = type;
      this.id = parseInt(id);
    }

    /**
     * Gets (mapbox) item id.
     *
     * @return {string}
     */
    getMapItemId() {}

    /**
     * Convert to list (for destructuring).
     *
     * Usage: [lon, lat, zoom] = this.toList();
     */
    toList() {
      return [this.type, this.id];
    }

    /**
     * Gets location.
     */
    getGeoLocation() {
      return this.location;
    }

    /**
     * Set data from feature.
     *
     * @param {Feature} feature
     */
    setFeature(feature) {
      this.mapFeature = feature;

      if (feature.properties && feature.properties.name) {
        this.name = feature.properties.name;
      }
      // For feature type 'Point', geometry.coordinates is an array with lon, lat.
      // For feature type 'LineString', geometry.coordinates is an array of points.
      if (feature.geometry && feature.geometry.type === 'Point' && feature.geometry.coordinates) {
        const [lon, lat] = feature.geometry.coordinates;
        this.location = new GeoLocation(lon, lat);
      }
    }

    /**
     * Check whether this comes from a map feature.
     * @return {boolean}
     */
    isMapFeature() {
      return !!this.mapFeature;
    }

    /**
     * Gets card type / feature type.
     */
    getFeatureType() {
      return dataTypeToFeatureType(this.type);
    }

    /**
     * Create DOM Element
     */
    createElement() {
        if (!this.htmlElement) {
          const el = document.createElement('div');
          el.id = this.getMapItemId();
          this.htmlElement = el;
        }

        return this.htmlElement;
    }

    /**
     * Gets HTML element from document
     */
    getMapElement(refresh = true) {
        if (refresh || !this.htmlElement) {
          this.htmlElement = document.getElementById(this.getMapItemId());
        }
        return this.htmlElement;
    }

    /**
     * Check whether the referenced object maps a Card.
     *
     * @param {CardData} card
     * @return {boolean}
     */
    matchCardData(card) {
      // Default implementation if loaded from list and match
      return this.type === card.type &&
      ((this.id && this.id === card.id) || this.getMapItemId() === card.getMapId());
    }

    /**
     * Check whether the referenced object maps another Feature data.
     *
     * @param {FeatureData} feature
     * @return {boolean}
     */
    matchFeatureData(feature) {
      // Default implementation if loaded from list and match
      return this.type === feature.type && (
       (this.id && this.id === feature.id) ||
       (this.getMapItemId() && this.getMapItemId() === feature.getMapItemId())
      );
    }
}

export default FeatureData;
