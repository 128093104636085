/**
 * Object to store map location data and zoom.
 */
class LocationData {
    latitude;
    longitude;
    zoom;

    constructor(latitude, longitude, zoom) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.zoom = zoom;
    }

    /**
     * Construct from GeoJson
     *
     * @param {string|object} txtGeo
     * @return LocationData
     */
    static fromGeoJson(txtGeo) {
      const geo = typeof txtGeo === 'object' ? txtGeo : JSON.parse(txtGeo);
      if (geo && geo.coordinates) {
        const [lon, lat] = geo.coordinates;
        return new LocationData(lat, lon)
      }
    }

    /**
     * Convert to list (for destructuring).
     *
     * Usage: [lon, lat, zoom] = this.toList();
     */
    toList() {
      return [this.longitude, this.latitude, this.zoom];
    }

    /**
     * Compare if LocationData is the same.
     *
     * Usage: [lon, lat, zoom] = this.toList();
     */
    static isEqual(location1, location2) {
      return parseFloat(location1.latitude).toFixed(2) ===
          parseFloat(location2.latitude).toFixed(2) &&
          parseFloat(location1.longitude).toFixed(2) ===
          parseFloat(location2.longitude).toFixed(2) &&
              parseFloat(location1.zoom).toFixed(0) ===
          parseFloat(location2.zoom).toFixed(0);
    }
}

export default LocationData;
