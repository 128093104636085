import React from "react";
import Slide from "@material-ui/core/Slide";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import LogManager from "../common/LogManager";
import styles from './SnackComponent.module.scss';

/**
 * This component is controlled by the LogManager service.
 * We only need to place an instance of this component at the top level component of the app
 * and we don't need props or attributes to show the messages
 */

class SnackComponent extends React.Component {
    defaultAutoHideDuration = 6000;

    constructor(props) {
        super(props);

        this.state = {
            type: LogManager.TYPE_ERROR,
            message: '',
            visible: false,
            autoHideDuration: this.defaultAutoHideDuration,
        }

        this.handleCloseLog = this.handleCloseLog.bind(this);
        this.handleDeleteLog = this.handleDeleteLog.bind(this);
        this.handleLogEvent = this.handleLogEvent.bind(this);

        LogManager.setLogHandler(this.handleLogEvent, this.handleCloseLog);
    }

    /**
     * method called from snackbar transition to close snackbar
     */
    handleLogEvent(data) {
        const  {visible, message, type, block} = data;
        this.setState({
            visible,
            message,
            type,
            autoHideDuration: block ? null : this.defaultAutoHideDuration,
        })
    }

    /**
     * method called from snackbar transition to close snackbar
     */
    handleCloseLog() {
        const message = this.state.message;
        const type = this.state.type;
        this.setState({
            visible: false,
            message,
            type,
            autoHideDuration: this.defaultAutoHideDuration,
        })
    }

    /**
     * method called from snackbar transition when snackbar finish closing
     */
    handleDeleteLog() {
        LogManager.deleteLog();
    }

    render() {
        let classList = styles.snackBar;
        if (!this.state.visible) {
            classList += ' ' + styles.hide;
        }
        return (
            <Snackbar open={this.state.visible}
                      autoHideDuration={this.state.autoHideDuration}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                      }}
                      TransitionComponent={Slide}
                      onClose={this.handleCloseLog}
                      TransitionProps={{
                          onExited: this.handleDeleteLog
                      }}
                      className={classList}
                      ClickAwayListenerProps={{mouseEvent: false, touchEvent: false}}
            >
                <SnackbarContent
                    className={styles[this.state.type]}
                    message={this.state.message}
                />
            </Snackbar>
        )
    }
}

export default SnackComponent;
