/**
 * Feature types for Map
 */
import AppService from "./AppService";

const featureTypes = {
    localidad: 'localidad',
    track: 'track',
    albergue: 'albergue',
};

/**
 * Data types for URL query string and REST URL
 */
const dataTypes = {
  route: 'route', // Camino
  stage: 'stage', // Etapa
  hotel: 'hotel', // Accommodation
  place: 'place', // City, monument, etc...
  track: 'track', // Generic track (route, stage)
  page: 'page',   // Page with text content.
}

/**
 * Classes for different data types
 */
const dataClass = {
  hotel: 'accommodation',
}

/**
 * States for geoPermission service status.
 */
const geoPermStatus = {
  granted: 'granted',
  prompt: 'prompt',
  denied: 'denied',
}

/**
 * Conversion: data type to feature type
 */
const dataTypeToFeatureType = function(type) {
  switch (type) {
    case dataTypes.place:
      return featureTypes.localidad;
    case dataTypes.hotel:
      return featureTypes.albergue;
    default:
      throw new Error("Cannot convert data type: " + type);
  }
}

/**
 * Conversion: feature type to data type
 */
const featureTypeToDataType = function(type) {
  switch (type) {
    case featureTypes.localidad:
      return dataTypes.place;
    case featureTypes.albergue:
      return dataTypes.hotel;
    default:
      throw new Error("Cannot convert feature type: " + type);
  }
}

/**
 * Pages loaded from gronze.com
 */
const getExternalPages = () => {
    return {
        legal_usage: AppService.isPwa() ? '1424' : '31380',
        legal_privacy: AppService.isPwa() ? '16620' : '31381',
        legal_cookies: '2311',
    }
}

export {
    featureTypes,
    dataTypes,
    dataClass,
    geoPermStatus,
    dataTypeToFeatureType,
    featureTypeToDataType,
    getExternalPages,
};
