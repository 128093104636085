import BaseStorage from "./BaseStorage";
import UserData from "../data/UserData";

/**
 * Storage service for user data.
 *
 * Use localStorage or sessionStorage depending on status.
 *
 * Implements most Storage methods plus some utility functions.
 */
class UserStorage extends BaseStorage {
  
    // All names like 'user.xxx'
    static keyPrefix = 'user';
  
    /**
     * Gets storage object.
     *
     * @return {Storage}
     */
    static storage() {
        return window.sessionStorage;
    }
    
    /**
     * Gets User Data from storage.
     *
     * @return {UserData} | null
     */
    static getUserData(keyName) {
      const rawData = this.getObject(keyName);
      
      if (rawData) {
        return UserData.fromRawData(rawData);
      }
    }     

}

export default UserStorage;
