import BaseService from './BaseService';
import RestService from './RestService';

import {dataTypes} from "./Constants";
import PlaceCard from '../data/PlaceCard';
import HotelCard from '../data/HotelCard';
import RouteCard from '../data/CardData';
import StageCard from '../data/StageCard';
import RouteList from '../data/RouteList';
import HotelList from '../data/HotelList';
import PageData from '../data/PageData';
import AnalyticsAPIService from "./AnalyticsAPIService";
/**
 * Provides back-end data and data mapping.
 * - Load objects from the backend.
 * - Manage data cachig and storage.
 *
 * Every API method here must return straight or Promise-wrapped data
 * that resolves to some data object or null.
 */
class DataService extends BaseService {

    /**
     * @var {RouteList} Route list
     */
    static routeList;

    /**
     * Initialize, preload route list.
     */
    static init() {
        this.loadRouteList()
          .then(routeList => {routeList && this.setRouteList(routeList) });
    }

    /**
     * Sets route list.
     *
     * @param {RouteList} routeList
     */
    static setRouteList(routeList) {
      this.routeList = routeList;
    }

    /**
     * Gets route by key.
     */
    static getRouteByKey(key) {
      return this.routeList && this.routeList.getPlace(key);
    }

     /**
      * Create card data.
      *
      * @param {string} type Data type
      * @param {object} data Data from REST
      * @param {string} restUrl
      * @param {string} name
      */
     static createCardData(type, data, restUrl, name = '') {
        const properties = {
            name,
            url: restUrl,
        };

        // Data may be an object or an array.
        const objectData = Array.isArray(data) ? data.shift(): data;

        this.debug("Creating card data / " + type, objectData);

        switch (type) {
          case dataTypes.hotel:
            return new HotelCard(type, properties, objectData);
          case dataTypes.place:
            return new PlaceCard(type, properties, objectData);
          case dataTypes.route:
            return new RouteCard(type, properties, objectData);
          case dataTypes.stage:
            return new StageCard(type, properties, objectData);
          default:
            this.logger().error("Unknown data type", type);
        }

    }

         /*
     * Get route list
     *
     * @return {RouteList}|none
     *   Parsed response body if any, none if empty
     */
    static async loadRouteList() {
        const listItems = await RestService.getRouteList();
         if (listItems) {
            return RouteList.fromListItems(listItems);
        }
    }

     /**
      * Load from services by object type, id.
      *
      * @param {PlaceData|HotelData} placeData
      *
      * @return {CardData}|null
      */
     static async loadFromPlaceData(placeData) {
       let url;
       if (placeData.restUrl) {
          url = placeData.restUrl;
       }
       else if ( placeData.type === dataTypes.stage && placeData.isMapFeature()) {
          url = RestService.getUrlForDataType(dataTypes.track, placeData.getMapItemId());
       }
       else {
          url = RestService.getUrlForDataType(placeData.type, placeData.id);
       }

       return RestService.getRawData(url, null, true)
           .then(res => {
              return res ?  this.createCardData(placeData.type, res, url, placeData.name) : null;
           }, error => {this.logger().error("Cannot load place data", error)});
     }

    /**
     * Load accommodations from rest API
     *
     * @param {LngLatBounds} bounds
     *   Map bounds, https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatbounds
     */
    static async loadHotels(bounds) {
        this.debug('Map.loadHotels', bounds);

        return RestService.getHotels(bounds)
            .then(listItems => {
                // this.debug("listItems", listItems);
                return listItems ? HotelList.fromListItems(listItems) : null;
            }, error => {this.logger().error("Cannot load hotel list", error)});

    }

    /**
     * Get text page by Name.
     *
     * @param {string} name
     *
     * @return {Promise<PageData>}
     */
    static async getPageByName(name, label) {
      const pages = PageData.getSitePages();

      if (name in pages) {
        return this.getPageById(pages[name].id, label);
      }
      else {
        return Promise.reject("Missing page: " + name);
      }
    }

    /**
     * Get text page by Id.
     *
     * @param {int} id
     *   The page id = Drupal node id
     *
     * @return {Promise<PageData>}
     */
    static async getPageById(id, label) {
      const type = dataTypes.page;
      this.debug('DataService.getPage', id);
      const url = RestService.getUrlForDataType(type, id);
      const props = {type: type, id: id, url: url};
      return RestService.getRawData(url, null, true, false)
          .then(res => {
              AnalyticsAPIService.sendViewCard(
                  label,
                  url
              );
              return res ? new PageData(id, props, res) : null;
          }, error => {
            this.logger().error("Cannot load page", error);
          });
    }
}

export default DataService;
