import GeoBounds from './GeoBounds';
import GeoLocation from './GeoLocation';

/**
 * CardData - Data from REST service.
 *
 * Data wrapper for object types:
 * - Accommodation (Hotel, Hostel)
 * - Place (City, Monument)
 */
class CardData {
    static BIG = 'big';
    static SMALL = 'small';

    static WARNING_INFO = 'info';
    static WARNING_CLOSED = 'closed';

    properties= {
        name: '',
        url: '',
    };

    /**
     * @var {string} Object type.
     */
    type;
    /**
     * @var {int} Object Id.
     */
    id;

    link= '';
    title;
    subtitle;

    imgHTML= '';
    extra_html = '';

    // Geo properties.
    geo_location;
    geo_bounds;

    // Place / Hotel
    tag_servicios= '';
    bookingUrl = '';
    phoneNumber = '';
    notaDestacada = '';

    // Raw object data
    data;

    // Selected card state.
    selected = false;

    // Event click point coordinates
    clickPoint;

    constructor(type, properties, data) {
      this.type = type;
      this.title = properties.name;
      this.properties = properties;

      if (data) {
        // The id will be node or term id.
        if (data.nid) {
          this.id = parseInt(data.nid);
        }
        else if (data.tid) {
          this.id = parseInt(data.tid);
        }
        this.title = data.title;
        this.link = data.url;

        this.geo_location = data.geo_location;
        this.bookingUrl = data.booking_url;

        this.extra_html = data.extra_html;
        this.imgHTML = data.image_main.image_html ? data.image_main.image_html :  null;

        this.data = data;
      }
    }

    /**
     * Gets card title.
     */
    getTitle() {
      return this.title ? this.title : this.properties.name;
    }

    /**
     * Gets unique Map id.
     * Examples: place[id], hotel[id], track_id, route_id
     *
     * @return {string}
     */
    getMapId() {}

    /**
     * Gets card subtitle
     */
    getSubtitle() {
      return this.subtitle ? this.subtitle : '';
    }

    /**
     * Gets card url/link.
     */
    getUrl() {
      return this.link ? this.link : this.properties.url;
    }

    /**
     * Gets card type.
     */
    getType() {
      return this.type ? this.type : '';
    }

    /**
     * Gets geo bounds.
     */
    getGeoLocation() {
      return this.geo_location ? new GeoLocation(this.geo_location.lon,  this.geo_location.lat) : null;
    }

    /**
     * Gets geo bounds.
     */
    getGeoBounds() {
      return this.data && this.data.geo_bounds ? GeoBounds.fromGeoData(this.data.geo_bounds) : null;
    }

    /**
     * Gets detail card size for this data.
     */
    getDetailCardSize() {
        return this.imgHTML ? CardData.BIG : CardData.SMALL;
    }

    /**
     * Navigation: has next?.
     *
     * @return {Boolean}
     */
    hasNext() {
    }

    /**
     * Navigation: has previous?.
     *
     * @return {Boolean}
     */
    hasPrev() {
    }

    /**
     * Navigation: Get next.
     *
     * @return {Placedata}|null
     */
    getNext() {
    }

    /**
     * Navigation: Get previous.
     *
     * @return {Placedata}|null
     */
    getPrev() {
    }

    /**
     * Get warning text.
     */
    getWarningText() {
      if (this.data) {
        if (this.data.status_warning) {
          return this.data.status_warning;
        }
        else if (this.data.nota_destacada) {
           return this.data.nota_destacada;
        }
      }
    }

    /**
     * Get warning type: info, closed, etc..
     * @return {string}
     */
    getWarningType() {
      return CardData.WARNING_INFO;
    }

    /**
     * Whether this card can be selected.
     */
    canSelect() {
      return false;
    }

    /**
     * Whether card has select buttons.
     */
    hasSelectionButtons() {
      return this.canSelect() || this.hasNext() || this.hasPrev();
    }

}

export default CardData;
