import {featureTypes, dataTypes} from "./Constants";
import BaseService from './BaseService';
import UserStorage from "../storage/UserStorage";
import UrlService from "./UrlService";
import LocationData from '../data/LocationData';
import PlaceData from '../data/PlaceData';
import GeoBounds from "../data/GeoBounds";

/**
 * Provides different geo-related utilities.
 * - Parse geo data from URL.
 * - Check visibility for zoom level, object type.
 */
class GeoService extends BaseService {
    static typeHotel = 'hotel';
    static typeLocation = 'place';

    /**
     * @var {LocationData}
     */
    static locationFromUrl;

    /**
     * @var {LocationData}
     */
    static placeFromUrl;

    /**
     * Get default zoom for object type / feature type.
     *
     * @param {string} type
     *
     * @return {float}
     */
    static getTypeZoom(type) {
        const config = this.config();

        switch (type) {
            case 'hotel':
            case featureTypes.albergue:
                return config.zoom_level_accommodation_min + 0.1;
            case 'place':
            case featureTypes.localidad:
            default:
                return config.zoom_level_place_min + 0.1;
        }
    }

    /**
     * Get map bounds from config.
     *
     * @return {array} Containing two arrays:
     * - Southwest coordinates
     * - Northeast coordinates
     */
    static getMapBounds() {
        const config = this.config();
        return [
            config.map_bounds_sw,
            config.map_bounds_ne
        ];
    }

    /**
     * Gets initial location / zoom.
     *
     * Dependes on vertical / horizontal
     *
     * @return {LocationData}
     */
    static getInitLocation() {
        const config = this.config();
        if (window.innerHeight > window.innerWidth) {
            return new LocationData(config.init_lat_vr, config.init_lon_vr, config.init_zoom_vr);
        } else {
            return new LocationData(config.init_lat_hz, config.init_lon_hz, config.init_zoom_hz);
        }
    }


    /**
     * Gets user position if stored.
     *
     * @return {LocationData} | null;
     */
    static getStoredLocation() {
        const lon = UserStorage.getItem('gronze_lon');
        const lat = UserStorage.getItem('gronze_lat');
        const zoom = UserStorage.getItem('gronze_zoom');

        if (lon && lat && zoom && lon !== 'undefined' && lat !== 'undefined') {
            return new LocationData(lat, lon, zoom);
        }
        else {
            return null;
        }
    }

    /**
     * Get location from Url.
     *
     * Zoom value defaults to config.init_zoom_with_coords
     *
     * @return {LocationData} | false
     */
    static getLocationFromUrl() {
        if (typeof this.locationFromUrl === 'undefined') {
            const urlParams = UrlService.getUrlParams();
            if (urlParams.has('lon') && urlParams.has('lat')) {
                const zoom = urlParams.has('zoom') ? urlParams.get('zoom') : this.config().init_zoom_with_coords;
                this.locationFromUrl = new LocationData(urlParams.get('lat'), urlParams.get('lon'), zoom);
            }
            else {
                this.locationFromUrl = false;
            }
        }
        return this.locationFromUrl;
    }

    /**
     * Get place from Url
     *
     * @return {PlaceData} | false
     */
    static getPlaceFromUrl() {
        if (typeof this.placeFromUrl === 'undefined') {
            const urlParams = UrlService.getUrlParams();
            if (urlParams.has('type') && urlParams.has('id')) {
                this.placeFromUrl = new PlaceData(urlParams.get('type'), urlParams.get('id'));
            }
            else {
                this.placeFromUrl = false;
            }
        }
        return this.placeFromUrl;
    }

    /**
     * Gets initial position from:
     * - Position from URL
     * - Feature in URL
     *
     * @return {LocationData}
     */
    static getInitialPosition() {
        let locationData;

        // control wich lat and long and zoom we will show
        /* eslint-disable */
        if (locationData = this.getLocationFromUrl()) {
            // we have coords on url
            return locationData;
            /* eslint-disable */
        } else if (locationData = this.getStoredLocation()) {
            return locationData;
        } else {
            return this.getInitLocation();
        }
    }

    /**
     * Gets zoom levels as structured data.
     *
     * For every data type we have [min, max]
     *
     * @returns {object}
     */
    static getVisibilityZoomLevels() {
        const config = this.config();

        return {
            route: config.zoom_level_route,
            stage: config.zoom_level_stage,
            place: [config.zoom_level_place_min, config.zoom_level_place_max],
            hotel: [config.zoom_level_accommodation_min, config.zoom_level_max ],
            hotel_names: config.zoom_level_accommodation_text_min,
        }
    }

    /**
     * Gets max zoom level for data type.
     *
     * @return {object} Object with 'min', 'max' properties.
     */
    static getTypeZoomLevels(type) {
        const levels = this.getVisibilityZoomLevels();
        const [min, max] = levels[type];
        return {min, max}
    }

    /**
     * Utility function to log bounds
     * @param {LngLatBounds} bounds
     */
    static logBounds(bounds, title = "Bounds") {
        this.debug(title, bounds);
        const [dx, dy] = this.boundsToDeltaLngLat(bounds);
        this.debug("Diff (lon, lat)", dx, dy);
        this.debug("Width, height (m)",
            bounds.getNorthEast().distanceTo(bounds.getNorthWest()),
            bounds.getNorthEast().distanceTo(bounds.getSouthEast()));
    }

    /**
     * Calculate delta for Bouds
     *
     * @param {LngLatBounds} bounds
     *
     * @return {array}
     * - deltaLng
     * - deltaLat
     */
    static boundsToDeltaLngLat(bounds) {
        return [
            bounds.getEast() - bounds.getWest(),
            bounds.getNorth() - bounds.getSouth()
        ];
    }

    /**
     * Expand bounds by a given percentage.
     *
     * @param {LngLatBounds} bounds
     * @param number pct
     *
     * @return new bounds object
     */
    static expandBounds(bounds, pct = 0.1) {
        const [deltaLng, deltaLat] = this.boundsToDeltaLngLat(bounds);
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        // NE, add delta
        ne.lng += deltaLng * pct;
        ne.lat += deltaLat * pct;

        // SW, substract.
        sw.lng -= deltaLng * pct;
        sw.lat -= deltaLng * pct;

        return new GeoBounds(sw, ne);
    }

    /**
     * Show camino?
     */
    static showCamino(zoom) {
        const config = this.config();
        return zoom < config.zoom_level_camino_max;
    }

    /**
     * Show places (localidades) ?
     */
    static showPlaces(zoom) {
        const config = this.config();
        return zoom >= config.zoom_level_place_min;
    }

    /**
     * Show accommodations (hotels) ?
     */
    static showHotels(zoom) {
        const config = this.config();
        return zoom >= config.zoom_level_accommodation_min;
    }

    /**
     * Show accommodations (hotels) ?
     */
    static showHotelNames(zoom) {
        const config = this.config();
        return zoom >= config.zoom_level_accommodation_text_min;
    }

    /**
     * Show stages (etapas) ?
     */
    static showStages(zoom) {
        return this.showDataType(dataTypes.stage, zoom);
    }

    /**
     * Show stages (etapas) ?
     */
    static showRoutes(zoom) {
        return this.showDataType(dataTypes.route, zoom);
    }

    /**
     * Show data type for this zoom level ?
     */
    static showDataType(type, zoom) {
        const levels = this.getTypeZoomLevels(type);
        // const levels = this.getVisibilityZoomLevels();
        // const [min, max] = levels[type];
        if (type === dataTypes.route) {
            return levels.min <= zoom && zoom <= levels.max;
        } else {
            return levels.min <= zoom;
        }

    }

    /**
     * Store location.
     *
     * @param {LocationData} location
     */
    static storeLocation(location) {
        UserStorage.setItem('gronze_lon', location.longitude);
        UserStorage.setItem('gronze_lat', location.latitude);
        UserStorage.setItem('gronze_zoom', location.zoom);
    }

    /**
     * Detects browser and OS to select permissions grant instructions
     *
     */
    static selectUrlInstructionsByBrowser () {
        // clean data and reload
        const classList = document.documentElement.classList;
        if (classList.contains('chrome')) {
            return 'https://support.google.com/chrome/answer/142065?hl=es';
        } else if (classList.contains('firefox')) {
            return 'https://support.mozilla.org/es/kb/firefox-comparte-mi-ubicacion-con-sitios-web#w_como-puedo-agregar-soporte-para-geolocalizacion-a-mi-sitio-web';
        } else if (classList.contains('safari')) {
            if (classList.contains('mobile')) {
                return 'https://support.apple.com/es-es/HT207092';
            } else {
                return 'https://support.apple.com/es-es/HT5403';
            }
        }
        return '';
    }

    static isUsingConfigInitialLocation() {
        if (this.getLocationFromUrl())
            return false;

        const storedLocation = this.getStoredLocation();
        if (!storedLocation)
            return true;

        const initLocation = this.getInitLocation();
        return LocationData.isEqual(initLocation, storedLocation);
    }
}

export default GeoService;
