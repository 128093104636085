import log from 'loglevel';

/**
 * Storage service for user data.
 *
 * Use localStorage or sessionStorage depending on status.
 *
 * Implements most Storage methods plus some utility functions.
 */
class BaseStorage {
  
    /**
     * Prefix to add to all stored variable names
     */
    static keyPrefix = 'base';
       
    /**
     * ABSTRACT Gets storage object.
     *
     *
     * @return {Storage}
     */
    static storage() {
      throw new Error("BaseStorage.storage() needs to be overridden. Kind of an abstract method.");
    }
    
    /**
     * Get logger.
     */
    static logger() {
      return log.getLogger('BaseStorage');  
    }
    
    /**
     * Implements Storage.getItem()
     */
    static getItem(keyName) {
      return this.storage().getItem(this.keyPrefix + '.' + keyName);
    }
    
    /**
     * Implements Storage.setItem().
     */
    static setItem(keyName, keyValue) {
      this.storage().setItem(this.keyPrefix + '.' + keyName, keyValue);
    }
    
    /**
     * Implements Storage.removeItem()
     */
    static removeItem(keyName) {
      this.storage().removeItem(this.keyPrefix + '.' + keyName);
    }
    
    /**
     * Implements Storage.clear().
     */
    static clear() {
      this.logger().debug('Clear storage');
      this.storage().clear();
    }
    
    /**
     * Save object to storage.
     * 
     * @param {object} objData Data to be stored.
     */
    static setObject(keyName, objData) {
      this.setItem(keyName, JSON.stringify(objData));
    }

    /**
     * Get object from storage.
     *
     * @return {object} | null
     */
    static getObject(keyName) {
      const rawData = this.getItem(keyName);
      if (rawData) {
        return JSON.parse(rawData);
      }
      else {
        return null;
      }
    }

}

export default BaseStorage;
