import { LngLat } from 'mapbox-gl';

/**
 * Stores location data, wrapping MapBox's LngLat
 *
 * @see https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglat
 */
class GeoLocation extends LngLat {
    // Properties
    // lng
    // lat
    
    /**
     * Class constructor.
     */
    static fromLatLon(latitude, longitude) {
      return new GeoLocation(longitude, latitude);
    }
    
    /**
     * Construct from Mapbox LngLat
     */
    static fromLngLat(lnglat) {
      return new GeoLocation(lnglat.lng, lnglat.lat);
    }

    /**
     * Construct from GeoJson
     * 
     * @param {string|object} txtGeo
     * @return LocationData
     */
    static fromGeoJson(txtGeo) {
      const geo = typeof txtGeo === 'object' ? txtGeo : JSON.parse(txtGeo);
      if (geo && geo.coordinates) {
        const [lon, lat] = geo.coordinates;
        return new GeoLocation(lon, lat)
      }     
    }
    
    /**
     * Convert to list (for destructuring).
     *
     * Usage: [lon, lat, zoom] = this.toList();
     */
    toList() {
      return [this.lng, this.lat];
    }
}

export default GeoLocation;
