import React from "react";
import styles from './Login.module.scss';
import {Button, Grid, TextField} from "@material-ui/core";
import i18n from "../i18n";
import log from "loglevel";
import LogManager from "../common/LogManager";
import SiteAuth from "../common/SiteAuth";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            values:  {
                username: '',
                password: '',
            },
            errors:  {
                username: false,
                password: false,
            },
            touched:  {
                username: false,
                password: false,
            },
        }

        this.handleChange = this.handleChange.bind(this);
        this.sendCredentials = this.sendCredentials.bind(this);
    }

    /**
     * Get logger.
     */
    logger() {
        return log.getLogger('MapComponent');
    }

    handleChange (event) {
        const _values = { ...this.state.values };
        _values[event.target.name] = event.target.value;
        const _touched = { ...this.state.touched };
        _touched[event.target.name] = true;
        const _errors = this.validateField(event.target.name, event.target.value);
        this.setState({
            values: _values,
            errors: _errors,
            touched: _touched,
        })
    }

    validateField (fieldName, value) {
        const errors = { ...this.state.errors };
        errors[fieldName] = value === '';
        return errors;
    }

    async sendCredentials() {
      SiteAuth.authenticate(this.state.values.username, this.state.values.password)
        .catch((error) => {
          this.logger().debug('Login error', error);
          // @todo Error to show inside the login form or the page.
          LogManager.createError('Usuario o password incorrecto');
        })
    }

    render() {
        return (
            <div
                className={styles.login}
            >
                <Grid container direction="row" justifyContent="center" className={styles.content} >
                    <Grid item xs={10}>
                        <TextField
                            name="username"
                            label="Usuario"
                            type="text"
                            autoComplete="nombre de usuario"
                            fullWidth
                            onChange={this.handleChange}
                            onBlur={this.handleChange}
                            error={this.state.errors.username}
                            defaultValue=""
                        />
                        <TextField
                            name="password"
                            label="Contraseña"
                            type="password"
                            autoComplete="contraseña"
                            fullWidth
                            onChange={this.handleChange}
                            onBlur={this.handleChange}
                            error={this.state.errors.password}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.sendCredentials}
                        disabled={
                            !this.state.touched.username || !this.state.touched.password ||
                            this.state.errors.username || this.state.errors.password
                        }
                    >{ i18n.t('initSession.send') }</Button>
                </Grid>

            </div>
        )
    }
}

export default Login;
